/* eslint-disable lingui/no-unlocalized-strings */
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

const MenuItem = ({
  route,
  className = '',
  classNameInner = '',
  noPadding = false
}) => {
  const { t } = useTranslation();

  if (!className) {
    className = `h-full w-auto flex justify-end items-center ${
      noPadding ? '' : 'px-4 py-4'
    } bg-white bg-opacity-0 hover:bg-opacity-10`;
  }

  return route.component || route.render ? (
    <Link key={route.path} to={route.path ?? ''} className={`${className}`}>
      <span
        className={`leading-none uppercase text-xl md:text-lg font-medium ${classNameInner}`}
      >
        {route.i18nKey ? t(route.i18nKey) : route.label}
      </span>
    </Link>
  ) : (
    <HashLink
      smooth
      key={route.path}
      to={route.path}
      className={`${className}`}
    >
      <span
        className={`leading-none uppercase text-xl md:text-lg font-medium ${classNameInner}`}
      >
        {route.i18nKey ? t(route.i18nKey) : route.label}
      </span>
    </HashLink>
  );
};

export default MenuItem;
