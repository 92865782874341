/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';

import Input from '../../Input';
import InputTextArea from '../../InputTextArea';
import ArrayItem from '../../ArrayItem';
import ArrayAddItem from '../../ArrayItemAdd';
import { createOnSave } from '../../../common/util';

import './PostMeta.scss';
import { DEFAULT_LANGUAGE } from '../../../common/translationUtils';

const PostMeta = ({ meta, language, onSave = () => {} }) => {
  const getMeta = () => {
    return language !== DEFAULT_LANGUAGE
      ? meta?.translations?.[language]
      : meta;
  };

  return (
    <div className="authors w-full h-full pb-4 flex flex-col justify-start items-start p-12 rounded-md bg-gray-100">
      <div className="w-full flex items-center h-auto pt-2 pb-8">
        <Input
          key={'metaTitle'}
          value={getMeta()?.metaTitle ?? ''}
          className="meta-title w-full font-medium text-3xl"
          label="Título do Cabeçalho"
          type="text"
          onChange={createOnSave(onSave, 'metaTitle')}
        ></Input>
      </div>
      {getMeta()?.authors?.map((author, index) => {
        return (
          <ArrayItem
            key={author?.contact + index}
            index={index}
            onDelete={async (index) => {
              const newAuthors = [...getMeta().authors];
              newAuthors.splice(index, 1);
              return await onSave('authors', newAuthors);
            }}
            onMove={() => {}}
            className="mb-4 p-8"
          >
            <div
              key={author.name + index}
              className="w-full flex flex-col justify-start items-start"
            >
              <Input
                initialValue={author.name ?? ''}
                className="w-full text-2xl font-light mb-4 py-1 px-2"
                label="Nome do Autor"
                type="text"
                onSave={createOnSave(onSave, `authors.${index}.name`)}
              ></Input>
              <Input
                initialValue={author.contact ?? ''}
                className="w-full italic text-2xl font-normal py-1 px-2"
                label="Contacto do Autor"
                type="text"
                onSave={createOnSave(onSave, `authors.${index}.contact`)}
              ></Input>
              <InputTextArea
                initialValue={author.info ?? ''}
                className="w-full text-xl font-normal py-1 px-2"
                label="Filiação / Info"
                type="text"
                onChange={createOnSave(onSave, `authors.${index}.info`)}
              ></InputTextArea>
            </div>
          </ArrayItem>
        );
      })}
      <ArrayAddItem
        onCreate={async () => {
          const newAuthors = [...getMeta().authors];
          newAuthors.push({ name: '', contact: '' });
          return await onSave('authors', newAuthors);
        }}
        className="w-full"
      ></ArrayAddItem>
      <div className="border-b-2 pt-4 w-full h-1"></div>
    </div>
  );
};

export default PostMeta;
