/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import {
  UploadIcon,
  TrashIcon,
  LinkIcon,
  PhotographIcon,
  CheckIcon,
  XIcon
} from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import Compressor from 'compressorjs';

import { uploadFile, removeFile } from '../../common/s3';
import Input from '../Input';
import { selectNodeText } from '../../common/util';

const S3ImageUpload = ({
  url,
  onUpload,
  onDelete,
  className = '',
  iconSize = 16,
  disableLink = false
}) => {
  const fourThirdsSize = Math.floor((iconSize * 3) / 4);
  const quarterSize = Math.floor(iconSize / 4);
  const eightSize = Math.floor(iconSize / 8);

  const [value, setValue] = React.useState('');
  const [isLoading, setIsloading] = React.useState('');

  return (
    <div
      className={`flex items-center ${
        url ? 'w-full' : 'w-auto'
      } border border-gray-300 rounded-md ${className}`}
    >
      <div
        className={`flex w-full justify-start items-center text-current py-${eightSize} `}
      >
        {url ? (
          <>
            <TrashIcon
              className={`w-${fourThirdsSize} h-${fourThirdsSize} p-${eightSize} mx-${eightSize} rounded-md ${
                isLoading
                  ? 'bg-gray-100 text-gray-400 opacity-20'
                  : 'hover:bg-white hover:bg-opacity-50 cursor-pointer'
              }`}
              onClick={async () => {
                if (isLoading) {
                  return;
                }
                if (url?.includes?.('s3')) {
                  setIsloading(true);
                  console.log('deleting s3 image');
                  const response = await removeFile(url);
                  console.log({ response });
                  if (response.ok) {
                    setIsloading(false);
                    return onDelete(response.data);
                  } else {
                    toast.error('Erro a remover ficheiro');
                  }
                } else {
                  console.log('deleting link image');
                  setIsloading(false);
                  return onDelete('');
                }
                setIsloading(false);
              }}
            ></TrashIcon>
            <div className={`flex w-full text-current px-${quarterSize}`}>
              {disableLink ? (
                <PhotographIcon
                  className={`w-${iconSize} h-${iconSize} rounded-md ${
                    isLoading
                      ? 'bg-gray-100 text-gray-400 opacity-20'
                      : 'hover:bg-white hover:bg-opacity-10 cursor-pointer'
                  }`}
                ></PhotographIcon>
              ) : (
                <span
                  onClick={(e) => {
                    selectNodeText(e.target);
                  }}
                  className="cursor-default font-mono meta-title w-full font-light text-base break-all text-current opacity-70 leading-none bg-trasparent mr-4"
                >
                  {url}
                </span>
              )}
            </div>
          </>
        ) : (
          <div className="flex w-full items-start pr-4">
            <label className="w-auto h-full">
              <UploadIcon
                className={`w-${fourThirdsSize} h-${fourThirdsSize} p-${eightSize} mx-${eightSize} rounded-md ${
                  isLoading
                    ? 'bg-gray-100 text-gray-400 opacity-20'
                    : 'hover:bg-white hover:bg-opacity-50 cursor-pointer'
                }`}
              ></UploadIcon>

              <input
                type="file"
                className="hidden"
                onChange={async (e) => {
                  if (isLoading) {
                    return;
                  }
                  setIsloading(true);
                  const files = e.target.files;
                  if (files?.length > 1) {
                    console.error('Multiple files uploaded, using first file');
                  }
                  const file = files[0];
                  let compressedFile = await new Promise(
                    (res) =>
                      new Compressor(file, {
                        quality: 0.8,
                        maxWidth: 1600,
                        success(result) {
                          console.log('compressed image');
                          res(result);
                        },
                        error(err) {
                          console.log('error compressing image');
                          console.error(err);
                          res(file);
                        }
                      })
                  );
                  console.log('uploading');
                  const response = await uploadFile(compressedFile);
                  if (response.ok) {
                    console.log(response.data?.publicUrl);
                    setIsloading(false);
                    return onUpload(response.data?.publicUrl);
                  } else {
                    toast.error('Erro a carregar ficheiro');
                  }
                  setIsloading(false);
                }}
              />
            </label>

            <div
              className={`w-full h-full flex items-center rounded-md bg-gray-50 bg-opacity-20`}
            >
              <LinkIcon
                className={`w-${iconSize} h-${fourThirdsSize} ml-${quarterSize} pr-${quarterSize} ${
                  isLoading ? 'opacity-20' : ''
                }`}
              ></LinkIcon>
              <Input
                value={value}
                className={` h-${fourThirdsSize} px-0 pr-${quarterSize} font-light w-full text-lg text-current bg-transparent leading-normal rounded-md border-none`}
                type="text"
                onChange={(val) => setValue(val)}
                onSave={(val) => {
                  onUpload(val);
                  setValue('');
                }}
                disableAffix={true}
              ></Input>
              <CheckIcon
                onClick={() => {
                  onUpload(value);
                  setValue('');
                }}
                className={`w-${iconSize} h-${fourThirdsSize} px-${eightSize} rounded-md ${
                  isLoading
                    ? 'opacity-20'
                    : 'hover:bg-white hover:bg-opacity-10 cursor-pointer'
                }`}
              ></CheckIcon>
              <XIcon
                onClick={() => {
                  setValue(url);
                  onUpload(url);
                }}
                className={`w-${iconSize} h-${fourThirdsSize} px-${eightSize} rounded-md ${
                  isLoading
                    ? 'opacity-20'
                    : 'hover:bg-white hover:bg-opacity-10 cursor-pointer'
                }`}
              ></XIcon>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default S3ImageUpload;
