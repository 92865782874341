/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';

import MainMenu from '../../components/Menu';
import Footer from '../../components/Footer';
import routes from '../../routes';
import footerRoutes from '../../routes/footer';

import graph from '../../img/marcus_winkler_graph.jpg';

import './PageGlossary.scss';
import { useTranslation } from 'react-i18next';

const PageGlossary = () => {
  const { t } = useTranslation();

  const imageBackground = (
    <>
      <div className="absolute z-0 top-0 left-0 w-full h-full">
        <img
          className="w-full h-full object-cover object-center"
          alt={'featured image'}
          src={graph}
        ></img>
      </div>
      <div className="overlay absolute z-10 top-0 left-0 w-full h-full"></div>
    </>
  );

  return (
    <div id="glossary" className="w-full h-full flex flex-col">
      <MainMenu routes={routes}></MainMenu>
      <div className="relative w-full flex flex-col justify-start items-center ">
        <div className="header relative w-full flex justify-center items-center">
          {imageBackground}
          <div className="w-8/12 z-20 text-gray-700 pt-32 pb-32">
            <h1 className="w-1/2 text-4xl uppercase font-bold mb-8">
              {t('pages.glossary.title')}
            </h1>
            <div className="w-1/4 pt-0 pb-8">
              <div className="w-full h-0 border-b-2 border-blue-300"></div>
            </div>
            <div className="w-full h-auto mb-32">
              <ul>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.0.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.0.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.1.title')}
                  </span>
                </li>
                <li className="pb-8">
                  <span className="text-xl font-sans mb-4">
                    {t('pages.glossary.terms.1.body')}
                  </span>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.2.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.2.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.3.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.3.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.4.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.4.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.5.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.5.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.6.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.6.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.7.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.7.body1')}</span>{' '}
                      <span>{t('pages.glossary.terms.7.body2')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.8.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.8.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.9.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.9.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.10.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.10.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ec.europa.eu/eurostat/statistics-explained/index.php?title=International_Standard_Classification_of_Education_(ISCED)"
                    className="underline"
                  >
                    <span className="text-4xl mb-4">
                      {t('pages.glossary.terms.11.title')}
                    </span>
                  </a>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.11.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.12.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.12.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.13.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.13.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.14.title')}
                  </span>
                </li>
                <li className="pb-8">
                  <span className="text-xl font-sans mb-4">
                    {t('pages.glossary.terms.14.body')}
                  </span>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.15.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.15.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.16.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.16.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.17.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.17.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.18.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.18.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.19.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.19.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.20.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.20.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ine.pt/xportal/xmain?xpid=INE&amp;xpgid=ine_publicacoes&amp;PUBLICACOESpub_boui=107961853&amp;PUBLICACOESmodo=2&amp;xlang=pt"
                    className="underline"
                  >
                    <span className="text-4xl mb-4">
                      {t('pages.glossary.terms.21.title')}
                    </span>
                  </a>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.21.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.22.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.22.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.23.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.23.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.24.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.24.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.25.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.25.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.26.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.26.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.27.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.27.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.28.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.28.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.ine.pt/ine_novidades/semin/cae/CAE_REV_3.pdf"
                    className="underline"
                  >
                    <span className="text-4xl mb-4">
                      {t('pages.glossary.terms.29.title')}
                    </span>
                  </a>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.29.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.30.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.30.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.31.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.31.body1')}</span>{' '}
                      <span>{t('pages.glossary.terms.31.body2')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.32.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.32.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.33.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.33.body')}</span>
                    </li>
                  </ul>
                </li>
                <li className="pb-8">
                  <span className="text-4xl mb-4">
                    {t('pages.glossary.terms.34.title')}
                  </span>
                  <ul>
                    <li className="text-xl font-sans pt-4">
                      <span>{t('pages.glossary.terms.34.body')}</span>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer routes={footerRoutes}></Footer>
    </div>
  );
};

export default PageGlossary;
