import React from 'react';
import { Switch } from 'react-router-dom';
import { toast } from 'react-toastify';

import AppContextBase from '../../context/AppContextBase';
import AdminMenu from '../../components/AdminMenu';
import PublicRoute from '../../components/RoutePublic';
import ProtectedRoute from '../../components/RouteProtected';
import Lost from '../../components/Lost';
import AdminDashboard from '../Dashboard';

import routes from '../../routes/admin';
import { getPosts } from '../../network/posts';
import { getCategories } from './../../network/categories';

import { UserCircleIcon, LogoutIcon } from '@heroicons/react/outline';
import './index.scss';
import { getTags } from '../../network/tags';

const AdminHome = () => {
  const [posts, setPosts] = React.useState([]);
  const [postMetas, setPostMetas] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [availableTags, setAvailableTags] = React.useState([]);
  const context = React.useContext(AppContextBase);

  const onPostUpdate = async (post) => {
    const newPosts = [...posts];
    const postIndex = newPosts.findIndex((x) => x._id === post._id);
    const newPost = { ...post }; // Force updates
    newPost.meta = { ...newPost?.meta }; // Force updates
    newPosts[postIndex] = newPost;
    setPosts(newPosts);
  };

  React.useEffect(() => {
    const fetchUser = async () => {
      const user = await context.currentUser();
      setUser(user);
    };

    const fetchPosts = async () => {
      const response = await getPosts('post');
      if (response?.ok) {
        const posts = response.data?.reverse?.() ?? [];
        setPosts(posts);
        setPostMetas(posts.map((post) => post.meta) ?? []);
      } else {
        toast.error('Erro a carregar publicações');
      }
    };

    const fetchCategories = async () => {
      const catResponse = await getCategories();
      if (catResponse?.ok) {
        setCategories(catResponse.data ?? []);
      } else {
        toast.error('Erro a carregar categorias');
      }
    };

    const fetchTags = async () => {
      const tagsResponse = await getTags();
      if (tagsResponse?.ok) {
        setAvailableTags(tagsResponse.data?.map?.((tag) => tag.label) ?? []);
      } else {
        toast.error('Erro a carregar tags');
      }
    };
    fetchUser();
    fetchPosts();
    fetchCategories();
    fetchTags();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      id="admin"
      className="relative flex justify-start items-start w-full h-screen"
    >
      <div className="w-60 h-full">
        <AdminMenu routes={routes}></AdminMenu>
      </div>
      <div className="routes-home h-full flex flex-col">
        <div
          className={`relative print:hidden h-12 w-full bg-bluegray-200 flex justify-end items-center`}
        >
          <div className="h-full w-auto py-2  flex items-center">
            <UserCircleIcon className="pr-2 h-3/4"></UserCircleIcon>
          </div>
          {!!user?.name && (
            <span className="whitespace-nowrap pr-4">{user.name}</span>
          )}
          <div
            className="h-full w-auto py-2 px-4 flex items-center cursor-pointer bg-white bg-opacity-0 hover:bg-opacity-20"
            title="Log out"
            onClick={() => {
              context?.logOut();
            }}
          >
            <LogoutIcon className="pr-2 h-3/4"></LogoutIcon>
            <span className="">Log out</span>
          </div>
        </div>
        <div className="h-full w-full flex-grow overflow-y-scroll flex flex-col justify-start items-center">
          <Switch>
            <ProtectedRoute
              exact
              authenticated={context.state.authenticated}
              path="/admin"
              render={() => (
                <AdminDashboard
                  {...{
                    user,
                    posts,
                    postMetas,
                    categories,
                    availableTags,
                    onPostUpdate
                  }}
                ></AdminDashboard>
              )}
            />
            {routes?.map((route) => {
              if (!route.protected) {
                return (
                  <PublicRoute
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    render={() =>
                      route.render({
                        user,
                        posts,
                        postMetas,
                        categories,
                        availableTags,
                        onPostUpdate
                      })
                    }
                  ></PublicRoute>
                );
              } else {
                return (
                  <ProtectedRoute
                    authenticated={context.state.authenticated}
                    key={route.path}
                    exact={route.exact}
                    path={route.path}
                    component={route.component}
                    render={() =>
                      route.render({
                        user,
                        posts,
                        postMetas,
                        categories,
                        availableTags,
                        onPostUpdate
                      })
                    }
                  />
                );
              }
            })}
            <PublicRoute component={Lost} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
