import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

import Ellipsis from '../../components/Ellipsis';
import { cleanValue, isEnter } from '../../common/util';
import { postPost } from '../../network/posts';
import { getPostMetas } from '../../network/postMetas';
import InputCheckbox from '../../components/InputCheckbox';

import './PostList.scss';
import postTypes from '../../common/postTypes';
import { DEFAULT_LANGUAGE } from '../../common/translationUtils';

const PostList = ({
  type = postTypes.post.type,
  typeLabel = 'publicação',
  typeRoute = '/admin/p'
}) => {
  const [metas, setMetas] = React.useState([]);
  const [metaCache, setMetaCache] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await getPostMetas(type);
      if (response.ok) {
        const metas = response.data.filter((pm) => pm?.postType === type) ?? [];
        setMetas(metas);
        setMetaCache(metas);
      } else {
        toast.error('Erro a carregar categorias. Por favor tente de novo.');
      }
    };
    if (!metas || !metas?.length) {
      fetchData();
    }
  }, [type]); // eslint-disable-line

  const listItems = metas?.map?.((meta, index) => {
    const firstLocalizationEntry = Object.entries(meta.translations).find(
      ([lang, value]) => value.init
    );
    const localizedMeta = meta.isPtTranslation
      ? meta
      : firstLocalizationEntry?.[1];
    const currentLang = meta.isPtTranslation
      ? DEFAULT_LANGUAGE
      : firstLocalizationEntry?.[0];

    return (
      <div className="post-list-item rounded-sm w-full min-h-60 mb-2 p-4 bg-white flex justify-start items-start">
        <Link
          key={meta?.postId + index}
          to={`${typeRoute}/${meta?.postId}`}
          className="w-3/4 h-full flex"
        >
          <div className="item-col overflow-y-hidden h-full flex flex-col pr-8 w-1/4">
            <div
              className="image-thumbnail bg-gray-100 bg-center bg-no-repeat bg-cover"
              style={{ backgroundImage: `url(${localizedMeta?.image}` }}
            ></div>
          </div>
          <div className="item-col h-full flex flex-col w-1/2 pr-8">
            <div className="pb-3 flex items-start text-3xl">
              <div className="rounded-sm w-12 mr-4 flex justify-center bg-blue-600 text-white px-2 py-1">
                {currentLang}
              </div>
              {localizedMeta?.title}
            </div>
            <div className="pb-3 text-lg max-h-1/2 overflow-y-hidden">
              {localizedMeta?.subtitle}
            </div>
            {!!meta?.slug && (
              <pre className="pb-3 text-base">
                <span className="text-gray-400">https://.../</span>
                {meta?.slug}
              </pre>
            )}
          </div>
        </Link>
        <div className="item-col flex flex-col justify-start w-1/4 p-4 h-full bg-gray-100">
          <div className="w-full flex justify-start h-auto">
            <InputCheckbox
              disabled
              className={`w-full pr-8 ${
                meta?.publishedDate
                  ? 'bg-blue-600 text-white'
                  : 'bg-transparent'
              } `}
              checked={!!meta?.publishedDate}
              label={
                <div className="flex w-full justify-between">
                  <span className="pr-4">Publicado</span>
                  {!!meta?.publishedDate && (
                    <span className=" ">
                      {moment(meta?.publishedDate).format('YYYY-MM-DD')}
                    </span>
                  )}
                </div>
              }
            ></InputCheckbox>
          </div>
          <div className="w-full flex justify-start h-auto pt-4">
            <div className="flex w-full justify-between px-2">
              <span className="pr-4">Data de Criação</span>
              <span className="pr-4">
                {moment(meta?.createdAt).format('YYYY-MM-DD')}
              </span>
            </div>
          </div>
          <div className="w-full flex justify-start h-auto pt-4">
            <div className="flex w-full justify-between px-2">
              <span className="pr-4">Autor</span>
              <span className="pr-4">{meta?.author?.name ?? '-'}</span>
            </div>
          </div>
          <div className="flex gap-2 items-center justify-start px-2 pt-2 mt-1 border-t border-gray-300">
            <div className="">Versões: </div>
            {!!meta.isPtTranslation && (
              <div className="rounded-sm w-12 flex justify-center bg-blue-600 text-white px-2 py-1">
                {DEFAULT_LANGUAGE}
              </div>
            )}
            {Object.entries(meta?.translations ?? {})
              ?.filter(([key, value]) => value.init)
              .map?.(([key]) => (
                <div className="rounded-sm w-12 flex justify-center bg-blue-600 text-white px-2 py-1">
                  {key}
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  });

  const searchInput = (
    <div className="flex mr-8">
      <input
        className="bg-bluegray-200 active:bg-bluegray-300 outline-none rounded-sm min-w-60 h-auto pl-3 py-3 text-gray-800 placeholder-current text-base focus:shadow-sm"
        placeholder="Procurar"
        value={search}
        onChange={(event) => {
          setSearch(event.target.value);
        }}
        onKeyPress={(e) => {
          if (isEnter(e)) {
            setLoading(true);
            const newMetas = metaCache.filter(
              (meta) => cleanValue(meta).indexOf(cleanValue(search)) !== -1
            );
            setMetas(newMetas);
            setLoading(false);
          }
        }}
      ></input>{' '}
      {isLoading && <Ellipsis backgroundColor="#5ea2ef" scale={0.3}></Ellipsis>}
    </div>
  );

  const createPost = (
    <>
      <div className="flex h-full">
        <button
          className="bg-light-blue hover:bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none mr-24"
          disabled={isLoading}
          onClick={async () => {
            setLoading(true);
            const response = await postPost({ postType: type });
            if (!response?.ok) {
              toast.error(
                `Erro a criar ${typeLabel}. Por favor tente de novo.`
              );
            } else {
              setMetas([response.data?.meta, ...metas]);
            }
            setLoading(false);
          }}
        >
          Criar {typeLabel}
        </button>
      </div>
      {isLoading && <Ellipsis backgroundColor="#5ea2ef" scale={0.3}></Ellipsis>}
    </>
  );

  return (
    <div className="post-list relative w-full flex flex-col p-8 bg-gray-100">
      <div className="header pb-4 flex justify-start items-center">
        {searchInput}
        {createPost}
      </div>
      {listItems}
    </div>
  );
};

export default PostList;
