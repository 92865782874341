/* eslint-disable lingui/no-unlocalized-strings */
import isURL from 'validator/lib/isURL';
import TableauReport from '../../TableauReact';
import { multiLineString } from '../../../common/util';

import './SectionTableau.scss';

const SectionImage = ({ classNames, styles, options, toc }) => {
  const { url, caption, altImage } = options;
  // const width = options.width ? options.width + '%' : null;
  // const align = options.align ?? 'start';
  return (
    <div
      className={`section section-tableau w-full flex flex-col justify-start items-center ${
        !altImage ? 'print:hidden' : ''
      } ${classNames}`}
      style={styles}
    >
      {!!toc?.title && (
        <h2 className="w-full pb-4 text-lg font-light">{toc.title}</h2>
      )}
      <figure className="w-full">
        <div className="w-full block print:hidden">
          <span className="hidden">{url}</span>
          {isURL(url) && (
            <TableauReport
              url={url}
              options={{ hideTabs: true, hideToolbar: true, debug: false }}
              query="?:embed=yes&:comments=no&:toolbar=no&:refresh=yes"
            />
          )}
        </div>
        {!!altImage && (
          <div className="w-full hidden print:block">
            <img
              alt={'Graphic'}
              src={altImage}
              style={{ width: '100%', ...styles }}
              className="w-full h-auto object-contain object-center"
            />
          </div>
        )}

        {caption ? (
          <figcaption>{multiLineString(caption ?? '')}</figcaption>
        ) : null}
      </figure>
    </div>
  );
};

export default SectionImage;
