import { useTranslation } from 'react-i18next';
import {
  AVAILABLE_LANGUAGES as ALL_LANGUAGES,
  DEFAULT_LANGUAGE
} from '../../common/translationUtils';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { createTranslation, enableLocalization } from '../../common/util';

const TranslationSwitcher = ({
  language,
  onChangeLanguage = () => {},
  localizableObject,
  onCreateTranslation,
  changeI18n = false,
  className = '',
  buttonClassName = ''
}) => {
  const { i18n } = useTranslation();

  const hasTranslations = localizableObject?.translations;
  if (!hasTranslations) {
    enableLocalization(localizableObject);
  }
  const availableLanguages = [DEFAULT_LANGUAGE];

  for (const langKey in localizableObject.translations) {
    if (localizableObject.translations?.[langKey]?.init) {
      availableLanguages.push(langKey);
    }
  }

  const localChangeLanguage = (lang) => {
    onChangeLanguage(lang);
    changeI18n && i18n.changeLanguage(lang);
  };

  const localCreateTranslation = (lang) => {
    const newObject = { ...localizableObject };
    createTranslation(newObject, lang);

    if (newObject?.meta) {
      const newMeta = { ...newObject?.meta };
      createTranslation(newMeta, lang);
      newObject.meta = newMeta;
    }

    return onCreateTranslation(newObject);
  };

  console.log(localizableObject);

  return (
    <div
      className={`w-auto h-auto flex items-center flex-shrink-0 rounded-md overflow-hidden ${className}`}
    >
      {ALL_LANGUAGES.map((lang, index) => {
        const isLast = index === ALL_LANGUAGES.length - 1;
        if (!availableLanguages.includes(lang) && lang !== DEFAULT_LANGUAGE) {
          return (
            <button
              key={lang}
              onClick={() => {
                localCreateTranslation(lang);
                localChangeLanguage(lang);
              }}
              className={`flex items-center h-full w-auto px-4 py-1 ${
                lang === language
                  ? 'bg-blue-700 text-white animate-pulse'
                  : 'bg-gray-500 text-white'
              } ${
                isLast ? '' : 'border-r border-gray-500'
              } focus:outline-none transition ${buttonClassName}`}
            >
              <PlusCircleIcon className="w-6 h-6 stroke-current mr-1"></PlusCircleIcon>
              <span className="leading-none uppercase pt-1">{lang}</span>
            </button>
          );
        }
        return (
          <button
            key={lang}
            onClick={() => localChangeLanguage(lang)}
            className={`px-8 h-full py-1 ${
              lang === language
                ? 'bg-blue-700 text-white animate-pulse'
                : 'bg-blue-200 text-gray-800'
            } ${
              isLast ? '' : 'border-r border-gray-500'
            } uppercase focus:outline-none transition buttonClassName`}
          >
            {lang}
          </button>
        );
      })}
    </div>
  );
};

export default TranslationSwitcher;
