import React from 'react';

import { toast } from 'react-toastify';

import ArrayItem from '../../components/ArrayItem';
import ArrayAddItem from '../../components/ArrayItemAdd';
import Input from '../../components/Input';
import { generateSlug } from '../../common/util';

import {
  getCategories,
  postCategory,
  deleteCategory,
  putCategory
} from '../../network/categories';

import './CategoryEditor.scss';
import {
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE
} from '../../common/translationUtils';
import { SaveIcon } from '@heroicons/react/outline';

const AdminCategoryEditor = () => {
  const [categories, setCategories] = React.useState([]);
  const [newCategory, setNewCategory] = React.useState({ label: '', slug: '' });

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await getCategories();
      if (response.ok) {
        setCategories(response.data);
      } else {
        toast.error('Erro a carregar categorias. Por favor tente de novo.');
      }
    };
    if (!categories || !categories?.length) {
      fetchData();
    }
  }, []); // eslint-disable-line

  const categoryItems = categories.map((category, index) => (
    <Category
      category={category}
      index={index}
      onDelete={async (index) => {
        console.log({ index, categories });
        const cat = categories[index];
        const response = await deleteCategory(cat._id);
        if (response?.ok) {
          const newCategories = categories.filter((c) => c._id !== cat._id);
          setCategories(newCategories);
        } else {
          toast.error('Nome da categoria deverá ter pelo menos 3 caracteres.');
        }
      }}
      onChange={(category) => {
        const newCategories = [...categories];
        newCategories[index] = category;
        setCategories(newCategories);
      }}
      onSave={async (category) => {
        const response = await putCategory(category);
        if (response?.ok) {
          toast.success('Categoria gravada com sucesso.');
        } else {
          toast.error('Erro a gravar categoria');
        }
      }}
    ></Category>
  ));

  return (
    <div className="category-list relative w-full flex flex-col justify-start items-start p-8 bg-gray-100">
      <h1 className="text-4xl font-bold pb-8">Categorias de Artigo</h1>
      {categoryItems}
      <div className={`bg-white w-full p-8 rounded-md flex`}>
        <div className="relative w-auto h-full flex-grow flex flex-col">
          <h2 className="text-2xl font-bold pb-8">Nova Categoria</h2>
          <Input
            containerClassName="w-3/4"
            className="w-full text-2xl font-light mb-4 py-1 px-2"
            label="Nome da categoria"
            type="text"
            value={newCategory.label}
            onChange={(value) => {
              setNewCategory({
                label: value,
                slug: generateSlug(value)
              });
            }}
          ></Input>
          <span className="w-1/2 pt-4 text-sm text-gray-400">
            Identificador
          </span>
          <input
            disabled
            className="w-1/2 text-xl font-normal text-gray-700 py-1 px-2 mb-8 rounded-md focus:outline-none border border-gray-300 bg-transparent"
            value={newCategory?.slug ?? ''}
          ></input>
        </div>
        <ArrayAddItem
          onCreate={async () => {
            if (!newCategory?.label || newCategory?.label?.length < 3) {
              toast.error(
                'Nome da categoria deverá ter pelo menos 3 caracteres.'
              );
              return;
            }
            const toSave = newCategory;
            if (!toSave.slug) {
              toSave.slug = generateSlug(toSave.label);
            }
            const response = await postCategory(toSave);

            if (response?.ok) {
              const newCategories = [...categories];
              newCategories.push(response.data);

              setCategories(newCategories);
              setNewCategory({ label: '', slug: '' });
            } else {
              toast.error('Erro a criar categoria');
            }
          }}
        ></ArrayAddItem>
      </div>
    </div>
  );
};

export default AdminCategoryEditor;

const Category = ({
  category,
  index,
  onSave = () => {},
  onChange = () => {},
  onDelete = () => {}
}) => {
  const [language, setLanguage] = React.useState(DEFAULT_LANGUAGE);
  const [isDirty, setDirty] = React.useState(false);

  return (
    <ArrayItem
      onDelete={onDelete}
      index={index}
      className="w-full p-8 flex justify-start items-center mb-4 bg-white hover:bg-gray-50 transition"
      key={'' + category.slug + index}
    >
      <div className="w-full h-full flex flex-col justify-start items-start">
        <div className="w-auto h-auto flex-shrink-0 rounded-md overflow-hidden bg-gray-300 mb-2">
          {AVAILABLE_LANGUAGES.map((lang, index) => {
            const isLast = index === AVAILABLE_LANGUAGES.length - 1;
            return (
              <button
                key={lang}
                onClick={() => setLanguage(lang)}
                className={`px-4 py-1 ${
                  lang === language
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-300 text-gray-700'
                }  ${
                  isLast ? '' : 'border-r-2 border-gray-100'
                }  focus:outline-none transition`}
              >
                {lang}
              </button>
            );
          })}
        </div>
        {language === 'pt' ? (
          <>
            <span className="text-2xl pb-1">{category?.label}</span>
            <pre className="text-sm text-gray-400">/{category?.slug}</pre>
          </>
        ) : (
          <>
            <Input
              containerClassName="w-3/4"
              className="w-full text-xl py-1 px-2 mb-1"
              type="text"
              value={category?.translations?.[language]?.label ?? {}}
              onChange={(value) => {
                setDirty(true);
                const newCategory = { ...category };
                newCategory.translations[language].label = value;
                return onChange(newCategory);
              }}
            ></Input>
            <pre className="text-sm text-gray-400">/{category?.slug}</pre>
          </>
        )}
      </div>
      <div
        className={`p-2 border-2 ${
          isDirty
            ? 'border-blue-500 text-blue-500 bg-white hover:bg-blue-500 hover:text-white cursor-pointer'
            : 'border-transparent text-gray-400 cursor-not-allowed'
        }  rounded-full  transition`}
        onClick={async () => {
          if (!isDirty) {
            return;
          }
          try {
            await onSave(category);
            setDirty(false);
          } catch (error) {
            //nothing
          }
        }}
      >
        <SaveIcon className="w-10 h-10  stroke-current"></SaveIcon>
      </div>
    </ArrayItem>
  );
};
