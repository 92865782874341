import React from 'react';

import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import isUrl from 'validator/lib/isURL';

import Ellipsis from '../../components/Ellipsis';
import { generateSlug } from '../../common/util';

import { deletePost, putPost } from '../../network/posts';

import './ResearchEditor.scss';
import moment from 'moment';
import InputCheckbox from '../../components/InputCheckbox';
import InputDateSelector from '../../components/InputDateSelector';
import ConfirmDelete from '../../components/ConfirmDelete';
import { getCategories } from '../../network/categories';
import { postTag } from '../../network/tags';

let years = [];
for (let i = 1990; i <= new Date().getFullYear(); i++) {
  years.push('' + i);
}
years = years.reverse();

const ResearchEditor = ({
  post: maybePost,
  meta: maybeMeta,
  availableTags,
  onDelete = (post) => {}
}) => {
  const [post, setPost] = React.useState(maybePost); // maybe comes in prop
  const [meta, setMeta] = React.useState(maybeMeta); // maybe comes in prop
  const [isLoading, setLoading] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false); // maybe comes in prop

  const [categories, setCategories] = React.useState([]);

  const fetchCategories = async () => {
    const response = await getCategories();
    if (!response.ok) {
      toast.error('Erro a obter categorias disponíveis');
      return;
    }
    setCategories(response.data);
  };

  React.useEffect(() => {
    if (!categories || !categories?.length) {
      fetchCategories();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const categoryOptions = categories?.map((cat) => {
    return { value: cat._id, label: cat.label, _id: cat._id };
  });

  React.useEffect(() => {
    if (!post) {
      return;
    }

    if (!post?.data) {
      post.data = {};
    }

    if (!post.data.authors) {
      post.data.authors = [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const savePostToDb = async () => {
    await new Promise((r) => setTimeout(r, 150));
    setLoading(true);

    const metaWithSlug = { ...meta, slug: generateSlug(meta?.title, 24) };

    const response = await putPost({ ...post, meta: metaWithSlug });
    if (!response?.ok) {
      toast.error('Erro a gravar publicação. Por favor tente de novo.');
      setDirty(true);
    } else {
      setPost({ ...post });
      setMeta(metaWithSlug);
      setDirty(false);
    }

    setLoading(false);
  };

  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <Ellipsis></Ellipsis>
      </div>
    );
  }

  if (!post || !meta) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        Hmm, this post seems to be missing...
      </div>
    );
  }

  const canSave = !isLoading && isDirty;

  return (
    <div
      className={`research-editor relative bg-gray-100 flex justify-start items-start w-full h-full overflow-visible p-4`}
    >
      <div className="w-3/4 pr-4 flex flex-wrap justify-start align-start">
        <Input
          className="h-auto rounded-md bg-white px-4 w-1/2 py-2 mb-2 border border-gray-300"
          value={meta?.title ?? ''}
          onChange={(e) => {
            setMeta((prevMeta) => ({ ...prevMeta, title: e.target.value }));
            setDirty(true);
          }}
          placeholder="Título da Publicação"
        ></Input>
        <div className="relative w-1/2 flex pl-4">
          <Input
            onChange={(e) => {
              setPost((prevPost) => ({
                ...prevPost,
                data: { ...prevPost.data, publishedIn: e.target.value }
              }));
              setDirty(true);
            }}
            value={post?.data?.publishedIn}
            placeholder="Publicado Em"
            className="h-auto w-full rounded-md bg-white mb-2 px-4 py-2 border border-gray-300"
          ></Input>
        </div>
        <div className="relative w-full flex pb-2">
          <Input
            className={`h-auto w-full rounded-md bg-white px-4 py-2 border ${
              post?.data?.url && !isUrl(post.data.url)
                ? 'border-red-700 ring-red-700 outline-none'
                : 'border-gray-300'
            }`}
            value={post?.data?.url ?? ''}
            onChange={(e) => {
              setPost((prevPost) => ({
                ...prevPost,
                data: { ...prevPost.data, url: e.target.value }
              }));
              setDirty(true);
            }}
            placeholder="URL do Título (https://...), opcional"
          ></Input>
        </div>

        <div className="relative w-1/2 flex pb-2">
          <CreatableSelect
            onChange={(value) => {
              console.log('onchage', value);
              setPost((prevPost) => ({
                ...prevPost,
                data: { ...prevPost.data, date: value?.value ?? value }
              }));
              setDirty(true);
            }}
            value={
              post?.data?.date?.year?.() ??
              post?.data?.date?.value ??
              post?.data?.date
            }
            options={years?.map((year) => ({
              label: year,
              value: year
            }))}
            placeholder={'Ano de publicação'}
            className="w-1/2 leading-loose bg-transparent"
          />
        </div>

        <div className="w-full h-auto flex flex-col justify-start items-start pt-6">
          <div className=" flex justify-start items-center pb-2">
            <span className="text-xl pr-4">Autores</span>
            <button
              className={`${
                isLoading
                  ? 'bg-gray-200 cursor-default'
                  : 'bg-light-blue  hover:bg-blue-400 cursor-pointer'
              } h-8 text-white leading-tight py-2 px-4 rounded focus:outline-none`}
              disabled={isLoading}
              onClick={() => {
                if (isLoading) {
                  return;
                }
                post.data.authors.push({});
                setDirty(true);
                setPost({ ...post });
              }}
            >
              Adicionar Autor
            </button>
          </div>
          <div className="w-full h-auto flex flex-wrap justify-start items-start content-start pt-2">
            {post?.data?.authors?.map?.((author, index) => {
              return (
                <div
                  key={index}
                  className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 pb-4 pr-4"
                >
                  <div className="w-full flex flex-col p-2 bg-gray-200 border border-gray-300 rounded-md justify-start align-start">
                    Autor
                    <Input
                      className="h-auto rounded-md bg-white px-4 w-full py-2 mb-2 "
                      value={author.name ?? ''}
                      onChange={(e) => {
                        post.data.authors[index].name = e.target.value;
                        setDirty(true);
                        setPost({ ...post });
                      }}
                      placeholder="Nome de Autor"
                    ></Input>
                    <Input
                      className={`h-auto rounded-md bg-white px-4 w-full py-2 outline-none ${
                        author?.url && !isUrl(author.url)
                          ? 'bg-red-100'
                          : 'bg-white'
                      }`}
                      value={author.url ?? ''}
                      onChange={(e) => {
                        post.data.authors[index].url = e.target.value;
                        setDirty(true);
                        setPost({ ...post });
                      }}
                      placeholder="URL do Autor (https://...), opcional"
                    ></Input>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-1/4 h-auto flex items-center">
        <div className="item-col flex flex-col justify-start w-full border-l pl-4 h-full bg-gray-100">
          <div className="w-full flex justify-start h-auto pb-2">
            <InputCheckbox
              disabled
              className={`w-full pr-8 ${
                meta?.publishedDate
                  ? 'bg-blue-600 text-white'
                  : 'bg-transparent'
              } `}
              checked={!!meta?.publishedDate}
              label={
                <div className="flex w-full justify-between">
                  <span className="pr-4">Publicado</span>
                  {!!meta?.publishedDate && (
                    <span className=" ">
                      {moment(meta?.publishedDate).format('YYYY-MM-DD')}
                    </span>
                  )}
                </div>
              }
            ></InputCheckbox>
          </div>
          <div className="relative w-full flex pb-2">
            <InputDateSelector
              onChange={(date) => {
                setMeta((prevMeta) => ({ ...prevMeta, publishedDate: date }));
                setDirty(true);
              }}
              value={meta?.publishedDate}
              placeholder="Data de publicação (OEJ)"
              className="h-auto w-full rounded-md bg-white px-4 py-2 border border-gray-300"
            ></InputDateSelector>
          </div>
          <div className="w-full flex justify-start h-auto pb-2">
            <div className="flex w-full justify-between px-2">
              <span className="pr-4">Data de Criação</span>
              <span className="pr-4">
                {moment(meta?.createdAt).format('YYYY-MM-DD')}
              </span>
            </div>
          </div>
          <div className="w-full flex justify-start h-auto pb-2">
            <div className="flex w-full justify-between px-2">
              <span className="pr-4">Autor</span>
              <span className="pr-4">{meta?.author?.name ?? '-'}</span>
            </div>
          </div>
          <div className="flex flex-col w-full pb-2">
            <Select
              onChange={(option) => {
                setMeta((prevMeta) => ({ ...prevMeta, category: option._id }));
                setDirty(true);
              }}
              placeholder={'Categoria'}
              value={categories.find(
                (x) => !!x._id && x._id === meta?.category?._id
              )}
              className="w-full text-gray-800 leading-loose bg-transparent mb-2"
              options={categoryOptions}
            />
            <CreatableSelect
              onChange={async (newValues) => {
                for (const option of newValues) {
                  if (!option.__isNew__) {
                    continue;
                  }
                  console.log('posting new tag: ', option.label);
                  const slug = generateSlug(option.label);
                  const tag = { slug, label: option.label };
                  const response = await postTag(tag);

                  if (!response?.ok) {
                    toast.error('Erro a criar tag . Por favor tente de novo.');
                    return;
                  }
                }

                setMeta((prevMeta) => ({
                  ...prevMeta,
                  tags: newValues.map((option) => option.label)
                }));
                setDirty(true);
              }}
              isMulti
              value={meta?.tags?.map?.((tag) => ({ label: tag, value: tag }))}
              options={availableTags?.map((tag) => ({
                label: tag,
                value: tag
              }))}
              placeholder={'Escolher ou criar temas'}
              //value={meta?.tags ?? []}
              className="w-full leading-loose bg-transparent"
            />
          </div>
          <div className="w-full flex justify-end items-center h-auto pt-2 border-t border-gray-300 mt-2">
            <button
              className={`${
                !canSave
                  ? 'bg-gray-200 cursor-default'
                  : 'bg-light-blue  hover:bg-blue-500 cursor-pointer'
              } h-10 w-full text-white font-bold py-2 px-4 rounded focus:outline-none`}
              disabled={!canSave}
              onClick={canSave ? savePostToDb : () => {}}
            >
              Gravar
            </button>

            {/* <button
              className={`${
                !canSave
                  ? 'bg-gray-200 cursor-default'
                  : 'bg-red-400  hover:bg-red-500 cursor-pointer'
              } h-10 w-1/2 text-white font-bold py-2 px-4 rounded focus:outline-none ml-4`}
              disabled={!canSave}
              onClick={() => {
                if (!canSave) return;
                setPost(maybePost);
              }}
            >
              Cancelar
            </button> */}
          </div>
          <div className="w-full flex justify-end items-center h-auto pt-2 border-t border-gray-300 mt-2">
            <ConfirmDelete
              deleteLabel="Apagar"
              confirmLabel="Confirmar remoção!"
              onDelete={async () => {
                await deletePost(post._id);
                return await onDelete(post);
              }}
            ></ConfirmDelete>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResearchEditor; //withRouter(PostEditor);

const Input = ({
  value,
  placeholder = '',
  type = 'text',
  onKeyDown = () => {},
  onBlur = () => {},
  onChange = () => {},
  className = 'h-auto rounded-md bg-white px-4 w-1/2 py-2 border border-gray-300'
}) => {
  return (
    <input
      className={`${className}`}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      type={type}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
    ></input>
  );
};
