import { Auth } from 'aws-amplify';

// JS way of creating Enums:
const errors = Object.freeze({
  noResponse: (message) => ({
    error: -100,
    result: 'failure',
    message: message ? message : 'Client: No response from server'
  }),
  deserializationError: (message) => ({
    error: -200,
    result: 'failure',
    message: message ? message : 'Client: error deserializing JSON data'
  }),
  requestError: (message) => ({
    error: 400,
    result: 'failure',
    message: message ? message : 'Bad request data'
  }),
  internalServerError: (message) => ({
    error: 500,
    result: 'failure',
    message: message ? message : 'Internal Server Error'
  }),
  notFound: (message) => ({
    error: 404,
    result: 'failure',
    message: message ? message : 'Not found'
  }),
  unauthorized: (message) => ({
    error: 403,
    result: 'failure',
    message: message ? message : 'unauthorized'
  })
});

const handleResponse = async (response, awsInfo = null) => {
  if (!response || !response.status) {
    console.error('No response from server');
    return errors.noResponse();
  } else if (response.status === 400) {
    console.error('Error: 400');
    try {
      let body = await response.json();
      return errors.requestError(body.message);
    } catch (err) {
      return errors.requestError();
    }
  } else if (response.status === 404) {
    console.error('Error: 404');
    try {
      let body = await response.json();
      return errors.notFound(body.message);
    } catch (err) {
      return errors.notFound();
    }
  } else if (response.status === 403) {
    console.error('Error: 403');
    try {
      let body = await response.json();
      return errors.unauthorized(body.message);
    } catch (err) {
      return errors.unauthorized();
    }
  } else if (response.status === 500) {
    console.error('Error: 500');
    try {
      let body = await response.json();
      return errors.internalServerError(body.message);
    } catch (err) {
      return errors.internalServerError();
    }
  } else {
    let body;
    try {
      body = await response.json();
    } catch (err) {
      console.error('Error: JSON Deserialization');
      return errors.deserializationError(null);
    }

    let result = { ok: true, data: body.data ?? body };

    if (awsInfo) {
      result.AWSinfo = awsInfo;
    }

    return result;
  }
};

const authenticate = async () => {
  let userInfo;
  try {
    userInfo = await Auth.currentAuthenticatedUser({
      bypassCache: process.env.NODE_ENV === 'development'
    });
  } catch (err) {
    console.error('authentication error: ', err);
    return null;
  }
  return userInfo;
};

export { handleResponse, authenticate, errors };
