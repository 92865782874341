import React from 'react';
import { Link } from 'react-router-dom';

import './AdminMenu.scss';

const AdminMenu = ({ routes }) => {
  return (
    <div
      className={`admin-bg h-full w-full pl-3 flex flex-col justify-start items-start text-white py-8 overflow-y-scroll`}
    >
      <div className="menu h-full flex flex-col justify-start items-start">
        {traverseMenu(routes)}
      </div>
    </div>
  );
};

const traverseMenu = (routes, depth = 0) => {
  return routes?.map((route, index) => {
    if (route.noMenu) {
      return null;
    }

    return (
      <Link
        key={route.path}
        to={route.path}
        className={`w-full h-auto flex justify-start items-center pl-1 py-4`}
      >
        <span className="leading-none uppercase text-base font-medium">
          {route.label}
        </span>
      </Link>
    );
  });
};

export default AdminMenu;
