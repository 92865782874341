/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';

import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';

import { ReactComponent as ChevronDown } from '../../img/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../img/chevron-up.svg';
import { useTranslation } from 'react-i18next';

const DropdownMenu = ({
  route,
  children,
  dropdownContainerClassName = 'w-full h-full flex justify-center items-center',
  classNameInner = ''
}) => {
  const { t } = useTranslation();

  const parentRef = React.useRef(null);
  const dropdownRef = React.useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(parentRef, false);
  const onClick = (e) => {
    //console.log('click, setting true');
    e.nativeEvent.stopImmediatePropagation();
    e.stopPropagation();
    if (dropdownRef.current) {
      dropdownRef.current.focus();
    }
    setIsActive((prevActive) => !prevActive);
  };
  const onKeyPress = (e) => {
    if (e.key === 'Escape') {
      //console.log('escape, false');
      setIsActive(false);
    }
  };

  return (
    <div
      key={route.path}
      onKeyPress={onKeyPress}
      ref={parentRef}
      className="w-auto md:h-full flex justify-start md:justify-center items-start md:items-center"
    >
      <button
        onClick={onClick}
        className={`menu-trigger h-auto md:h-full w-auto flex justify-end items-center px-0 md:px-4 pb-8 md:pb-0 cursor-pointer bg-white bg-opacity-0 hover:bg-opacity-10`}
      >
        <span
          className={`leading-none uppercase text-xl md:text-lg font-medium ${classNameInner}`}
        >
          {route.i18nKey ? t(route.i18nKey) : route.label}
        </span>
        {isActive ? (
          <ChevronUp className="hidden md:block ml-1 h-4 w-4"></ChevronUp>
        ) : (
          <ChevronDown className="hidden md:block ml-1 h-4 w-4"></ChevronDown>
        )}
      </button>
      <nav
        ref={dropdownRef}
        className={`absolute hidden md:flex justify-center items-center top-full right-1/2 min-h-32 translate-x-1/2 w-full shadow-md bg-white transform transition text-gray-700 p-8 ${
          isActive
            ? 'opacity-100 visible translate-y-0'
            : 'opacity-0 invisible -translate-y-24'
        }`}
      >
        <div
          className={dropdownContainerClassName}
          tabIndex="-1"
          onBlur={(e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          {children}
        </div>
      </nav>
      <div className="w-full flex items-start flex-col md:hidden">
        {children}
      </div>
    </div>
  );
};

export default DropdownMenu;
