import { useEffect, useState } from 'react';

import PostAuthors from '../PostAuthors';
import PostDownloads from '../PostDownloads';
import './PostTOC.scss';
import { useTranslation } from 'react-i18next';

const sectionsWithHref = (sections) => {
  return sections?.filter?.((section) => !!section.toc?.href) ?? [];
};

const PostTOC = ({
  sections,
  meta,
  printRef,
  onNavigate = () => {},
  className = ''
}) => {
  const { t } = useTranslation();
  const sectionsWithToc = sectionsWithHref(sections);
  const ids = [];

  // This is itentionally ugly. We only support nested TOCs up to 2 levels
  for (const section of sectionsWithToc) {
    if (section.toc?.href) {
      ids.push(section.toc?.href);
    }
    if (Array.isArray(section.sections)) {
      for (const subsection of section.sections) {
        if (subsection.toc?.href) {
          ids.push(subsection.toc?.href);
        }
      }
    }
  }

  const activeId = useActiveId(ids);

  const createTitle = (section, parent) => {
    const toc = section?.toc;
    let result = [];

    if (toc?.href) {
      result.push(
        <li className="relative flex pb-4" key={toc.href}>
          <div
            className={`toc-indicator ${
              activeId === section?.toc?.href ? 'active' : ''
            }`}
          ></div>
          <a
            onClick={() => onNavigate(parent ? parent.toc?.href : toc?.href)}
            className={`font-light z-40 text-xl h-auto ${
              toc.level === 0 ? 'pl-0' : 'pl-6'
            }`}
            href={window.location.href.replace(/#.*$/, '') + '#' + toc.href}
          >
            {toc.title}
          </a>
        </li>
      );
    }

    if (Array.isArray(section.sections)) {
      for (const subSection of section.sections) {
        result = result.concat(createTitle(subSection, section));
      }
    }

    return result;
  };

  return (
    <div
      className={`toc relative bg-gray-100 rounded-l-lg w-full h-full px-12 py-8 hidden md:flex flex-col justify-start items-start ${className}`}
    >
      {sectionsWithToc?.length > 0 ? (
        <>
          <h3 className="toc-title relative w-full font-medium text-3xl uppercase leading-normal pb-4">
            {t('posts.toc.title')}
          </h3>
          <ul>
            {sectionsWithToc.map?.((section, index) => createTitle(section))}
          </ul>
          <div className="w-full pt-0 pb-4">
            <div className="w-full h-0 border-b-2 border-blue-300"></div>
          </div>
        </>
      ) : null}
      {!!meta?.authors?.length && (
        <>
          <PostAuthors {...meta}></PostAuthors>
          <div className="w-full pt-0 pb-4">
            <div className="w-full h-0 border-b-2 border-blue-300"></div>
          </div>
        </>
      )}
      {!!printRef && (
        <>
          <PostDownloads printRef={printRef} {...meta}></PostDownloads>
          <div className="w-full pt-0 pb-4">
            <div className="w-full h-0 border-b-2 border-blue-300"></div>
          </div>
        </>
      )}
      {!!meta?.publicationDetails && (
        <>
          <div className="w-full py-4">
            <div className="w-full h-0 border-b-2 border-blue-300"></div>
          </div>
          <h3 className="toc-title relative w-full font-medium text-3xl leading-normal pb-4">
            {t('posts.toc.details')}
          </h3>
          <div className="w-full h-full flex flex-col justify-start items-start pb-4">
            <p className="flex text-xl font-light">
              {meta?.publicationDetails ?? ''}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default PostTOC;

const browserSupportsIntersectionObserver = () => {
  return (
    'IntersectionObserver' in window ||
    'IntersectionObserverEntry' in window ||
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  );
};

function useActiveId(itemIds) {
  const [activeElement, setActiveElement] = useState(null);

  useEffect(() => {
    const supportsIO = browserSupportsIntersectionObserver();
    let observer;
    if (supportsIO) {
      observer = new IntersectionObserver(
        (entries) => {
          for (let index = 0; index < entries.length; index++) {
            const entry = entries[index];
            if (entry.isIntersecting) {
              setActiveElement(entry.target.id);
            }
          }
        },
        { rootMargin: `0% 0% -35% 0%`, threshold: 0.05 }
      );

      itemIds.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          observer.observe(element);
        }
      });
    }

    return () => {
      if (supportsIO) {
        observer.disconnect(); // Just destroy the observer
        // itemIds.forEach((id) => {
        //   observer.unobserve(document.getElementById(id));
        // });
      }
    };
  }, [itemIds]);

  return activeElement;
}
