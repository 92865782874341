import React from 'react';

import './InputNumberButton.scss';

const InputNumberButton = ({
  onChange = () => {},
  className = '',
  label = '',
  initialValue,
  value: maybeValue
}) => {
  const [value, setValue] = React.useState(maybeValue ?? initialValue ?? 0);

  return (
    <div className={`input-number-button ${className}`}>
      {!!label && (
        <label
          for="custom-input-number"
          className="w-full text-current text-sm"
        >
          {label}
        </label>
      )}
      <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
        <button
          className=" text-gray-600 text-current hover:bg-gray-300 border border-gray-300 focus:outline-none h-full w-20 rounded-l cursor-pointer outline-none"
          onClick={() => {
            const newValue = value > 0 ? value - 1 : 0;
            setValue(newValue);
            onChange(newValue);
          }}
        >
          <span className="m-auto text-2xl font-thin">−</span>
        </button>
        <input
          type="number"
          className="focus:outline-none text-center w-full border-t border-b bg-transparent border-gray-300 font-semibold text-md text-current  md:text-basecursor-default flex items-center  outline-none"
          name="custom-input-number"
          value={value}
          onChange={(evt) => {
            const newValue = evt.target.value;
            setValue(newValue);
            onChange(newValue);
          }}
        ></input>
        <button
          className="border border-gray-300 text-current focus:outline-none hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
          onClick={() => {
            const newValue = value < 11 ? value + 1 : 0;
            setValue(newValue);
            onChange(newValue);
          }}
        >
          <span className="m-auto text-2xl font-thin">+</span>
        </button>
      </div>
    </div>
  );
};

export default InputNumberButton;
