/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';

import MainMenu from '../../components/Menu';
import Footer from '../../components/Footer';
import routes from '../../routes';
import footerRoutes from '../../routes/footer';

import graph from '../../img/marcus_winkler_graph.jpg';

import './PageAboutData.scss';
import { useTranslation } from 'react-i18next';

const PageAboutData = () => {
  const { t } = useTranslation();
  const imageBackground = (
    <>
      <div className="absolute z-0 top-0 left-0 w-full h-full">
        <img
          className="w-full h-full object-cover object-center"
          alt={'featured image'}
          src={graph}
        ></img>
      </div>
      <div className="overlay absolute z-10 top-0 left-0 w-full h-full"></div>
    </>
  );

  return (
    <div id="aboutthedata" className="w-full h-full flex flex-col">
      <MainMenu routes={routes}></MainMenu>
      <div className="relative w-full flex justify-center items-center">
        {imageBackground}
        <div className="w-8/12 z-20 text-white flex flex-col pt-24 pb-32">
          <span className="font-light italic text-2xl leading-snug md:leading-normal mb-8">
            {t('pages.aboutData.breadcrumb')}
          </span>
          <h1 className="w-4/5 text-4xl font-bold mb-8">
            {t('pages.aboutData.title')}
          </h1>
          <h2 className="meta-title w-full font-medium text-3xl  leading-snug md:leading-normal mb-8">
            {t('pages.aboutData.personnelTitle')}
          </h2>
          <span className=" text-xl font-sans font-light mb-8">
            {t('pages.aboutData.personnelBody')}
          </span>
          <h2 className="meta-title w-full font-medium text-3xl  leading-snug md:leading-normal mb-8">
            {t('pages.aboutData.labourForceSurveyTitle')}
          </h2>
          <span className=" text-xl font-sans font-light mb-8">
            {t('pages.aboutData.labourForceSurveyBody')}
          </span>
          <div className="flex flex-col text-xl font-light mb-8">
            <h2 className="meta-title w-full font-medium text-3xl  leading-snug md:leading-normal mb-8">
              {t('pages.aboutData.jobSurveyTitle')}
            </h2>
            <span>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.ine.pt/xportal/xmain?xpid=INE&amp;xpgid=ine_main"
              >
                https://www.ine.pt/xportal/xmain?xpid=INE&amp;xpgid=ine_main
              </a>
            </span>
          </div>
          <span className=" text-xl font-sans font-light mb-8">
            {t('pages.aboutData.jobSurveyBody')}
          </span>
          <h2 className="meta-title w-full font-medium text-3xl  leading-snug md:leading-normal mb-8">
            {t('pages.aboutData.additionalDataTitle')}
          </h2>
          <div className="flex flex-col text-xl font-light mb-8">
            <span className="font-semibold">
              {t('pages.aboutData.eurostat')}
            </span>
            <span>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://ec.europa.eu/eurostat/web/main/data/database"
              >
                https://ec.europa.eu/eurostat/web/main/data/database
              </a>
            </span>
          </div>
          <span className=" text-xl font-sans font-light mb-8">
            {t('pages.aboutData.eurostatBody')}
          </span>
          <div className="flex flex-col text-xl font-light mb-8">
            <span className="font-semibold">{t('pages.aboutData.iefp')}</span>
            <span>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.iefp.pt/estatisticas"
              >
                https://www.iefp.pt/estatisticas
              </a>
            </span>
          </div>
          <span className=" text-xl font-sans font-light mb-8">
            {t('pages.aboutData.iefpBody')}
          </span>
          <div className="flex flex-col text-xl font-light mb-8">
            <span className="font-semibold">
              {t('pages.aboutData.socialSecurity')}
            </span>
            <span>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://www.seg-social.pt/estatisticas"
              >
                http://www.seg-social.pt/estatisticas
              </a>
            </span>
          </div>
          <span className=" text-xl font-sans font-light mb-8">
            {t('pages.aboutData.socialSecurityBody')}
          </span>
          <div className="flex flex-col text-xl font-light mb-8">
            <span className="font-semibold">
              {t('pages.aboutData.datalabor')}
            </span>
            <span>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://datalabor.pt/"
              >
                https://datalabor.pt/
              </a>
            </span>
          </div>
          <span className=" text-xl font-sans font-light mb-8">
            {t('pages.aboutData.datalaborBody')}
          </span>
          <div className="flex flex-col text-xl font-light mb-8">
            <span className="font-semibold">{t('pages.aboutData.oecd')}</span>
            <span>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://data.oecd.org/ "
              >
                https://data.oecd.org/
              </a>
            </span>
          </div>
          <span className=" text-xl font-sans font-light mb-8">
            {t('pages.aboutData.oecdBody')}
          </span>
        </div>
      </div>
      <Footer routes={footerRoutes}></Footer>
    </div>
  );
};

export default PageAboutData;
