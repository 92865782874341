import './SectionRichText.scss';

const SectionRichText = ({ toc, data, classNames, styles }) => {
  return (
    <div
      className={`section post-richtext w-full flex flex-col justify-start items-center ${classNames}`}
      style={styles}
    >
      {toc?.title && (
        <h2 className="w-full text-3xl font-regular pb-4">{toc.title}</h2>
      )}
      <div
        className="w-full text-lg font-light"
        dangerouslySetInnerHTML={{ __html: data }}
      ></div>
    </div>
  );
};

export default SectionRichText;
