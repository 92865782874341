const DEFAULT_LANGUAGE = 'pt';
const AVAILABLE_LANGUAGES = ['pt', 'en'];

const getLocalizedMetaCache = (lang, metas = []) => {
  if (lang === DEFAULT_LANGUAGE) {
    return metas?.filter?.((m) => m.isPtTranslation) ?? [];
  }
  const localized = metas.map((pm) => {
    return {
      ...pm,
      ...getPublicLocalizedContent(pm, lang)
    };
  });
  return localized;
};

const translatableKeys = ['category', 'meta'];

const getPublicLocalizedContent = (content, language) => {
  if (language === 'pt') {
    // console.log('language is pt, skipping translation');
    return content;
  }
  if (!content?.translations?.[language]) {
    console.log('no translation found, returning original content');
    return content;
  }

  const newFields = translateFields(content, translatableKeys, language);

  if (content.tags) {
    newFields.tags = getTranslatedTags(content?.tags);
  }

  return {
    ...content,
    ...content.translations[language],
    ...newFields,
    language: language
  };
};

export {
  DEFAULT_LANGUAGE,
  AVAILABLE_LANGUAGES,
  getPublicLocalizedContent,
  getLocalizedMetaCache
};

const translateFields = (content, fieldNames, language) => {
  const newFields = {};
  for (const key of fieldNames) {
    if (!content[key]) {
      continue;
    }
    const translated = getTranslatedField(content, key, language);
    if (translated) {
      newFields[key] = translated;
    }
  }

  return newFields;
};

const getTranslatedField = (content, fieldName, language) => {
  const targetField = content?.[fieldName];

  const newFields = translateFields(targetField, translatableKeys, language);

  let result;
  if (targetField?.translations?.[language]) {
    result = {
      ...content[fieldName],
      ...content[fieldName].translations[language],
      ...newFields,
      language
    };
  }

  return result;
};

const getTranslatedTags = (tagArray) => {
  if (!tagArray) return [];
  return tagArray.map((tag) => {
    // return i18n?.t('tags.' + tag) ?? tag;
    return tag;
  });
};
