/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import moment from 'moment';

import InputTextArea from '../../InputTextArea';
import S3ImageUpload from '../../S3ImageUpload';

import { getCategories } from '../../../network/categories';
import { createOnSave, generateSlug } from '../../../common/util';
import './PostHeader.scss';
import S3PdfUpload from '../../S3PdfUpload';
import { postTag } from '../../../network/tags';
import { DEFAULT_LANGUAGE } from '../../../common/translationUtils';
import TagContext from '../../../context/TagContext';

const categoryStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#ffffff',
    background: 'transparent'
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: '#ffffff'
  }),
  input: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: '#ffffff'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: '#ffffff'
  }),
  container: (provided, state) => {
    return { ...provided };
  },
  // valueContainer: (provided, state) => {
  //   return { ...provided, zIndex: 1000000 };
  // },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      backgroundColor: 'transparent',
      color: '#ffffff'
    };
  },
  multiValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      backgroundColor: 'rgba(255,255,255, 0.2)',
      color: '#ffffff'
    };
  },
  multiValueLabel: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition,
      color: '#ffffff'
    };
  },
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: '#1c599f',
    color: '#ffffff',
    border: '1px solid #ffffff',
    zIndex: 1000000
  }),
  menuList: (provided, state) => {
    return { ...provided, zIndex: 1000000 };
  }
};

const PostHeader = ({
  meta,
  language,
  onSave = () => {},
  availableTags = []
}) => {
  const [categories, setCategories] = React.useState([]);
  const { tags: allTags } = React.useContext(TagContext);

  const isLocalized = language !== DEFAULT_LANGUAGE;

  const fetchCategories = async () => {
    const response = await getCategories();
    if (!response.ok) {
      toast.error('Erro a obter categorias disponíveis');
      return;
    }
    setCategories(response.data);
  };

  React.useEffect(() => {
    if (!categories || !categories?.length) {
      fetchCategories();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const categoryOptions = categories?.map((cat) => {
    const translatedCategory = isLocalized
      ? { ...cat, ...cat?.translations?.[language] }
      : cat;
    return {
      value: translatedCategory._id,
      label: translatedCategory.label,
      _id: translatedCategory._id
    };
  });

  const imageOnSave = createOnSave(onSave, 'image');
  const categoryOnSave = createOnSave(onSave, 'category');
  const attachmentsOnSave = createOnSave(onSave, 'attachments');
  const tagsOnSave = createOnSave(onSave, 'tags');

  const handleTagChange = async (newValues, actionMeta) => {
    for (const option of newValues) {
      if (!option.__isNew__) {
        continue;
      }
      console.log('posting new tag: ', option.label);
      const slug = generateSlug(option.label);
      const tag = { slug, label: option.label };
      const response = await postTag(tag);

      if (!response?.ok) {
        toast.error('Erro a criar tag. Por favor tente de novo.');
        return;
      }
    }

    tagsOnSave(newValues.map((option) => option.value));
  };

  const getMeta = () => {
    return language !== DEFAULT_LANGUAGE
      ? meta?.translations?.[language]
      : meta;
  };

  const targetCategory = categories.find(
    (x) =>
      (!!x._id && x._id === meta?.category?._id) || x._id === meta?.category
  );
  const translatedCategory = isLocalized
    ? { ...targetCategory, ...targetCategory?.translations?.[language] }
    : targetCategory;

  const category = (
    <div key="category" className="flex flex-col w-full mb-4">
      <span className="text-sm text-white">Categoria</span>
      <Select
        onChange={(option) => {
          categoryOnSave(option._id);
        }}
        placeholder={'Seleccionar'}
        value={translatedCategory}
        className="w-3/4 font-light italic text-2xl text-white leading-loose bg-transparent"
        options={categoryOptions}
        styles={categoryStyles}
      />
    </div>
  );

  const tags = (
    <div key="tags" className="flex flex-col w-full mb-4">
      <span className="text-sm text-white">Temas</span>
      {isLocalized ? (
        <Select
          closeMenuOnSelect={false}
          onChange={handleTagChange}
          isMulti
          value={meta?.tags?.map?.((tag) => {
            if (isLocalized) {
              const targetTag = allTags.find((x) => x?.label === tag);
              let label = targetTag?.translations?.[language]?.label;
              if (!label) {
                label = `[Missing Translation] ${tag}`;
              }
              return {
                label,
                value: tag
              };
            }
            return { label: tag, value: tag };
          })}
          options={availableTags.map((tag) => {
            if (isLocalized) {
              const targetTag = allTags.find((x) => x?.label === tag);
              let label = targetTag?.translations?.[language]?.label;
              if (!label) {
                label = `[Missing Translation] ${tag}`;
              }
              return {
                label,
                value: tag
              };
            }
            return { label: tag, value: tag };
          })}
          placeholder={'Escolher temas'}
          //value={meta?.tags ?? []}
          className="w-3/4 font-light italic text-2xl text-white leading-loose bg-transparent"
          styles={categoryStyles}
        ></Select>
      ) : (
        <CreatableSelect
          closeMenuOnSelect={false}
          onChange={handleTagChange}
          isMulti
          value={meta?.tags?.map?.((tag) => {
            if (isLocalized) {
              const targetTag = allTags.find((x) => x?.label === tag);
              let label = targetTag?.translations?.[language]?.label;
              if (!label) {
                label = `Missing Translation: ${tag}`;
              }
              return {
                label,
                value: tag
              };
            }
            return { label: tag, value: tag };
          })}
          options={availableTags.map((tag) => {
            if (isLocalized) {
              const targetTag = allTags.find((x) => x?.label === tag);
              return {
                label:
                  targetTag?.translations?.[language]?.label ??
                  `Missing Translation: ${tag}`,
                value: tag
              };
            }
            return { label: tag, value: tag };
          })}
          placeholder={'Escolher ou criar temas'}
          //value={meta?.tags ?? []}
          className="w-3/4 font-light italic text-2xl text-white leading-loose bg-transparent"
          styles={categoryStyles}
        />
      )}
    </div>
  );

  const title = (
    <InputTextArea
      key="title"
      value={getMeta()?.title ?? ''}
      className="z-50 meta-title w-full font-medium text-4xl text-white leading-normal bg-trasparent"
      label={<span className="text-white">Título do Artigo</span>}
      type="text"
      onChange={createOnSave(onSave, 'title')}
      affixComponentProps={{ backgroundColor: '#ffffff' }}
    ></InputTextArea>
  );

  const subtitle = (
    <InputTextArea
      key="subtitle"
      value={getMeta()?.subtitle ?? ''}
      className="z-50 meta-title w-full font-light text-2xl text-white leading-normal bg-trasparent"
      label={<span className="text-white">Excerto / Subtítulo</span>}
      type="text"
      onChange={createOnSave(onSave, 'subtitle')}
      affixComponentProps={{ backgroundColor: '#ffffff' }}
    ></InputTextArea>
  );

  const image = (
    <React.Fragment key="image">
      <span className="text-sm text-white">Background</span>
      <S3ImageUpload
        className="mb-4 bg-transparent"
        url={getMeta()?.image}
        onUpload={(url) => {
          imageOnSave(url);
        }}
        onDelete={() => {
          imageOnSave('');
        }}
      ></S3ImageUpload>
    </React.Fragment>
  );

  const imageBackground = (
    <React.Fragment key="background">
      <div className="absolute z-0 top-0 left-0 w-full h-full">
        <img
          className="w-full h-full object-cover object-center"
          alt={'featured image'}
          src={meta?.image}
        ></img>
      </div>
      <div className="overlay absolute z-10 top-0 left-0 w-full h-full"></div>
    </React.Fragment>
  );

  const attachments = (
    <React.Fragment key="attachments">
      <span className="text-sm">Anexos PDF</span>
      <S3PdfUpload
        className="mb-4 bg-transparent w-full"
        attachments={getMeta()?.attachments ?? []}
        onUpload={(attachments) => {
          attachmentsOnSave(attachments);
        }}
        onDelete={(deletedAttachment) => {
          attachmentsOnSave(
            getMeta()?.attachments?.filter(
              (attachment) => attachment.url !== deletedAttachment.url
            )
          );
        }}
      ></S3PdfUpload>
    </React.Fragment>
  );

  // const date = (
  //   <div className="w-1/2 z-50  h-auto py-3 mt-16 border-t border-white flex justify-start items-center">
  //     <Input
  //       value={meta?.date ?? ''}
  //       className="meta-title w-full font-light italic text-2xl text-white leading-loose bg-trasparent"
  //       label={
  //         <span className="text-white">Data (texto livre, por extenso)</span>
  //       }
  //       type="text"
  //       onChange={createOnSave(onSave, 'date')}
  //       affixComponentProps={{ backgroundColor: '#ffffff' }}
  //     ></Input>
  //   </div>
  // );
  const date = (
    <div className="w-1/2 z-50  h-auto py-3 mt-16 border-t border-white flex justify-start items-center">
      {moment(meta?.createdAt).format('LL')}
    </div>
  );

  return (
    <div className="admin-post-header relative w-full pt-24 flex justify-start print:justify-start items-center">
      {imageBackground}
      <div className="relative z-20 w-9/12 xl:w-7/12 h-full px-12 pb-16 flex flex-col justify-start items-start text-white">
        {image}
        {category}
        {tags}
        {title}
        {subtitle}
        {date}
      </div>
      <div className="relative z-20 w-3/12 xl:w-5/12 h-full px-12 pb-16 flex flex-col justify-start items-start">
        {attachments}
      </div>
    </div>
  );
};

export default PostHeader;
