import React from 'react';
import { Popover, ArrowContainer } from 'react-tiny-popover';

const ArrowPopover = ({
  className = '',
  targetClassName = '',
  content,
  children,
  isOpen: maybeOpen,
  onSetOpen = () => {},
  containerWidth = '40rem',
  arrowClassName = '',
  ...props
}) => {
  const [isOpen, setOpen] = React.useState(maybeOpen);
  const isControlled = typeof maybeOpen !== 'undefined' ? true : false;

  const popoverTargetProps = {
    onClick: () => {
      setOpen(true);
    },
    className: targetClassName,
    ...props
  };

  return (
    <Popover
      className={`${className}`}
      isOpen={isControlled ? maybeOpen : isOpen}
      positions={['right', 'bottom', 'top', 'left']}
      onClickOutside={() => (isControlled ? onSetOpen(false) : setOpen(false))}
      containerStyle={{ width: containerWidth }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'white'}
          arrowSize={10}
          arrowClassName={'popover-arrow ' + (arrowClassName ?? '')}
          className="popover-arrow-container"
        >
          {content}
        </ArrowContainer>
      )}
    >
      <PopoverTarget {...popoverTargetProps}>{children}</PopoverTarget>
    </Popover>
  );
};

export default ArrowPopover;

const PopoverTarget = React.forwardRef((props, ref) => (
  <div ref={ref} onClick={props.onClick}>
    {props.children}
  </div>
));
