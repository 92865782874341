/* eslint-disable lingui/no-unlocalized-strings */
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from 'react-share';

import './NewsSocial.scss';

const getSocials = (socialShares) => {
  const socials = socialShares.map((share, index) => {
    switch (share.type) {
      case 'facebook': {
        return (
          <div
            key={share.type + index}
            className={
              'h-24 md:h-12 rounded-t-xl bg-white flex justify-center items-center mr-6'
            }
          >
            <FacebookShareButton
              key={share.type + index}
              url={window.location.href}
              className="share-icon relative h-full w-full flex justify-start items-center"
            >
              <FacebookIcon className="h-full w-auto"></FacebookIcon>
            </FacebookShareButton>
          </div>
        );
      }
      case 'twitter': {
        return (
          <div
            key={share.type + index}
            className={
              'h-24 md:h-12 rounded-t-xl bg-white flex justify-center items-center mr-6'
            }
          >
            <TwitterShareButton
              url={window.location.href}
              key={share.type + index}
              className="share-icon relative h-full w-full flex justify-center items-center"
            >
              <TwitterIcon className="h-full w-auto"></TwitterIcon>
            </TwitterShareButton>
          </div>
        );
      }
      default:
        console.error('Unknown social share link type: ' + share.type);
        return <div></div>;
    }
  });
  return socials;
};

const PostHeader = ({ socialShares }) => {
  const socials = socialShares.filter((share) => !!share.enabled);

  const socialSection = socials.length ? getSocials(socials) : null;

  return (
    <div className="relative news-social w-full flex flex-row justify-center md:justify-start">
      {socialSection}
    </div>
  );
};

export default PostHeader;
