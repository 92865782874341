/* eslint-disable lingui/no-unlocalized-strings */
import { StatusOfflineIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';

const Error = ({
  className = '',
  Icon = StatusOfflineIcon,
  iconClassName = ' w-12 h-12 mr-2 stroke-current',
  message,
  messageClassName = 'text-current text-base'
}) => {
  const { t } = useTranslation();
  if (!message) {
    message = t('errors.loadingData');
  }
  return (
    <div
      className={`flex justify-center items-center text-gray-300 ${className}`}
    >
      <Icon className={iconClassName}></Icon>
      {!!message && <span className={messageClassName}>{message}</span>}
    </div>
  );
};

export default Error;
