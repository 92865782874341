/* eslint-disable lingui/no-unlocalized-strings */
import { useState } from 'react';
import { Collapse } from 'react-collapse';

import { ChevronRightIcon, ChevronDownIcon } from '@heroicons/react/outline';

import SectionMenuManager from '../SectionMenuManager';
import SectionError from '../SectionError';

import { sectionTypes } from '../sectionTypes';
import SectionItem from '../../SectionItem';
import SectionAddItem from '../../SectionItemAdd';

import { getEffects } from '../../effects/effects';
import { arrayMoveTo } from '../../../common/util';

import './SectionCollapse.scss';

const defaultSectionWrapper = ({ children, htmlId }) => (
  <>
    <div className="page-break" />
    <div id={htmlId} className="section-wrapper relative w-full h-auto">
      {children}
    </div>
  </>
);

const SectionCollapse = ({
  toc,
  sections,
  classNames,
  styles,
  options,
  index,
  onSave,
  wrapperComponent: WrapperComponent = defaultSectionWrapper
}) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(options?.isOpen);
  return (
    <div
      className={`section px-0 section-collapse w-full flex flex-col justify-start items-center ${classNames}`}
      style={styles}
    >
      <div
        onClick={() => {
          setIsCollapseOpen(!isCollapseOpen);
        }}
        className={`cursor-pointer w-full px-8 flex justify-between items-center`}
      >
        <SectionMenuManager
          toc={toc}
          index={index}
          onSave={onSave}
          titleLabel="Título do separador"
        ></SectionMenuManager>
        {isCollapseOpen ? (
          <ChevronDownIcon className="ml-8 w-20 h-20"></ChevronDownIcon>
        ) : (
          <ChevronRightIcon className="ml-8 w-20 h-20"></ChevronRightIcon>
        )}
      </div>
      <Collapse isOpened={isCollapseOpen} className={'w-full'}>
        <div className="content relative w-full h-full flex flex-col justify-start items-start">
          {sections.map((section, innerIndex) => {
            const sectionType = section.type;
            const SectionComponent =
              sectionTypes[sectionType].component ?? SectionError;

            const { styles, classNames } = getEffects(section.effects);

            const options = section.options ?? {};

            return (
              <SectionItem
                key={section.type + innerIndex}
                index={innerIndex}
                totalItems={sections.length}
                onDelete={async (innerIndex) => {
                  const newSections = [...sections];
                  newSections.splice(innerIndex, 1);
                  return await onSave(
                    `sections.${index}.sections`,
                    newSections
                  );
                }}
                onMoveUp={(movingIndex) => {
                  const newSections = arrayMoveTo(
                    sections,
                    movingIndex,
                    movingIndex - 1
                  );
                  console.log('onnMoveUp', sections, newSections);
                  return onSave(`sections.${index}.sections`, newSections);
                }}
                onMoveDown={(movingIndex) => {
                  const newSections = arrayMoveTo(
                    sections,
                    movingIndex,
                    movingIndex + 1
                  );
                  console.log('onnMoveDown', sections, newSections);
                  return onSave(`sections.${index}.sections`, newSections);
                }}
                className="w-full mb-4"
              >
                <WrapperComponent htmlId={section.toc?.href}>
                  <SectionComponent
                    parent={{ toc, sections, index, styles, options }}
                    onSave={(path, value) => {
                      const nestedPath = `sections.${index}.${path}`;
                      //console.log('onSave nested path: ', nestedPath);
                      onSave(nestedPath, value);
                    }}
                    id={section.toc?.href}
                    {...section}
                    index={innerIndex}
                    options={options ?? {}}
                    key={'' + section.meta?.title + innerIndex}
                    classNames={classNames}
                    styles={styles}
                  ></SectionComponent>
                </WrapperComponent>
              </SectionItem>
            );
          })}
          <div className="w-full px-8">
            <SectionAddItem
              disableSectionTypes={['collapse']}
              onCreate={async (newSectionObject) => {
                const newSections = [...sections];
                newSections.push(newSectionObject);
                return await onSave(`sections.${index}.sections`, newSections);
              }}
              className="w-full bg-bluegray-300 bg-opacity-20 rounded-md"
            ></SectionAddItem>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default SectionCollapse;
