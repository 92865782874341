const InputCheckbox = ({
  onChange = () => {},
  checked,
  label,
  className = '',
  labelClassName = '',
  disabled
}) => {
  return (
    <label
      className={`inline-flex items-center h-12 rounded-md border border-gray-300 p-2 ${className}`}
    >
      <input
        disabled={disabled}
        type="checkbox"
        checked={checked}
        className="w-6 text-current h-12"
        onChange={onChange}
      />
      {!!label && (
        <span
          className={`flex-grow ml-2 text-sm text-current ${labelClassName}`}
        >
          {label}
        </span>
      )}
    </label>
  );
};

export default InputCheckbox;
