/* eslint-disable lingui/no-unlocalized-strings */
import Select from 'react-select';

const FormikSelectField = ({
  options,
  field,
  form,
  placeholder = 'Select...',
  className = ''
}) => (
  <Select
    className={`${className}`}
    options={options}
    placeholder={placeholder}
    name={field.name}
    value={
      options ? options.find((option) => option.value === field.value) : ''
    }
    onChange={(option) => form.setFieldValue(field.name, option.value)}
    onBlur={field.onBlur}
  />
);

export default FormikSelectField;
