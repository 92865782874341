/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { withTranslation, Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import isURL from 'validator/lib/isURL';

import TableauReport from '../../components/TableauReact';
import MainMenu from '../../components/Menu';
import Footer from '../../components/Footer';
import routes from '../../routes';
import footerRoutes from '../../routes/footer';
import Hero from '../../components/Hero';
import PostGrid from '../../components/PostGrid';

import { getPageByName } from '../../network/pages';
import {
  DEFAULT_LANGUAGE,
  getLocalizedMetaCache,
  getPublicLocalizedContent
} from '../../common/translationUtils';
import iscte from '../../img/iscte.jpg';

import './Home.scss';
import { getPostMetas } from '../../network/postMetas';

const Home = () => {
  const [postMetas, setPostMetas] = React.useState([]);
  const [postMetaCache, setPostMetaCache] = React.useState([]);
  const [newsMetas, setNewsMetas] = React.useState([]);
  const [newsMetaCache, setNewsMetaCache] = React.useState([]);
  const [heroSlides, setHeroSlides] = React.useState([]);
  const [featuredIndicator, setFeaturedIndicator] = React.useState('');
  const [featuredIndicatorCache, setFeaturedIndicatorCache] = React.useState(
    []
  );

  const { t, i18n } = useTranslation();

  const isLocalized = i18n.language !== DEFAULT_LANGUAGE;

  const init = async () => {
    const response = await getPageByName('indicators');
    console.log(response?.data);
    if (response.ok) {
      const pageData = response?.data;
      if (pageData) {
        const localized = getPublicLocalizedContent(pageData, i18n.language);
        setFeaturedIndicator(localized?.data?.featuredIndicator ?? {});
        setFeaturedIndicatorCache(pageData);
      }
    }

    const postResponse = await getPostMetas('post');
    if (postResponse?.ok) {
      const published =
        postResponse.data?.filter((x) => !!x?.publishedDate) ?? [];
      const localized = getLocalizedMetaCache(i18n.language, published);
      setPostMetas(localized);
      setPostMetaCache(published);
    } else {
      toast.error(t('errors.loadingPosts'));
    }

    const newsResponse = await getPostMetas('news');
    if (newsResponse?.ok) {
      const published =
        newsResponse.data?.filter((x) => !!x?.publishedDate) ?? [];
      const localized = getLocalizedMetaCache(i18n.language, published);
      setNewsMetas(localized);
      setNewsMetaCache(published);
    } else {
      toast.error(t('errors.loadingNews'));
    }
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let heroSlides = newsMetas.filter((news) => news?.featured) ?? [];
    if (isLocalized) {
      heroSlides = heroSlides.filter((pm) => pm.init);
    }

    setHeroSlides(heroSlides);
  }, [isLocalized, newsMetas]);

  React.useEffect(() => {
    localizeContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const localizeContent = () => {
    console.log(
      'Language changed, updating post metas to lang ',
      i18n.language
    );
    const localizedPosts = getLocalizedMetaCache(i18n.language, postMetaCache);
    setPostMetas(localizedPosts);

    const localizedNews = getLocalizedMetaCache(i18n.language, newsMetaCache);
    setNewsMetas(localizedNews);

    const localizedIndicator = getPublicLocalizedContent(
      featuredIndicatorCache,
      i18n.language
    );

    setFeaturedIndicator(localizedIndicator?.data?.featuredIndicator);
  };

  const featuredIndicatorSection =
    featuredIndicator && isURL(featuredIndicator.url ?? '') ? (
      <div
        className={`w-10/12 md:w-8/12 section flex justify-center items-center`}
      >
        <div className="w-full z-20">
          <div
            className={`w-full h-full flex flex-col justify-start items-start`}
          >
            <div className="w-10/12 md:w-8/12 py-20 md:py-36 flex flex-col justify-center">
              <div className="w-full flex items-start">
                <div className="hidden md:flex border-b-4 border-current w-16 mt-1 h-6 mr-6"></div>
                <div className="w-full pl-4 md:pl-0 flex flex-col justify-center">
                  <h1 className="font-bold text-2xl md:text-5xl uppercase pb-2">
                    {t('pages.home.indicator.title')}
                  </h1>
                  <h2 className="font-bold text-xl md:text-3xl uppercase">
                    {featuredIndicator.title}
                  </h2>
                </div>
              </div>
            </div>

            <div className="hidden">{featuredIndicator?.url}</div>
            <div className="w-full h-auto mb-4 border-l-2 border-t-2 border-r-2 border-gray-100">
              <TableauReport
                url={featuredIndicator?.url}
                options={{ hideTabs: true, hideToolbar: true }}
                query="?:embed=yes&amp;:comments=no&amp;:toolbar=no&amp;:refresh=yes&amp;:jsdebug=no"
              />
            </div>
            <span className="w-10/12 md:w-full text-sm font-light py-1 px-2 bg-transparent  break-words whitespace-pre-line">
              {featuredIndicator.label ?? ''}
            </span>
            {!!featuredIndicator?.description && (
              <span className="w-10/12 md:w-full text-xl font-light py-1 px-2 bg-transparent break-words whitespace-pre-line pt-2 mt-2 border-t border-gray-200">
                {featuredIndicator.description ?? ''}
              </span>
            )}
          </div>
        </div>
      </div>
    ) : null;

  let postGridPosts = postMetas?.slice(0, 3) ?? [];
  if (isLocalized) {
    postGridPosts = postGridPosts.filter((pm) => pm.init);
  }

  let newsGridPosts = newsMetas?.slice(0, 5) ?? [];
  if (isLocalized) {
    newsGridPosts = newsGridPosts.filter((pm) => pm.init);
  }

  return (
    <>
      <MainMenu routes={routes}></MainMenu>
      <div
        id="home"
        className="relative flex flex-col justify-start items-center w-full"
      >
        <Hero slides={heroSlides ?? []}></Hero>
        <div
          id="indicador-em-destaque"
          className="relative w-full flex flex-col justify-center items-center bg-white pb-20 md:pb-32"
        >
          {!!featuredIndicator && featuredIndicatorSection}
        </div>
        <div className="relative w-full min-h-screen flex flex-col justify-center items-center bg-gray-100">
          <div className="w-10/12 md:w-8/12 py-20 pl-4 md:pl-0 md:py-36 flex items-center">
            <div className="hidden md:flex border-b-4 border-current w-16 mr-6"></div>
            <h1 className="font-bold text-2xl md:text-5xl uppercase">
              {t('pages.home.posts.title')}
            </h1>
          </div>
          <div className="w-10/12 md:w-8/12 flex items-center">
            <PostGrid postMetas={postGridPosts}></PostGrid>
          </div>
          <div className="w-10/12 md:w-8/12 py-20 md:py-36 pl-4 md:pl-0 flex items-center">
            <div className="hidden md:flex border-b-4 border-current w-16 mr-6"></div>
            <h1 className="font-bold text-2xl md:text-5xl uppercase">
              {t('pages.home.news.title')}
            </h1>
          </div>
          <div className="w-10/12 md:w-8/12 flex items-center pb-20 md:pb-24">
            <PostGrid postMetas={newsGridPosts}></PostGrid>
          </div>
          <div className="w-full h-auto flex flex-col md:flex-row justify-start">
            <div className="w-full min-h-48 md:w-1/4 flex overflow-hidden">
              <img
                className="h-full object-right object-cover"
                // eslint-disable-next-line lingui/no-unlocalized-strings
                alt={'featured image'}
                src={iscte}
              ></img>
            </div>
            <div className="w-full md:w-3/4 bg-dinamia-blue flex font-sans justify-center">
              <div className="w-10/12 md:w-8/12 py-20 md:py-32 z-20 flex flex-col text-white">
                <h3 className="w-full font-semibold text-3xl md:text-6xl text-white leading-normal pb-8 md:pb-16">
                  <Trans i18nKey="pages.home.about.title">
                    Sobre o <br /> Observatório de <br /> Emprego Jovem
                  </Trans>
                </h3>
                <p className="w-full font-light text-xl md:text-3xl text-white leading-relaxed">
                  {t('pages.home.about.body')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer routes={footerRoutes}></Footer>
    </>
  );
};

export default withTranslation('app')(Home);
