import './Ellipsis.scss';
const Ellipsis = ({
  backgroundColor = '#1c599f',
  scale = 1,
  zIndex = 1000,
  opacity = 1,
  className
}) => {
  return (
    <div
      className={`lds-ellipsis ${className ?? ''}`}
      style={{ transform: `scale(${scale})`, opacity, zIndex }}
    >
      <div style={{ backgroundColor }}></div>
      <div style={{ backgroundColor }}></div>
      <div style={{ backgroundColor }}></div>
      <div style={{ backgroundColor }}></div>
    </div>
  );
};

export default Ellipsis;
