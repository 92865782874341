import { getEffects } from '../../effects/effects';

import SectionError from '../SectionError';

import SectionItem from '../../SectionItem';
import SectionAddItem from '../../SectionItemAdd';

import { sectionTypes } from '../sectionTypes';
import { arrayMoveTo } from '../../../common/util';

import './PostBody.scss';

const defaultSectionWrapper = ({ children, htmlId }) => (
  <>
    <div className="page-break" />
    <div id={htmlId} className="section-wrapper relative w-full h-auto">
      {children}
    </div>
  </>
);

const PostBody = ({
  post,
  WrapperComponent = defaultSectionWrapper,
  onSave = () => {},
  disableSectionTypes = []
}) => {
  const sections = post?.sections ?? [];
  return (
    <div className="content relative w-7/12 h-full pb-16 print:block print:w-full print:text-left flex flex-col justify-start items-start">
      {sections.map((section, index) => {
        const sectionType = section.type;
        const SectionComponent =
          sectionTypes[sectionType].component ?? SectionError;

        const { styles, classNames } = getEffects(section.effects);

        const options = section.options ?? {};

        return (
          <SectionItem
            key={section.type + index}
            index={index}
            totalItems={sections.length}
            onDelete={async (index) => {
              const newSections = [...sections];
              newSections.splice(index, 1);
              return await onSave('sections', newSections);
            }}
            onMoveUp={(index) => {
              const newSections = arrayMoveTo(sections, index, index - 1);
              console.log('onMoveUp', index, index - 1, sections, newSections);
              return onSave(`sections`, newSections);
            }}
            onMoveDown={(index) => {
              const newSections = arrayMoveTo(sections, index, index + 1);
              console.log(
                'onnMoveDown',
                index,
                index + 1,
                sections,
                newSections
              );
              return onSave(`sections`, newSections);
            }}
            className="w-full mb-4"
            width="12"
          >
            <WrapperComponent htmlId={section.toc?.href}>
              <SectionComponent
                onSave={onSave}
                id={section.toc?.href}
                {...section}
                index={index}
                options={options ?? {}}
                key={'' + section.meta?.title + index}
                classNames={classNames}
                styles={styles}
              ></SectionComponent>
            </WrapperComponent>
          </SectionItem>
        );
      })}
      <SectionAddItem
        onCreate={async (newSectionObject) => {
          const newSections = [...sections];
          newSections.push(newSectionObject);
          return await onSave('sections', newSections);
        }}
        disableSectionTypes={disableSectionTypes}
        className="w-full bg-bluegray-300 rounded-md"
      ></SectionAddItem>
    </div>
  );
};

export default PostBody;
