import Home from '../pagesPublic/Home';
import AdminHome from '../pagesAdmin/Home';
import Post from '../pagesPublic/Post';
import News from '../pagesPublic/News';
import NewsList from '../pagesPublic/NewsList';
import PostList from '../pagesPublic/PostList';
import PageIndicators from '../pagesPublic/PageIndicators';
import PageTeam from '../pagesPublic/PageTeam';
import PageAbout from '../pagesPublic/PageAbout';
import PagePartners from '../pagesPublic/PagePartners';
import PageAboutData from '../pagesPublic/PageAboutData';
import PageGlossary from '../pagesPublic/PageGlossary';
import Login from '../pagesPublic/AuthLogin';
import Renew from '../pagesPublic/AuthRenew';
import Recover from '../pagesPublic/AuthRecover';
import { IdentificationIcon } from '@heroicons/react/outline';
import { Redirect } from 'react-router-dom';
import ResearchList from '../pagesPublic/ResearchList';

const routes = [
  {
    label: 'Login',
    i18nKey: 'menus.main.login',
    noMenu: true,
    path: '/login',
    exact: false,
    component: Login
  },
  {
    label: 'Renew Password',
    i18nKey: 'menus.main.renewPassword',
    noMenu: true,
    path: '/renew',
    exact: false,
    component: Renew
  },
  {
    label: 'Recover Password',
    i18nKey: 'menus.main.recoverPassword',
    noMenu: true,
    path: '/recover',
    exact: false,
    component: Recover
  },
  {
    label: (
      <IdentificationIcon className="h-8 w-8 font-xs"></IdentificationIcon>
    ),
    //noMenu: true,
    protected: false,
    noMenu: true,
    path: '/admin',
    exact: false,
    component: AdminHome
  },
  {
    label: 'Single Post',
    i18nKey: 'menus.main.singlePost',
    noMenu: true,
    path: '/p/:id',
    exact: false,
    component: Post
  },
  {
    label: 'Single News',
    i18nKey: 'menus.main.singleNews',
    noMenu: true,
    path: '/n/:id',
    exact: false,
    component: News
  },
  {
    label: 'Featured Indicator',
    i18nKey: 'menus.main.featuredIndicator',
    noMenu: true,
    path: '/indicador-em-destaque',
    exact: false,
    component: () => {
      return <Redirect to={'/#indicador-em-destaque'}></Redirect>;
    }
  },
  {
    label: 'oej',
    i18nKey: 'menus.main.oej',
    path: '/',
    exact: true,
    component: Home,
    routes: [
      {
        label: 'Sobre o OEJ',
        i18nKey: 'menus.main.about',
        path: '/sobre',
        component: PageAbout
      },
      {
        label: 'A Equipa',
        i18nKey: 'menus.main.team',
        path: '/equipa',
        component: PageTeam
      },
      {
        label: 'Parcerias',
        i18nKey: 'menus.main.partners',
        path: '/parcerias',
        component: PagePartners
      },
      {
        label: 'Sobre os Dados',
        i18nKey: 'menus.main.aboutData',
        path: '/sobreosdados',
        component: PageAboutData
      },
      {
        label: 'Contacto',
        i18nKey: 'menus.main.contact',
        path: '#contacto'
      }
    ]
  },
  {
    label: 'Indicadores-chave',
    i18nKey: 'menus.main.indicators',
    path: '/indicadores',
    component: PageIndicators
  },
  {
    label: 'Publicações',
    i18nKey: 'menus.main.posts',
    path: '/publicacoes',
    component: PostList
  },
  {
    label: 'Notícias',
    i18nKey: 'menus.main.news',
    path: '/noticias',
    component: NewsList
  },
  {
    label: 'Investigação',
    i18nKey: 'menus.main.research',
    path: '/investigacao',
    component: ResearchList
  },
  {
    label: 'Glossário',
    i18nKey: 'menus.main.glossary',
    path: '/glossario',
    component: PageGlossary
  }
];
export default routes;
