import './Ring.scss';
const Ring = ({
  color = '#1c599f',
  zIndex = 1000,
  size = 80,
  opacity = 1,
  className
}) => {
  const innerSize = (size * 4) / 5;
  const margin = size / 10;
  return (
    <div
      className={`lds-ring ${className ?? ''}`}
      style={{ width: size, height: size, opacity, zIndex }}
    >
      <div
        style={{
          border: `${margin}px solid ${color}`,
          borderColor: `${color} transparent transparent transparent`,
          margin,
          width: innerSize,
          height: innerSize
        }}
      ></div>
      <div
        style={{
          border: `${margin}px solid ${color}`,
          borderColor: `${color} transparent transparent transparent`,
          margin,
          width: innerSize,
          height: innerSize
        }}
      ></div>
      <div
        style={{
          border: `${margin}px solid ${color}`,
          borderColor: `${color} transparent transparent transparent`,
          margin,
          width: innerSize,
          height: innerSize
        }}
      ></div>
      <div
        style={{
          border: `${margin}px solid ${color}`,
          borderColor: `${color} transparent transparent transparent`,
          margin,
          width: innerSize,
          height: innerSize
        }}
      ></div>
    </div>
  );
};

export default Ring;
