import Dashboard from '../pagesAdmin/Dashboard';
import PostList from '../pagesAdmin/PostList';
import NewsList from '../pagesAdmin/NewsList';
import PostEditor from '../pagesAdmin/PostEditor';
import NewsEditor from '../pagesAdmin/NewsEditor';
import CategoryEditor from '../pagesAdmin/CategoryEditor';
import PageIndicators from '../pagesAdmin/PageIndicators';
import PageFeaturedIndicator from '../pagesAdmin/PageFeaturedIndicator';
import UserManagement from '../pagesAdmin/UserManagement';
import ResearchList from '../pagesAdmin/ResearchList';
import AdminTagEditor from '../pagesAdmin/TagEditor';

const routes = [
  {
    label: 'Home',
    protected: true,
    path: '/admin',
    exact: true,
    component: Dashboard
  },
  {
    label: 'Post Editor',
    protected: true,
    noMenu: true,
    path: '/admin/p/:id',
    exact: false,
    render: (props) => <PostEditor {...props}></PostEditor>
  },
  {
    label: 'News Editor',
    protected: true,
    noMenu: true,
    path: '/admin/n/:id',
    exact: false,
    render: (props) => <NewsEditor {...props}></NewsEditor>
  },
  {
    label: 'Publicações',
    protected: true,
    path: '/admin/posts',
    exact: false,
    render: ({ postMetas }) => (
      <PostList
        type="post"
        typeLabel="publicação"
        typeRoute="/admin/p"
        postMetas={postMetas}
      ></PostList>
    )
  },
  {
    label: 'Investigação',
    protected: true,
    path: '/admin/research',
    exact: false,
    render: ({ postMetas, ...rest }) => (
      <ResearchList
        type="research"
        typeLabel="Item de Investigação"
        typeRoute="/admin/r"
        postMetas={postMetas}
        {...rest}
      ></ResearchList>
    )
  },
  {
    label: 'Notícias',
    protected: true,
    path: '/admin/news',
    exact: false,
    render: ({ postMetas }) => (
      <NewsList
        type="news"
        typeLabel="notícia"
        typeRoute="/admin/n"
        postMetas={postMetas}
      ></NewsList>
    )
  },

  {
    label: 'Categorias',
    protected: true,
    path: '/admin/categories',
    exact: false,
    render: ({ categories }) => (
      <CategoryEditor categories={categories ?? []}></CategoryEditor>
    )
  },
  {
    label: 'Tags',
    protected: true,
    path: '/admin/tags',
    exact: false,
    render: ({ tags }) => <AdminTagEditor tags={tags ?? []}></AdminTagEditor>
  },
  {
    label: 'Indicadores',
    protected: true,
    path: '/admin/indicators',
    exact: true,
    component: PageIndicators
  },
  {
    label: 'Indicador em Destaque',
    protected: true,
    path: '/admin/featured-indicator',
    exact: true,
    component: PageFeaturedIndicator
  },
  {
    label: 'Utilizadores',
    protected: true,
    noMenu: false,
    path: '/admin/users',
    exact: false,
    component: UserManagement
  }
];
export default routes;
