/* eslint-disable lingui/no-unlocalized-strings */
import { isNullish } from './../../common/util';
import { sectionTypes } from './../../components/AdminPostEditor/sectionTypes';
import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import { getPost } from '../../network/posts';

import MainMenu from '../../components/Menu';
import Footer from '../../components/Footer';
import routes from '../../routes';
import footerRoutes from '../../routes/footer';
import PostHeader from '../../components/PostRender/PostHeader';
import PostTOC from '../../components/PostRender/PostTOC';
import PostDownloads from '../../components/PostRender/PostDownloads';
import PostBody from '../../components/PostRender/PostBody';

import { Mobile } from '../../responsive';
import CodePreview from '../../components/CodePreview';
import Lost from '../../components/Lost';
import Ellipsis from '../../components/Ellipsis';

import './Post.scss';
import MetaTags from '../../components/MetaTags';
import PostAuthors from '../../components/PostRender/PostAuthors';
import { getPublicLocalizedContent } from '../../common/translationUtils';
import { useTranslation } from 'react-i18next';

const Post = ({ post: maybePost, match }) => {
  const [post, setPost] = React.useState(maybePost); // maybe comes in prop
  const [postCache, setPostCache] = React.useState(maybePost);
  const [loading, setLoading] = React.useState(true);

  const history = useHistory();

  const printRef = React.useRef();

  const { i18n } = useTranslation();

  React.useEffect(() => {
    const fetchData = async () => {
      const _id = match?.params?.id;
      const response = await getPost(_id); // otherwise fetch it
      if (response.ok) {
        setPostCache(response.data);

        const localized = getPublicLocalizedContent(
          response.data,
          i18n.language
        );

        setPost(localized);
      }
      setLoading(false);
    };
    if (!post) {
      if (match?.location?.state) {
        setPostCache(match?.location?.state);

        const localized = getPublicLocalizedContent(
          match?.location?.state,
          i18n.language
        );
        setPost(localized);
      } else {
        fetchData();
      }
    } else {
      setLoading(false);
    }
  }, [post, match.params?.id, match.location?.state, i18n.language]);

  React.useEffect(() => {
    localizeContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const localizeContent = () => {
    console.log('Language changed, updating post to lang ', i18n.language);
    const localized = getPublicLocalizedContent(postCache, i18n.language);
    setPost(localized);
  };

  const handleTocNavigate = (href, newCollapseState) => {
    const newPost = { ...post };
    const targetSection = newPost.sections.find(
      (sec) => sec.toc?.href === href
    );

    // console.log('navigate to ', href, ' of type ', targetSection?.type);
    if (!targetSection || !targetSection?.type === sectionTypes.collapse.type) {
      return;
    }

    if (!isNullish(newCollapseState)) {
      targetSection.options.isOpen = newCollapseState;
    } else {
      if (targetSection?.options) {
        targetSection.options.isOpen = true;
      }
    }
    setPost(newPost);
  };

  return (
    <>
      <MainMenu routes={routes}></MainMenu>
      <div
        className={`post relative flex print:block print:static print:text-left flex-col justify-start items-center w-full min-h-screen pb-12`}
        ref={printRef}
      >
        <MetaTags
          title={post?.meta?.title}
          description={post?.meta?.subtitle}
          imageSrc={post?.meta?.image}
          url={history.location}
        ></MetaTags>
        {loading ? (
          <Ellipsis className="print:hidden"></Ellipsis>
        ) : !post ? (
          <div className="w-full h-full flex justify-center items-center print:hidden">
            <Lost></Lost>
          </div>
        ) : (
          <>
            <PostHeader
              {...post?.meta}
              breadcrumb={post?.meta?.category?.label}
              socialShares={[
                { type: 'facebook', enabled: true },
                { type: 'twitter', enabled: true }
              ]}
            ></PostHeader>
            {process.env.NODE_ENV === 'development' && (
              <div className="bg-bluegray-300 print:hidden p-4 rounded-md h-auto w-full flex flex-col">
                <pre className="text-gray-500">dev</pre>
                <CodePreview post={post}></CodePreview>
              </div>
            )}
            <div className="w-full pt-12 px-8 md:px-0 flex justify-end print:justify-center items-start">
              <div className="content relative w-full md:w-7/12 print:w-10/12 h-full pb-16 flex print:block flex-col justify-start items-start">
                {!!post?.meta?.authors?.length && (
                  <>
                    <PostAuthors
                      className="hidden print:block w-full  p-8 mb-8 rounded-lg bg-gray-100"
                      {...post.meta}
                    ></PostAuthors>
                  </>
                )}
                <PostBody
                  sections={post?.sections}
                  onCollapseStateChange={handleTocNavigate}
                ></PostBody>
                {!!printRef && (
                  <Mobile>
                    <div className="print:hidden bg-gray-100 rounded-lg w-full h-full px-12 py-8 flex md:hidden flex-col justify-start items-start">
                      <PostDownloads
                        printRef={printRef}
                        {...post?.meta}
                      ></PostDownloads>
                    </div>
                  </Mobile>
                )}
              </div>
              <div className="relative hidden md:flex w-4/12 print:hidden h-auto pl-12">
                <PostTOC
                  printRef={printRef}
                  meta={post?.meta}
                  sections={post?.sections}
                  onNavigate={handleTocNavigate}
                ></PostTOC>
              </div>
            </div>
          </>
        )}
      </div>
      <Footer routes={footerRoutes}></Footer>
    </>
  );
};

export default withRouter(Post);
