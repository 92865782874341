/* eslint-disable lingui/no-unlocalized-strings */
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';

import { sectionTypes } from '../AdminPostEditor/sectionTypes';

const SectionAddItem = ({
  onCreate = () => {},
  className = '',
  toolbarClassnames = '',
  disableSectionTypes = []
}) => {
  return (
    <div
      className={`section-add-container py-8 px-12 flex flex-col justify-start items-start ${className}`}
    >
      <div className="text-xl w-full font-regular pb-4 mb-4 border-b-2 border-bluegray-500">
        Criar Secção
      </div>
      <div
        className={`w-full flex justify-between items-center ${toolbarClassnames}`}
      >
        {sectionTypes.values.map((sectionType, index) => {
          if (disableSectionTypes.includes(sectionType.type)) {
            return null;
          }
          const Icon = sectionType?.icon ?? QuestionMarkCircleIcon;
          return (
            <div
              key={sectionType.type + index}
              onClick={() => onCreate(sectionType.getTemplate())}
              className={`section-add cursor-pointer transition text-current opacity-70 hover:opacity-100 rounded-md flex flex-col justify-center items-center`}
            >
              <Icon className="h-16 stroke-current"></Icon>
              <div>{sectionType.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SectionAddItem;
