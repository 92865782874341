import React from 'react';
import isURL from 'validator/lib/isURL';
import { toast } from 'react-toastify';

import TableauReport from '../../components/TableauReact';
import ArrayItem from '../../components/ArrayItem';
import ArrayAddItem from '../../components/ArrayItemAdd';
import Input from '../../components/Input';
import InputTextArea from '../../components/InputTextArea';
import EffectPreview from '../../components/EffectPreview';
import { effects, getEffects } from '../../components/effects/effects';

import { getPageByName, putPageByName } from '../../network/pages';

import './PageIndicators.scss';
import {
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE
} from '../../common/translationUtils';
import { get, set } from 'lodash';

const PageIndicators = () => {
  const [page, setPage] = React.useState(null);
  const [language, setLanguage] = React.useState(DEFAULT_LANGUAGE);
  const [urlErrors, setUrlErrors] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);

  const save = async () => {
    setLoading(true);
    const response = await putPageByName('indicators', page);
    if (response?.ok) {
      setPage(response.data);
      toast.success('Dados gravados com sucesso');
    } else {
      toast.error('Erro a gravar informação.');
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    const response = await getPageByName('indicators');
    console.log(response?.data);
    if (response.ok) {
      setPage(response.data);
    } else {
      toast.error('Erro a carregar categorias. Por favor tente de novo.');
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (!page?.data?.indicators || !page?.data?.intro) {
      fetchData();
    }
  }, []); // eslint-disable-line

  const setValueLocalized = async (path, value) => {
    const newPage = { ...page };
    if (language === DEFAULT_LANGUAGE) {
      set(newPage, 'data.' + path, value);
    } else {
      const localizedPath = 'translations.' + language + '.data.' + path;
      set(newPage, localizedPath, value);
    }
    setPage(newPage);
  };

  const getValueLocalized = (path) => {
    if (language === DEFAULT_LANGUAGE) {
      return get(page, 'data.' + path);
    }
    const localizedPath = 'translations.' + language + '.data.' + path;
    return get(page, localizedPath);
  };

  const currentIndicators =
    language === DEFAULT_LANGUAGE
      ? page?.data?.indicators
      : getValueLocalized('indicators');
  const indicatorItems = currentIndicators?.map((indicator, index) => {
    const { styles, classNames } = getEffects([indicator.effects]);
    return (
      <ArrayItem
        onDelete={async (index) => {
          console.log('Deleting ' + index);
          let newIndicators = [...currentIndicators];
          newIndicators.splice(index, 1);
          setValueLocalized(`indicators`, newIndicators);
        }}
        index={index}
        className="w-full flex justify-start items-center mb-4"
        key={'' + indicator.slug + index}
      >
        <div
          className={`section w-full h-full p-12 flex flex-col justify-start items-start ${classNames}`}
          style={styles}
        >
          <Input
            value={currentIndicators[index].title}
            className="meta-title w-full font-medium italic text-3xl leading-loose bg-trasparent mb-4"
            label={<span className="">Título</span>}
            type="text"
            onChange={async (value) => {
              currentIndicators[index].title = value;
              const newIndicators = [...currentIndicators];
              setValueLocalized('indicators', newIndicators);
            }}
          ></Input>
          <Input
            value={currentIndicators[index].url}
            className="meta-title w-full font-light italic text-xl leading-loose bg-trasparent mb-4"
            label={<span className="">URL</span>}
            type="text"
            onChange={async (value) => {
              if (!isURL(value)) {
                console.log('not URL');
                currentIndicators[index].url = value?.trim() ?? value;
                const newIndicators = [...currentIndicators];
                setValueLocalized('indicators', newIndicators);
                const newUrlErrors = [...urlErrors];
                if (!newUrlErrors.includes(index)) {
                  newUrlErrors.push(index);
                  setUrlErrors(newUrlErrors);
                }
              } else {
                currentIndicators[index].url = value?.trim() ?? value;
                const newIndicators = [...currentIndicators];
                setValueLocalized('indicators', newIndicators);
                const newUrlErrors = [...urlErrors].filter((x) => x !== index);
                setUrlErrors(newUrlErrors);
              }
            }}
          ></Input>
          {currentIndicators[index].url && !urlErrors.includes(index) && (
            <div className="w-full h-auto mb-4">
              <TableauReport
                url={currentIndicators[index].url}
                options={{ hideTabs: true, hideToolbar: true }}
                query="?:embed=yes&amp;:comments=no&amp;:toolbar=no&amp;:refresh=yes"
              />
            </div>
          )}
          <InputTextArea
            containerClassName="w-3/4 bg-transparent"
            className="w-full text-2xl font-light mb-4 py-1 px-2 bg-transparent"
            label="Legenda"
            type="text"
            value={currentIndicators[index].description}
            onChange={(value) => {
              currentIndicators[index].description = value;
              const newIndicators = [...currentIndicators];
              setValueLocalized('indicators', newIndicators);
            }}
          ></InputTextArea>
          <div className="effects-bg w-full p-4 mt-4 flex justify-start items-center flex-wrap bg-white rounded-md">
            {Object.keys(effects.background).map((key, effectIndex) => {
              const effect = effects.background[key];
              return (
                <div
                  key={effect?.key + effectIndex}
                  className="pr-4 flex justify-center items-center pb-3"
                >
                  <EffectPreview
                    effect={effect}
                    currentEffects={currentIndicators[index].effects}
                    onCheck={(isChecked, effect) => {
                      if (isChecked) {
                        currentIndicators[index].effects = effect.key;
                        const newIndicators = [...currentIndicators];
                        setValueLocalized('indicators', newIndicators);
                      }
                    }}
                  ></EffectPreview>
                </div>
              );
            })}
          </div>
          <div
            className={`${
              urlErrors.includes(index) ? 'flex' : 'hidden'
            } text-red-500 text-sm`}
          >
            URL Inválido. Deverá ser um URL com formato standard (https://....)
          </div>
        </div>
      </ArrayItem>
    );
  });

  const currentIntro =
    language === DEFAULT_LANGUAGE
      ? page?.data?.intro
      : get(page, 'translations.' + language + '.data.intro');

  return (
    <div className="category-list relative w-full flex flex-col justify-start items-start p-8 bg-gray-100">
      <div className="sticky z-30 top-0 shadow-xl w-full h-auto flex-shrink-0 rounded-md overflow-hidden bg-gray-300 mb-2">
        {AVAILABLE_LANGUAGES.map((lang, index) => {
          return (
            <button
              key={lang}
              onClick={() => setLanguage(lang)}
              className={`px-8 py-1 ${
                lang === language
                  ? 'bg-blue-700 text-white animate-pulse'
                  : 'bg-gray-300 text-gray-700'
              } border-r-2 border-gray-100 uppercase focus:outline-none transition`}
            >
              {lang}
            </button>
          );
        })}
      </div>
      <div className="w-full py-4 h-auto flex justify-between items-center">
        <h1 className="w-1/2 text-4xl font-bold">Indicadores-Chave</h1>
        <div className="w-1/22 h-auto rounded-md flex justify-end align-center top-0 p-4 pr-0">
          <button
            className={`${
              isLoading
                ? 'bg-gray-200 cursor-default'
                : 'bg-light-blue  hover:bg-blue-500 cursor-pointer'
            } h-12 text-white font-bold py-2 px-4 rounded focus:outline-none ml-4`}
            disabled={isLoading}
            onClick={save}
          >
            Gravar
          </button>
        </div>
      </div>
      <h2 className="w-1/2 text-2xl font-bold border-b-4 border-gray-300 pb-2 mb-4">
        Introdução
      </h2>
      <div className="w-3/4 h-auto mb-12 px-12 py-8 rounded-md bg-gray-50">
        <InputTextArea
          disabled={isLoading}
          value={currentIntro ?? ''}
          className="meta-title w-full font-medium italic text-xl leading-loose bg-white"
          label={<span className="">Texto Introdutório</span>}
          type="text"
          onChange={(value) => {
            setValueLocalized('intro', value);
          }}
        ></InputTextArea>
      </div>

      <h2 className="w-1/2 text-2xl font-bold border-b-4 border-gray-300 pb-2 mb-4">
        Indicadores
      </h2>
      {indicatorItems}
      <div
        className={`bg-white w-full flex items-center justify-between p-8 rounded-md mt-4`}
      >
        <h2 className="text-2xl font-bold">Criar indicador</h2>
        <ArrayAddItem
          disabled={!currentIndicators}
          onCreate={async () => {
            const newPage = { ...page };

            newPage?.data?.indicators?.push({
              title: '',
              description: '',
              url: '',
              effects: ''
            });

            if (!newPage?.translations) {
              newPage.translations = {};
            }

            for (const lang of AVAILABLE_LANGUAGES.filter(
              (l) => l !== DEFAULT_LANGUAGE
            )) {
              if (!newPage.translations?.[lang]) {
                newPage.translations[lang] = {
                  type: 'page',
                  name: 'indicators',
                  data: {
                    intro: '',
                    indicators: [],
                    featuredIndicator: {}
                  }
                };
              }

              newPage.translations[lang].data.indicators?.push({
                translations: {
                  [lang]: {
                    title: '',
                    description: '',
                    url: '',
                    effects: ''
                  }
                }
              });
            }
            setPage(newPage);
          }}
        ></ArrayAddItem>
      </div>
    </div>
  );
};

export default PageIndicators;
