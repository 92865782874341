/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import ReactPlayer from 'react-player';
import isURL from 'validator/lib/isURL';

import Input from '../../Input';
import InputCheckbox from '../../InputCheckbox';
import SectionMenuManager from '../SectionMenuManager';
import { createOnSave } from '../../../common/util';

import './SectionEmbed.scss';

const SectionImage = ({
  classNames,
  styles,
  options: { url, caption },
  toc,
  index,
  onSave,
  parent
}) => {
  // const width = options.width ? options.width + '%' : null;
  // const align = options.align ?? 'start';

  const [preview, setPreview] = React.useState(false);

  const embedOnSave = createOnSave(onSave, `sections.${index}.options.url`);
  const captionOnSave = createOnSave(
    onSave,
    `sections.${index}.options.caption`
  );
  const [isUrlError, setIsUrlError] = React.useState(false);

  return (
    <div
      className={`section section-embed print:hidden w-full flex flex-col justify-start items-start ${classNames}`}
      style={styles}
    >
      <SectionMenuManager
        toc={toc}
        parentToc={parent?.toc}
        index={index}
        onSave={onSave}
      ></SectionMenuManager>
      <Input
        value={url}
        className="meta-title w-full font-light italic text-xl leading-loose bg-trasparent mb-4"
        label={<span className="">URL</span>}
        type="text"
        onChange={async (value) => {
          if (!isURL(value)) {
            setIsUrlError(true);
          } else {
            setIsUrlError(false);
          }
          await embedOnSave(value?.trim() ?? '');
        }}
      ></Input>
      <div className={`${isUrlError ? 'flex' : 'hidden'} text-red-500 text-sm`}>
        URL Inválido. Deverá ser um URL com formato standard (https://....)
      </div>
      <InputCheckbox
        className="mb-4"
        // disabled={preview}
        checked={preview}
        onChange={() => {
          setPreview(!preview);
          // setTimeout(() => {
          //   setPreview(false);
          // }, 6000);
        }}
        label="Pré-visualizar"
      ></InputCheckbox>
      <div className="w-full">
        {preview && (
          <div className="player-wrapper mb-4">
            <ReactPlayer className="react-player" url={url} controls={true} />
          </div>
        )}
        <Input
          value={caption}
          className="meta-title w-full font-light italic text-sm leading-none bg-trasparent"
          label={<span className="">Legenda</span>}
          type="text"
          onChange={async (value) => {
            await captionOnSave(value?.trim() ?? '');
          }}
        ></Input>
      </div>
    </div>
  );
};

export default SectionImage;
