/* eslint-disable lingui/no-unlocalized-strings */
import op from 'object-path';

const effects = {
  background: {
    default: {
      label: 'Transp.',
      style: null,
      key: 'background.transparent',
      classNames: ''
    },
    blue: {
      label: 'Azul',
      style: null,
      key: 'background.blue',
      classNames: 'effect-bg-lightblue'
    },
    gray: {
      label: 'Cinza',
      style: null,
      key: 'background.gray',
      classNames: 'effect-bg-gray'
    },
    darkGray: {
      label: 'Cinzento',
      style: null,
      key: 'background.darkGray',
      classNames: 'effect-bg-dark-gray'
    }
  }
};

const getEffects = (effectsToApply) => {
  if (!Array.isArray(effectsToApply)) {
    return { styles: null, classNames: '' };
  }

  const styles = {};
  const classNames = [];
  for (const currentEffectPath of effectsToApply) {
    const effect = op.get(effects, currentEffectPath);
    if (effect) {
      Object.assign(styles, effect.style); // Style is an object
      classNames.push(effect.classNames); // Classname is just a string
    }
  }

  return { styles, classNames: classNames.join(' ') };
};

export { effects, getEffects };
