import React from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { isEmail, isStrongPassword } from 'validator';
import { XIcon, UserAddIcon } from '@heroicons/react/outline';

import AppContext from '../../context/AppContextBase';
import FormCreateUser from './FormCreateUser';
import Ellipsis from '../../components/Ellipsis';
import ArrayItem from '../../components/ArrayItem';

import { getUsers, postUser, deleteUser } from '../../network/users';

import './UserManagement.scss';

const UserManagement = () => {
  const [users, setUsers] = React.useState([]);
  const [formOpen, setformOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState(null);
  const context = React.useContext(AppContext);

  const fetchData = async () => {
    setLoading(true);
    const response = await getUsers();
    if (response.ok) {
      setUsers(response.data);
    } else {
      toast.error('Erro a carregar utilizadores. Por favor tente de novo.');
    }
    setLoading(false);
  };

  React.useEffect(() => {
    const fetchSelf = async () => {
      setCurrentUser(await context.currentUser());
    };
    fetchSelf();

    if (!users || !users?.length) {
      setLoading(true);
      fetchData();
    }
  }, []); // eslint-disable-line

  const onUserSubmit = async (values, { setSubmitting }) => {
    const toSave = { ...values, auth: { role: values.role } };
    delete toSave.role;
    console.log(toSave);

    setSubmitting(true);

    const response = await postUser(toSave);
    if (response.ok) {
      toast.success('Utilizador criado com sucesso');
      setformOpen(false);
      fetchData();
    } else {
      console.log(response);
      toast.error(
        'Erro a criar utilizador: ' +
          (response.message ?? 'Indeterminado. Por favor tente de novo')
      );
    }

    setSubmitting(false);
  };

  const onUserDelete = async (index) => {
    const user = users[index];
    const response = await deleteUser(user.id);
    if (response.ok) {
      toast.success('Utilizador removido com sucesso');
      fetchData();
    } else {
      console.log(response);
      toast.error(
        'Erro a remover utilizador: ' +
          (response.message ?? 'Indeterminado. Por favor tente de novo')
      );
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.email || !isEmail(values.email)) {
      errors.email = 'Obrigatório';
    }

    if (
      !isStrongPassword(values.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0
      })
    ) {
      errors.password =
        'Min. 8 caracteres, com minúsculas, maiúsculas e um ou mais números';
    }
    if (!(values?.firstName?.length > 1)) {
      errors.firstName = 'Min. 2 caracteres';
    }

    if (!(values?.lastName?.length > 1)) {
      errors.lastName = 'Min. 2 caracteres';
    }

    if (!values.role) {
      errors.role = 'Obrigatório';
    }

    return errors;
  };

  return (
    <div className="category-list relative w-full flex flex-col justify-start items-start p-8">
      <h1 className="text-4xl font-bold pb-8">Utilizadores</h1>
      {loading && <Ellipsis scale={0.3} className="h-16"></Ellipsis>}
      {users.map((user, index) => {
        return (
          <ArrayItem
            onDelete={onUserDelete}
            allowDelete={
              currentUser &&
              currentUser._id !== user._id &&
              user.auth.role !== 'dev'
            }
            index={index}
            className="w-full p-8 flex justify-start items-center mb-4 bg-white hover:bg-gray-50 transition"
            key={'' + user.id}
          >
            <div className="w-full h-full flex justify-start items-start">
              <div className=" w-1/4 text-2xl pb-1">{user?.name}</div>
              <div className="h-full w-1/4 text-lg pb-1">
                <span className="text-gray-400">Registo: </span>
                <br />
                <span>
                  {user?.createdAt
                    ? moment(user?.createdAt).format('YYYY/MM/DD - HH:mm')
                    : ''}
                </span>
              </div>
              <div className="h-full w-1/8 text-lg pb-1">
                <span className="text-gray-400">Nível: </span>
                <br />
                <span>{user?.auth?.role}</span>
              </div>
            </div>
          </ArrayItem>
        );
      })}

      <div className="w-full h-auto flex flex-col bg-white rounded p-8 mb-4">
        <div className="h-12 w-auto">
          <button
            className={`h-full flex items-center ${
              !formOpen
                ? 'bg-blue-400 hover:bg-blue-500'
                : 'bg-red-400 hover:bg-red-500'
            } text-white py-2 px-4 rounded focus:outline-none`}
            onClick={() => {
              setformOpen(!formOpen);
            }}
          >
            {formOpen ? (
              <>
                <XIcon className="w-6 h-6"></XIcon>
                <span className="pl-4 whitespace-nowrap">Cancelar</span>
              </>
            ) : (
              <>
                <UserAddIcon className="w-8 h-8"></UserAddIcon>
                <span className="pl-4 whitespace-nowrap">Criar Utilizador</span>
              </>
            )}
          </button>
        </div>
        {formOpen && (
          <FormCreateUser
            validate={validate}
            onSubmit={onUserSubmit}
          ></FormCreateUser>
        )}
      </div>
    </div>
  );
};

export default UserManagement;

// eslint-disable-next-line no-unused-vars
const errorOut = async (awsError) => {
  if (awsError.message.includes('User is not confirmed.')) {
    toast.error(
      'Por favor, confirme o seu email utilizando o e-mail que lhe foi enviado.'
    );
  } else if (awsError.message.includes(' Incorrect username or password.')) {
    toast.error('Email ou password incorrectos.');
  } else if (awsError.message.includes('User does not exist.')) {
    toast.error('Email ou password incorrectos.');
  } else {
    toast.error(
      <div>
        <div>Erro inesperado. Tente de novo dentro de momentos</div>
        {awsError.message ? <pre>{awsError.message}</pre> : null}
      </div>
    );
  }
  console.error(awsError);
  return;
};
