/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';

import { getPost } from '../../network/posts';

import MainMenu from '../../components/Menu';
import Footer from '../../components/Footer';
import routes from '../../routes';
import footerRoutes from '../../routes/footer';
import NewsHeader from '../../components/PostRender/NewsHeader';
import NewsSocial from '../../components/PostRender/NewsSocial';
import PostBody from '../../components/PostRender/PostBody';

import Lost from '../../components/Lost';
import Ellipsis from '../../components/Ellipsis';

import './News.scss';
import MetaTags from '../../components/MetaTags';
import { useTranslation } from 'react-i18next';
import { getPublicLocalizedContent } from '../../common/translationUtils';

const News = ({ news: maybeNews, match }) => {
  const [news, setNews] = React.useState(maybeNews); // maybe comes in prop
  const [newsCache, setNewsCache] = React.useState(maybeNews);
  const [loading, setLoading] = React.useState(true);

  const history = useHistory();

  const { i18n } = useTranslation();

  React.useEffect(() => {
    const fetchData = async () => {
      const _id = match?.params?.id;
      const response = await getPost(_id); // otherwise fetch it
      if (response.ok) {
        setNewsCache(response.data);

        const localized = getPublicLocalizedContent(
          response.data,
          i18n.language
        );

        setNews(localized);
      }
      setLoading(false);
    };
    if (!news) {
      if (match?.location?.state) {
        setNewsCache(match?.location?.state);

        const localized = getPublicLocalizedContent(
          match?.location?.state,
          i18n.language
        );
        setNews(localized);
      } else {
        fetchData();
      }
    } else {
      setLoading(false);
    }
  }, [news, match.params?.id, match.location?.state, i18n.language]);

  React.useEffect(() => {
    localizeContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const localizeContent = () => {
    console.log('Language changed, updating post to lang ', i18n.language);
    const localized = getPublicLocalizedContent(newsCache, i18n.language);
    setNews(localized);
  };

  return (
    <>
      <MainMenu routes={routes}></MainMenu>
      <main
        className={`news relative flex print:block flex-col justify-start items-center w-full min-h-screen pb-12`}
      >
        <MetaTags
          title={news?.meta?.title}
          description={news?.meta?.subtitle}
          imageSrc={news?.meta?.image}
          url={history.location}
        ></MetaTags>
        {loading ? (
          <Ellipsis></Ellipsis>
        ) : !news ? (
          <div className="w-full h-full flex justify-center items-center">
            <Lost></Lost>
          </div>
        ) : (
          <>
            <div className="header relative w-10/12 md:w-8/12 pt-16 pb-8 md:pb-16 px-8 flex justify-center">
              <NewsHeader {...news?.meta}></NewsHeader>
            </div>

            <div className="content relative w-10/12 md:w-8/12 h-full pb-4 flex flex-col justify-start items-center">
              <PostBody
                featuredImage={news?.meta?.image}
                sections={news?.sections}
              ></PostBody>
            </div>
            <div className="header relative w-10/12 md:w-8/12 pb-0 md:pb-16 px-8 flex">
              <NewsSocial
                socialShares={[
                  { type: 'facebook', enabled: true },
                  { type: 'twitter', enabled: true }
                ]}
              ></NewsSocial>
            </div>
          </>
        )}
      </main>
      <Footer routes={footerRoutes}></Footer>
    </>
  );
};

export default withRouter(News);
