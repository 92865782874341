import isEmail from 'validator/lib/isEmail';
import './PostMeta.scss';

const PostAuthors = ({ metaTitle, authors, className = '' }) => {
  return (
    !!authors && (
      <div
        className={`authors w-full h-full pb-4 flex flex-col justify-start items-start ${className}`}
      >
        {!!metaTitle && (
          <h3 className="toc-title meta-title w-full pl-8 md:px-0 pb-4 font-medium text-3xl leading-normal">
            {metaTitle}
          </h3>
        )}
        <div className="w-full flex flex-col justify-start items-start">
          {authors.map((author, index) => {
            const last = index >= authors.length - 1;
            const contact = author?.contact?.trim?.();
            return (
              <div
                key={author.name + index}
                className={`w-full ${last ? 'pb-0' : 'pb-6'}`}
              >
                <div className="w-auto h-full flex flex-col justify-start items-start">
                  <p className=" text-xl font-medium pb-1">{author.name} </p>
                  {!!author.info && (
                    <p className="flex text-base font-light pb-1">
                      {author.info}
                    </p>
                  )}

                  {!!contact && (
                    <p className="flex italic text-xl font-normal">
                      {isEmail(contact) ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`mailto:${contact}`}
                        >
                          {contact}
                        </a>
                      ) : (
                        contact
                      )}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

export default PostAuthors;
