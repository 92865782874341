// eslint-disable-next-line no-unused-vars
import { backendAddress } from '../common/variables';
// eslint-disable-next-line no-unused-vars
import { handleResponse, authenticate, errors } from './common';

export const getPageByName = async (name = '') => {
  const userInfo = await authenticate();

  const response = await fetch(backendAddress + 'pages/name/' + name, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};

export const putPageByName = async (name, data) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'pages/name/' + name, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};

// export const postPostMeta = async (postMeta) => {
//   const userInfo = await authenticate();
//   if (!userInfo) {
//     return null;
//   }

//   const response = await fetch(backendAddress + 'postMetas', {
//     method: 'POST',
//     body: postMeta,
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
//     }
//   });

//   return await handleResponse(response);
// };

// export const deletePostMeta = async (_id) => {
//   const userInfo = await authenticate();
//   if (!userInfo) {
//     return null;
//   }

//   const response = await fetch(backendAddress + 'postMetas/' + _id, {
//     method: 'DELETE',
//     //body: data,
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//       jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
//     }
//   });

//   return await handleResponse(response);
// };
