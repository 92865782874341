/* eslint-disable lingui/no-unlocalized-strings */
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon
} from 'react-share';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import './PostHeader.scss';
import TagContext from '../../../context/TagContext';
import React from 'react';

const Socials = ({ socialShares }) => {
  const { t } = useTranslation();

  const socials = socialShares.map((share, index) => {
    // const { url, title, description, imageSrc } = share;
    switch (share.type) {
      case 'facebook': {
        return (
          <div
            key={share.type + index}
            className={cx(
              'relative min-w-40 h-12 rounded-t-xl bg-white flex justify-center items-center',
              {
                'ml-6': index !== 0
              }
            )}
          >
            <FacebookShareButton
              key={share.type + index}
              url={window.location.href}
              className="share-icon relative h-full w-full flex justify-center items-center"
            >
              <FacebookIcon className="h-full w-auto"></FacebookIcon>
              <span className="text-blue-400 text-lg font-medium pr-3">
                {t('posts.social.share')}
              </span>
            </FacebookShareButton>
          </div>
        );
      }
      case 'twitter': {
        return (
          <div
            key={share.type + index}
            className={cx(
              'relative min-w-40 h-12 rounded-t-xl bg-white flex justify-center items-center',
              {
                'ml-6': index !== 0
              }
            )}
          >
            <TwitterShareButton
              url={window.location.href}
              key={share.type + index}
              className="share-icon relative h-full w-full flex justify-center items-center"
            >
              <TwitterIcon className="h-full w-auto"></TwitterIcon>
              <span className="text-blue-400 text-lg font-medium pr-3">
                {t('posts.social.tweet')}
              </span>
            </TwitterShareButton>
          </div>
        );
      }
      default:
        console.error('Unknown social share link type: ' + share.type);
        return <div></div>;
    }
  });
  return socials;
};

const PostHeader = ({
  breadcrumb,
  tags,
  title,
  subtitle,
  image,
  date,
  socialShares
}) => {
  const { tags: allTags } = React.useContext(TagContext);
  const { i18n } = useTranslation();

  const imageBackground = (
    <>
      <div className="absolute z-0 top-0 left-0 w-full h-full">
        <img
          className="w-full h-full object-cover object-center"
          alt={'featured image'}
          src={image}
        ></img>
      </div>
      <div className="overlay absolute z-10 top-0 left-0 w-full h-full"></div>
    </>
  );

  const socials = socialShares.filter((share) => !!share.enabled);

  return (
    <div className="post-header relative w-full pt-12 flex flex-col md:flex-row justify-end print:justify-start items-end">
      {imageBackground}
      <div className="relative w-full md:w-7/12 h-full px-8 pb-16 flex flex-col justify-between items-start">
        <div className="relative z-20 w-full h-full flex flex-col justify-start items-start">
          {breadcrumb ? (
            <h3 className="w-full font-light italic text-2xl text-white leading-loose">
              {breadcrumb}
            </h3>
          ) : null}
          {tags ? (
            <div className="w-full flex flex-wrap font-light text-lg text-white leading-loose mb-4">
              {tags.map((tag) => {
                const targetTag = allTags.find((x) => x?.label === tag);
                let translatedTag =
                  targetTag?.translations?.[i18n.language]?.label;
                if (!translatedTag) {
                  translatedTag = tag;
                }
                return (
                  <div
                    key={tag}
                    className=" px-4 mr-2 mb-2 md:md-0 bg-white bg-opacity-20 rounded-md flex justify-center items-center"
                  >
                    {translatedTag}
                  </div>
                );
              })}
            </div>
          ) : null}
          <h1 className="w-3/4 pb-2 font-medium  text-4xl text-white leading-normal">
            {title}
          </h1>
          <h2 className="w-full font-light text-2xl text-white leading-normal">
            {subtitle}
          </h2>
        </div>
        <div className="relative z-20 w-full h-full flex flex-col justify-start items-start">
          {date ? (
            <div className="w-1/2 h-auto py-3 mt-12 border-t border-white flex justify-start items-center">
              <span className="w-full font-light italic text-2xl text-white leading-loose">
                {date}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="relative print:hidden z-20 w-full md:w-4/12 px-12 h-full flex justify-start items-end">
        {!!socials?.length && <Socials socialShares={socials}></Socials>}
      </div>
    </div>
  );
};

export default PostHeader;
