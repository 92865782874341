/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';

import Toolbar from './Toolbar';
import Search from '../Search';
import traverseMenu from './traverseMenu';
import logo from '../../img/logos/logo2copy.png';
import './MainMenu.scss';
import { MenuIcon, XIcon } from '@heroicons/react/solid';
import MenuItem from './MenuItem';
import LanguageSwitcher from '../LanguageSwitcher';

const MainMenu = ({ routes = [], colorClass = 'bg-dinamia-blue' }) => {
  const videoRef = React.useRef();
  const [canPlay, setCanPlay] = React.useState(false);

  React.useEffect(() => {
    const playVideo = async () => {
      if (videoRef?.current) {
        if (!videoRef.current.paused) {
          return;
        }
        try {
          await videoRef.current.play();
          // console.log('playing video');
        } catch (error) {
          // console.log('could not play video');
        }
      } else {
        // console.log('could not play video');
      }
    };

    if (videoRef.current) {
      // console.log('useEffect setting muted');
      // https://stackoverflow.com/a/66153842
      videoRef.current.defaultMuted = true;
      videoRef.current.muted = true;
      videoRef.current.autoplay = true;
      videoRef.current.src = 'videos/banner_1.m4v';
    }
    if (canPlay) {
      playVideo();
    }
  }, [videoRef, canPlay]);

  return (
    <div
      id="main-menu"
      className={`relative print:hidden w-full flex flex-col justify-center items-center overflow-y-visible z-30`}
    >
      <div className="relative w-full min-h-60 md:min-h-0 flex justify-center">
        <video
          ref={videoRef}
          loop={true}
          playsInline={true}
          type="video/mp4"
          className={`absolute object-cover object-right z-10 w-full h-full top-0 left-0 pointer-events-none`}
          onLoadedData={() => {
            // console.log('event loadedData');
            setCanPlay(true);
            //setTimeout(playVideo, 300);
          }}
        ></video>
        <div className="relative w-full md:w-10/12 h-auto flex justify-between items-center lg:items-start py-8">
          <div className="w-2/3 md:w-4/12 flex justify-center items-start pr-5 ">
            <Link to={'/'} className=" w-auto flex justify-center items-start">
              <img
                className={`w-full h-auto max-h-full max-w-full z-20 opacity-100  `}
                src={logo}
                alt="Dinamia"
              ></img>
            </Link>
          </div>
          <div
            className={`relative print:hidden h-full md:h-12 z-20 flex justify-end items-center`}
          >
            <div className="h-full w-auto md:w-10/12 pr-8 md:pr-0 flex justify-end items-center">
              <Toolbar></Toolbar>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`${colorClass} bg-opacity-100 z-20 w-full h-16 flex justify-center items-center text-white overflow-y-visible`}
      >
        <nav className="hidden md:flex menu w-full lg:w-10/12 h-full flex-wrap justify-end items-center overflow-y-visible">
          {traverseMenu(routes)}
          <MenuItem
            route={{ label: <LanguageSwitcher></LanguageSwitcher> }}
            noPadding={true}
          ></MenuItem>
        </nav>
        <div className="relative w-full mobile-menu flex md:hidden justify-between items-center px-6">
          <Search className="z-20 flex md:hidden rounded-md w-3/5 h-10 justify-start items-center"></Search>
          <MobileMenu routes={routes}></MobileMenu>
        </div>
      </div>
    </div>
  );
};

export default MainMenu;

const MobileMenu = ({ routes = [] }) => {
  return (
    <Menu
      width="100%"
      right
      outerContainerId="root"
      customBurgerIcon={<MenuIcon className="bg-transparent"></MenuIcon>}
      customCrossIcon={<XIcon className="bg-transparent"></XIcon>}
    >
      <img className={`w-full h-auto -mx-10`} src={logo} alt="Dinamia"></img>
      {traverseMenu(routes, 0, 'text-2xl')}
      <MenuItem
        route={{ label: <LanguageSwitcher className="text-2xl"></LanguageSwitcher> }}
        noPadding={true}
      ></MenuItem>
    </Menu>
  );
};
