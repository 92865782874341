import React from 'react';
import { Link } from 'react-router-dom';

import './AdminCategoryList.scss';

const AdminCategoryList = ({ categories }) => {
  const Categories = categories.map((category, index) => {
    return (
      // Use ArrayItem and so on
      <Link
        className="w-full pb-4 flex flex-col"
        key={'' + category.slug + index}
        to={{ pathname: `admin/categories`, state: category }}
      >
        <span className="text-2xl pb-1">{category.label}</span>
        <pre className="text-sm text-gray-400">/{category.slug}</pre>
      </Link>
    );
  });

  return (
    <div className="admin-category-list relative w-full flex flex-col justify-start">
      {Categories}
    </div>
  );
};

export default AdminCategoryList;
