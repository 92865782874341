import React from 'react';
import { Link } from 'react-router-dom';

import PostThumbnail from '../PostThumbnail';

import './AdminPostGrid.scss';

const PostGrid = ({ postMetas = [], pathPrefix = '/admin' }) => {
  const postThumbnails = postMetas.map((meta, index) => {
    return (
      <Link
        className="w-full"
        key={'' + meta?.title + index}
        to={{ pathname: `${pathPrefix}/p/${meta?.postId}`, state: meta }}
      >
        <PostThumbnail showEmptyFields={true} postMeta={meta}></PostThumbnail>
      </Link>
    );
  });

  return (
    <div className="post-grid relative w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 justify-center">
      {postThumbnails}
    </div>
  );
};

export default PostGrid;
