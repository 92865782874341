/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { TrashIcon } from '@heroicons/react/outline';

import Popover from '../Popover';
import Ellipsis from '../Ellipsis';

const ArrayItem = ({
  children,
  index,
  onDelete,
  widthClass = 'w-24',
  className = '',
  allowDelete = true,
  confirmLabel = 'Confirmar remoção',
  disabled = false,
  cancelLabel = 'Cancelar'
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const confirmContent = (
    <div className="w-full bg-white rounded-md shadow-md p-8">
      <div className="w-full flex flex-col items-center text-white">
        {isLoading ? (
          <Ellipsis scale={0.3} backgroundColor={'rgb(239, 68, 68)'}></Ellipsis>
        ) : (
          <ExclamationIcon className="text-red-500 w-12 h-12 mr-4 mb-4 stroke-current"></ExclamationIcon>
        )}
        <button
          disabled={isLoading || disabled}
          onClick={async () => {
            setLoading(true);
            await onDelete(index);
            setLoading(false);
            setOpen(false);
          }}
          className="h-full w-full flex justify-center items-center mb-4 bg-red-400 hover:bg-red-600 hover:shadow-md py-2 px-4 rounded focus:outline-none"
        >
          <span className="pl-4 whitespace-nowrap">{confirmLabel}</span>
        </button>
        {/* <button
          disabled={isLoading}
          onClick={() => {
            console.log('cancel');
            setOpen(false);
          }}
          className="h-full w-full flex justify-center items-center bg-gray-300 hover:bg-gray-400 hover:shadow-md py-2 px-4 rounded focus:outline-none"
        >
          <span className="pl-4 whitespace-nowrap">{cancelLabel}</span>
        </button> */}
      </div>
    </div>
  );
  return (
    <div
      className={`array-item h-full w-full bg-gray-50 rounded-md flex pr-0 ${className}`}
    >
      {children}

      <div
        onClick={() => {
          if (!(isLoading || disabled)) {
            setOpen(true);
          }
        }}
        className={`h-full ${widthClass} cursor-pointer transition pl-8 pr-4 flex flex-col justify-center align-center`}
      >
        {allowDelete ? (
          <Popover
            onSetOpen={setOpen}
            isOpen={isOpen}
            content={confirmContent}
            containerWidth="auto"
          >
            <TrashIcon
              className={`${
                isLoading || disabled
                  ? 'text-gray-400'
                  : 'text-red-200 hover:text-red-500'
              } stroke-current`}
            ></TrashIcon>
          </Popover>
        ) : (
          <TrashIcon className="text-gray-200 stroke-current"></TrashIcon>
        )}
      </div>
    </div>
  );
};

export default ArrayItem;
