import React from 'react';

const EffectPreview = ({ effect, onCheck, currentEffects = [] }) => {
  const [isChecked, setChecked] = React.useState(
    currentEffects.includes(effect.key)
  );
  
  React.useEffect(() => {
    setChecked(currentEffects.includes(effect.key));
  }, [currentEffects, effect]);

  return (
    <label className="inline-flex items-center mt-3">
      <input
        type="radio"
        className="form-radio h-5 w-5 text-gray-800"
        checked={isChecked}
        onChange={(event) => {
          onCheck(event.target.checked, effect);
        }}
      />
      <span className="ml-2 text-gray-800">{effect.label}</span>
    </label>
  );
};

export default EffectPreview;
