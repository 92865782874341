/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { useTranslation } from 'react-i18next';
// import useDetectOutsideClick from '../../hooks/useDetectOutsideClick';
import ls from 'local-storage';

const LanguageSwitcher = ({ className = 'px-4 py-4 text-xl md:text-lg' }) => {
  const { i18n } = useTranslation();
  const parentRef = React.createRef();

  // const [isOpen, setOpen] = useDetectOutsideClick(parentRef, false);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    ls.set('lang', lang);
  };

  return (
    <div
      // onClick={() => setOpen(!isOpen)}
      ref={parentRef}
      className={`overflow-visible relative`}
    >
      <div
        className={`text-white transition flex justify-start items-center uppercase font-medium ${className}`}
        onClick={() => changeLanguage(i18n.language === 'pt' ? 'en' : 'pt')}
      >
        {i18n.language === 'pt' ? 'EN' : 'PT'}
      </div>
      {/* <div className="relative bottom-0 w-full overflow-visible z-20 transform translate-y-4 -translate-x-4">
        <div
          className={`absolute overflow-hidden top-0 w-auto transition ${
            isOpen ? 'h-auto' : 'h-0'
          } flex flex-col`}
        >
          {['pt', 'en'].map((l) => {
            // console.log(l);
            const isCurrent = l === i18n.language;
            return (
              <div
                key={l}
                className={`px-4 py-4  ${
                  isCurrent
                    ? 'text-white bg-dinamia-blue bg-opacity-100'
                    : 'bg-white hover:bg-dinamia-blue text-gray-800 hover:text-white'
                }  transition flex justify-center items-center uppercase text-xl md:text-lg font-medium`}
                onClick={() => changeLanguage(l)}
              >
                {l}
              </div>
            );
          })}
        </div>
      </div> */}
    </div>
  );
};

const LanguageToggle = (lang) => {
  const { i18n } = useTranslation();

  if (!i18n.languages.includes(lang)) {
    throw new Error(`Language "${lang}" is not supported`);
  }
};

export default LanguageSwitcher;

export { LanguageToggle };
