/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import ReactQuill from 'react-quill';

import SectionMenuManager from '../SectionMenuManager';
import SectionOptions from '../SectionOptions';
import { createOnSave } from '../../../common/util';

import './SectionRichText.scss';
import 'react-quill/dist/quill.bubble.css';

const quillOptions = {
  modules: {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
      ],
      ['link' /*'image'*/]
    ]
  },

  formats: [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'align',
    //'indent',
    'link'
    //'image'
  ]
};

const SectionRichText = ({
  toc,
  data,
  classNames,
  styles,
  effects = [],
  index,
  onSave,
  parent
}) => {
  const onSaveContent = createOnSave(onSave, `sections.${index}.data`);
  const [editorValue, setEditorValue] = React.useState(data);
  const quillRef = React.createRef();

  return (
    <div
      className={`section post-richtext w-full flex flex-col justify-start items-center ${classNames}`}
      style={styles}
    >
      <SectionMenuManager
        toc={toc}
        parentToc={parent?.toc}
        index={index}
        onSave={onSave}
      ></SectionMenuManager>

      <ReactQuill
        ref={quillRef}
        className="w-full text-2xl rounded-md text-current border border-gray-300 font-light mb-4 py-1 px-2 bg-transparent"
        theme="bubble"
        {...(editorValue ? { defaultValue: editorValue } : {})}
        modules={quillOptions.modules}
        formats={quillOptions.formats}
        onChange={(editorValue) => {
          // console.log('saving editor content', editorValue);
          onSaveContent(editorValue);
          setEditorValue(editorValue);
          if (quillRef.current) {
            quillRef.current.getEditor().focus();
          }
        }}
        onBlur={() => {
          // console.log('saving editor content', editorValue);
          onSaveContent(editorValue);
        }}
      />
      <SectionOptions
        effects={effects}
        onSave={onSave}
        index={index}
      ></SectionOptions>
    </div>
  );
};

export default SectionRichText;
