/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

import InputCheckbox from '../../InputCheckbox';
import InputTextArea from '../../InputTextArea';
import ConfirmDelete from '../../ConfirmDelete';

import { createOnSave } from '../../../common/util';
import { deletePost } from '../../../network/posts';
import './PostOptions.scss';
import { DEFAULT_LANGUAGE } from '../../../common/translationUtils';

const PostOptions = ({ meta, language, onSave = () => {} }) => {
  const history = useHistory();

  const onSavePublished = createOnSave(onSave, `publishedDate`);
  const onSaveDetails = createOnSave(onSave, `publicationDetails`);

  const getMeta = () => {
    return language !== DEFAULT_LANGUAGE
      ? meta?.translations?.[language]
      : meta;
  };

  return (
    <div className="admin-post-options relative rounded-md p-8 mb-8 bg-gray-100 w-full flex flex-col justify-start items-start">
      <h3 className="meta-title w-full font-medium text-3xl leading-normal pb-4">
        Opções de publicação
      </h3>
      <div className="w-full flex justify-start h-auto pt-4">
        <InputCheckbox
          className={`w-full pr-8 ${
            meta?.publishedDate ? 'bg-blue-600 text-white' : 'bg-transparent'
          } `}
          checked={!!meta?.publishedDate}
          onChange={(e) => {
            const checked = e.target.checked;
            if (checked) {
              const date = moment();
              onSavePublished(date);
            } else {
              onSavePublished(null);
            }
          }}
          label={
            <div className="flex w-full justify-between">
              <span className="pr-4">Publicado</span>
              {!!meta?.publishedDate && (
                <span className=" ">
                  {moment(meta?.publishedDate).format('YYYY-MM-DD')}
                </span>
              )}
            </div>
          }
        ></InputCheckbox>
      </div>
      <div className="w-full flex justify-start h-auto pt-4">
        <div className="flex w-full justify-between px-2">
          <span className="pr-4">Data de Criação</span>
          <span className="pr-4">
            {moment(meta?.createdAt).format('YYYY-MM-DD')}
          </span>
        </div>
      </div>
      <div className="w-full flex justify-start h-auto pt-4">
        <div className="flex w-full justify-between px-2">
          <span className="pr-4">Autor</span>
          <span className="pr-4">{meta?.author?.name ?? '-'}</span>
        </div>
      </div>
      <div className="post-details w-full flex flex-col pt-4">
        <InputTextArea
          value={getMeta()?.publicationDetails ?? ''}
          className="w-full font-light text-lg"
          label={<span className="px-2 text-base">Detalhes da Publicação</span>}
          type="text"
          onChange={onSaveDetails}
        ></InputTextArea>
      </div>
      <div className="w-full flex justify-end h-auto pt-16">
        <ConfirmDelete
          onDelete={async () => {
            await deletePost(meta.postId);
            await onSave();
            history.push('/admin/posts');
          }}
        ></ConfirmDelete>
      </div>
    </div>
  );
};

export default PostOptions;
