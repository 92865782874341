/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import isURL from 'validator/lib/isURL';

import TableauReport from '../../components/TableauReact';
import MainMenu from '../../components/Menu';
import Footer from '../../components/Footer';
import routes from '../../routes';
import footerRoutes from '../../routes/footer';
import { getEffects } from '../../components/effects/effects';
import { getPageByName } from '../../network/pages';
import CodePreview from '../../components/CodePreview';
import { multiLineString } from '../../common/util';

import graph from '../../img/marcus_winkler_graph.jpg';

import './PageIndicators.scss';
import { useTranslation } from 'react-i18next';
import { getPublicLocalizedContent } from '../../common/translationUtils';

const PageIndicators = () => {
  const [pageCache, setPageCache] = React.useState(null);
  const [pageData, setPageData] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setLoading] = React.useState(false);

  const { t, i18n } = useTranslation();

  const fetchData = async () => {
    setLoading(true);
    const response = await getPageByName('indicators');
    if (response.ok) {
      const pageData = response?.data;
      if (pageData) {
        setPageCache(pageData);
        const localized = getPublicLocalizedContent(pageData, i18n.language);
        setPageData(localized ?? {});
      }
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (!pageData?.data?.indicators) {
      fetchData();
    }
  }, []); // eslint-disable-line

  React.useEffect(() => {
    localizeContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const localizeContent = () => {
    console.log(
      'Language changed, updating post metas to lang ',
      i18n.language
    );

    const localized = getPublicLocalizedContent(pageCache, i18n.language);
    setPageData(localized ?? {});
  };

  const indicators = pageData?.data?.indicators;
  const intro = pageData?.data?.intro;

  const indicatorItems = indicators?.map((indicator, index) => {
    const { styles, classNames } = getEffects([indicator.effects]);
    return (
      <div
        key={indicator.title + index}
        className={`w-full section flex justify-center items-center ${classNames}`}
        style={styles}
      >
        <div className="w-full md:w-9/12 z-20 pb-16 md:pb-32">
          <div
            className={`w-full h-full flex flex-col justify-start items-center`}
          >
            <div
              className={`w-10/12 md:w-full flex  justify-start items-start md:items-center pb-16`}
            >
              <div className="bg-dinamia-blue bg-opacity-100 flex-shrink-0 h-16 w-16 md:h-32 md:w-32 rounded-full hidden md:flex justify-center items-center mr-4 md:mr-12 mb-8 md:mb-0">
                <div className="font-bold text-5xl md:text-7xl text-white leading-none">
                  {index + 1}
                </div>
              </div>
              <span className="meta-title font-medium text-4xl md:text-5xl leading-normal bg-trasparent">
                <span className="bg-dinamia-blue bg-opacity-100 h-16 w-16 rounded-full inline-flex md:hidden justify-center items-center mr-4">
                  <div className="font-bold text-4xl text-white">
                    {index + 1}
                  </div>
                </span>
                {indicators[index].title}
              </span>
            </div>
            {isURL(indicators[index].url ?? ' ') && (
              <div className="w-full h-auto mb-4 border-l-2 border-t-2 border-r-2 border-gray-100">
                <TableauReport
                  url={indicators[index].url}
                  options={{ hideTabs: true, hideToolbar: true }}
                  query="?:embed=yes&amp;:comments=no&amp;:toolbar=no&amp;:refresh=yes"
                />
              </div>
            )}
            <div className="w-10/12 md:w-full text-2xl font-light mb-4 py-1 px-2 bg-transparent">
              {multiLineString(indicators[index].description ?? '')}
            </div>
          </div>
        </div>
      </div>
    );
  });

  const imageBackground = (
    <>
      <div className="absolute z-0 top-0 left-0 w-full h-full">
        <img
          className="w-full h-full object-cover object-center"
          alt={'featured image'}
          src={graph}
        ></img>
      </div>
      <div className="overlay absolute z-10 top-0 left-0 w-full h-full"></div>
    </>
  );

  return (
    <div id="indicators" className="w-full h-full flex flex-col">
      <MainMenu routes={routes}></MainMenu>
      <div className="relative w-full flex flex-col justify-start items-start ">
        <div className="header relative w-full flex justify-center items-center mb-16 md:mb-32">
          {imageBackground}

          <div className="w-10/12 z-20 text-white pt-28 pb-28">
            {process.env.NODE_ENV === 'development' && (
              <CodePreview post={indicators}></CodePreview>
            )}
            <h1 className="w-1/2 text-6xl font-bold mb-8">
              {t('pages.indicators.title')}
            </h1>
            <div className="w-full h-auto">
              <span className="meta-title w-full font-medium text-2xl md:text-3xl leading-snug md:leading-normal">
                {intro ?? ''}
              </span>
            </div>
          </div>
        </div>
        {indicatorItems}
      </div>
      <Footer routes={footerRoutes}></Footer>
    </div>
  );
};

export default PageIndicators;
