/* eslint-disable lingui/no-unlocalized-strings */
import cloneDeep from 'lodash/cloneDeep';

import SectionRichText from './SectionRichText';
import SectionCollapse from './SectionCollapse';
import SectionImage from './SectionImage';
import SectionEmbed from './SectionEmbed';
import SectionTableau from './SectionTableau';

import {
  MenuAlt1Icon,
  CameraIcon,
  FilmIcon,
  ArrowsExpandIcon,
  ChartSquareBarIcon
} from '@heroicons/react/outline';

// eslint-disable-next-line
const availableOptions = {
  collapse: { isOpen: false },
  image: {
    width: 0,
    src: '',
    alt: '',
    caption: ''
  },
  tableau: {
    caption: '',
    url: ''
  },
  embed: {
    caption: '',
    url: ''
  }
};

const defaultTemplate = {
  toc: { title: '', href: null, level: 0 },
  effects: [],
  options: {},
  data: null,
  sections: null
};

export const sectionTypes = {
  richtext: {
    label: 'Text',
    type: 'richtext',
    component: SectionRichText,
    icon: MenuAlt1Icon,
    getTemplate: () => ({
      ...cloneDeep(defaultTemplate),
      type: 'richtext',
      data: ''
    })
  },
  image: {
    label: 'Image',
    type: 'image',
    component: SectionImage,
    icon: CameraIcon,
    getTemplate: () => ({
      ...cloneDeep(defaultTemplate),
      type: 'image',
      options: {
        width: 100,
        src: '',
        alt: '',
        caption: ''
      }
    })
  },
  embed: {
    label: 'Embed',
    type: 'embed',
    component: SectionEmbed,
    icon: FilmIcon,
    getTemplate: () => ({
      ...cloneDeep(defaultTemplate),
      type: 'embed',
      options: {
        caption: '',
        url: ''
      }
    })
  },
  tableau: {
    label: 'Tableau',
    type: 'tableau',
    component: SectionTableau,
    icon: ChartSquareBarIcon,
    getTemplate: () => ({
      ...cloneDeep(defaultTemplate),
      type: 'tableau',
      options: {
        caption: '',
        url: ''
      }
    })
  },
  collapse: {
    label: 'Collapse',
    type: 'collapse',
    component: SectionCollapse,
    icon: ArrowsExpandIcon,
    getTemplate: () => ({
      ...cloneDeep(defaultTemplate),
      type: 'collapse',
      sections: [],
      options: { isOpen: false }
    })
  }
};

Object.defineProperty(sectionTypes, 'values', {
  get: () => {
    return Object.values(sectionTypes);
  },
  configurable: false,
  enumerable: false
});

Object.defineProperty(sectionTypes, 'keys', {
  get: () => {
    return Object.keys(sectionTypes);
  },
  configurable: false,
  enumerable: false
});
