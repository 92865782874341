/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';

import isURL from 'validator/lib/isURL';

import TableauReport from '../../TableauReact';
import S3ImageUpload from '../../S3ImageUpload';
import Input from '../../Input';
import InputCheckbox from '../../InputCheckbox';
import SectionMenuManager from '../SectionMenuManager';
import { createOnSave } from '../../../common/util';
import { PhotographIcon } from '@heroicons/react/outline';

import './SectionTableau.scss';

const SectionImage = ({
  classNames,
  styles,
  options: { url, caption, altImage },
  toc,
  index,
  onSave,
  parent
}) => {
  // const width = options.width ? options.width + '%' : null;
  // const align = options.align ?? 'start';

  const [preview, setPreview] = React.useState(false);
  const [isUrlError, setIsUrlError] = React.useState(false);

  const altImageOnSave = createOnSave(
    onSave,
    `sections.${index}.options.altImage`
  );
  const embedOnSave = createOnSave(onSave, `sections.${index}.options.url`);
  const captionOnSave = createOnSave(
    onSave,
    `sections.${index}.options.caption`
  );

  // https://public.tableau.com/views/Indicador1Taxadedesempregodosjovens/Planilha1?:language=en&:display_count=y&publish=yes&:origin=viz_share_link

  return (
    <div
      className={`section section-tableau print:hidden w-full flex flex-col justify-start items-center ${classNames}`}
      style={styles}
    >
      <SectionMenuManager
        toc={toc}
        parentToc={parent?.toc}
        index={index}
        onSave={onSave}
      ></SectionMenuManager>
      <figure className="w-full">
        <Input
          value={url}
          className="meta-title w-full font-light italic text-xl leading-loose bg-trasparent mb-4"
          label={<span className="">URL</span>}
          type="text"
          onChange={async (value) => {
            if (!isURL(value)) {
              setIsUrlError(true);
            } else {
              setIsUrlError(false);
            }
            await embedOnSave(value?.trim() ?? '');
          }}
        ></Input>
        <div
          className={`${isUrlError ? 'flex' : 'hidden'} text-red-500 text-sm`}
        >
          URL Inválido. Deverá ser um URL com formato standard (https://....)
        </div>
        <InputCheckbox
          className="mb-4"
          // disabled={preview}
          checked={preview}
          onChange={() => {
            setPreview(!preview);
            // setTimeout(() => {
            //   setPreview(false);
            // }, 6000);
          }}
          label="Pré-visualizar"
        ></InputCheckbox>
        {url && preview && (
          <TableauReport
            url={url}
            options={{ hideTabs: true, hideToolbar: true }}
            query="?:embed=yes&amp;:comments=no&amp;:toolbar=no&amp;:refresh=yes"
          />
        )}
        <Input
          value={caption}
          className="meta-title w-full font-light italic text-sm leading-none bg-trasparent mb-4"
          label={<span className="">Legenda</span>}
          type="text"
          onChange={async (value) => {
            await captionOnSave(value?.trim() ?? '');
          }}
        ></Input>
        <figure className="w-full">
          <span className="text-sm">Imagem para impressão</span>
          <S3ImageUpload
            className="mb-4"
            url={altImage}
            onUpload={(url) => {
              altImageOnSave(url?.trim() ?? '');
            }}
            onDelete={() => {
              altImageOnSave('');
            }}
          ></S3ImageUpload>
          {altImage ? (
            <img
              alt={'Graphic'}
              src={altImage}
              style={{ width: '100%', ...styles }}
              className="w-full h-auto object-contain object-center mb-4"
            />
          ) : (
            <PhotographIcon
              className={`w-full h-auto rounded-xl bg-white opacity-30 mb-4`}
            ></PhotographIcon>
          )}
        </figure>
      </figure>
    </div>
  );
};

export default SectionImage;
