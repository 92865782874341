import { useHistory } from 'react-router-dom';
import React from 'react';

import Input from '../Input';

import { ReactComponent as Magnifier } from '../../img/magnifier.svg';

import './Search.scss';
import { useTranslation } from 'react-i18next';

const Toolbar = ({ className }) => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className={`search relative flex ${className}`}>
      <div className="absolute left-0 top-0 bg-transparent w-12 h-full pl-2 flex justify-start items-center">
        <Magnifier className="fill-current text-white h-1/2" />
      </div>
      <Input
        placeholder={t('sections.search.placeholder')}
        className="bg-transparent border-transparent w-full h-full pl-12 text-white placeholder-current text-base"
        // eslint-disable-next-line lingui/no-unlocalized-strings
        containerClassName="h-full flex items-center"
        onSave={(value) => {
          // eslint-disable-next-line lingui/no-unlocalized-strings
          console.log('pushing history');
          history.push('/publicacoes', { search: value });
        }}
      ></Input>
    </div>
  );
};

export default Toolbar;
