import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import traverseMenu from '../Menu/traverseMenu';
import { generalEmail } from '../../common/variables';
import fct from '../../img/logos/fct2.png';
import iscte from '../../img/logos/iscte.png';
import dinamia from '../../img/logos/dinamia.png';

import './Footer.scss';

const Footer = ({ routes }) => {
  const { t } = useTranslation();

  const logos = (
    <>
      <a
        className="w-1/2 md:w-1/3 h-auto hover:scale-105 transform transition"
        href="https://dinamiacet.iscte-iul.pt"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="w-full h-full object-contain"
          alt="dinamia'CET"
          src={dinamia}
        ></img>
      </a>
      <a
        className="w-1/2 md:w-1/3 h-auto  hover:scale-105 transform transition"
        href="https://iscte-iul.pt"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="w-full h-full object-contain"
          alt="ISCTE"
          src={iscte}
        ></img>
      </a>
      <a
        className="w-1/2 md:w-1/3 h-auto ml-8 md:ml-0  hover:scale-105 transform transition"
        href="https://fct.pt/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="w-full h-full object-contain" alt="FCT" src={fct}></img>
      </a>
    </>
  );
  return (
    <div
      id="footer"
      className={`relative print:hidden w-full min-h-96 flex justify-center items-stretch z-30 pt-16 pb-12 md:py-20 text-white font-medium`}
    >
      <div className="w-10/12 md:w-8/12 flex flex-col md:flex-row justify-start md:justify-between items-center md:items-start">
        <div className="w-full md:w-8/12 h-full flex flex-col justify-between items-start border-none md:border-r-2 border-white pr-0 md:pr-12">
          <div
            id="contacto"
            className="w-full flex flex-col justify-start items-start pb-8 md:pb-0 border-b-2 md:border-none border-white"
          >
            <span className="text-3xl md:text-2xl uppercase pb-2">
              {t('footer.title')}
            </span>
            <span className="text-xl leading-loose pb-2">
              <Trans i18nKey="footer.about">
                Iscte - Instituto Universitário de Lisboa
                <br />
                Av. das Forças Armadas
                <br />
                Edifício 4 - A004| 1649-026 Lisboa, Portugal
              </Trans>
            </span>
            <span className="flex text-xl leading-loose pb-2">
              <div className="relative top-1 flex justify-center items-center w-8 h-8 rounded-full bg-gray-50 text-gray-600 mr-2">
                {t('footer.telephone')}
              </div>
              <a className="pr-2" href={`tel:+351210464441`}>
                (+351) 210464441
              </a>
            </span>
            <span className="flex text-xl leading-loose pb-2">
              <div className="relative top-1 flex justify-center items-center w-8 h-8 rounded-full bg-gray-50 text-gray-600 mr-2">
                {t('footer.email')}
              </div>
              <a
                href={`mailto:${generalEmail}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {generalEmail}
              </a>
            </span>
          </div>
          <div className="w-full hidden md:flex flex-wrap -ml-6 pt-12 md:pt-0">
            {logos}
          </div>
        </div>
        <div className="footer-menu w-full md:w-4/12 md:-my-4 -ml-8 md:ml-0 mt-8 pl-0 lg:pl-24 flex flex-col md:flex-wrap justify-center items-start">
          {traverseMenu(routes)}
        </div>
        <div className="w-full flex md:hidden flex-wrap justify-center pt-2 mt-8 md:pt-0 border-t-2 md:border-none border-white">
          {logos}
        </div>
      </div>
    </div>
  );
};

export default Footer;
