let backendAddress;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  backendAddress = 'http://localhost:3010/';
} else {
  backendAddress = 'https://api.obsempregojovem.com/';
}
export { backendAddress };

let frontendAddress;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  frontendAddress = 'http://localhost:3001/';
} else {
  frontendAddress = 'https://obsempregojovem.com/';
}
export { frontendAddress };

const generalEmail = 'geral@obsempregojovem.com';

export { generalEmail };

const translatableMetaFields = [
  'image',
  'title',
  'subtitle',
  'metaTitle',
  'publicationDetails',
  'authors',
  'attachments',
  'research'
];

export { translatableMetaFields };
