import { PlusIcon } from '@heroicons/react/outline';

const ArrayAddItem = ({
  onCreate = () => {},
  className = '',
  disabled = false
}) => {
  return (
    <div
      onClick={() => {
        if (!disabled) {
          onCreate();
        }
      }}
      className={`array-add py-4 cursor-pointer transition ${
        disabled ? 'text-gray-400' : 'text-blue-200 hover:text-blue-400'
      }  rounded-md flex justify-center items-center ${className}`}
    >
      <PlusIcon className="h-16 stroke-current"></PlusIcon>
    </div>
  );
};

export default ArrayAddItem;
