import { backendAddress } from '../common/variables';
import { handleResponse, authenticate } from './common';

export async function getSelf() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(
    backendAddress + 'users/' + userInfo.attributes.sub,
    {
      method: 'GET',
      //body: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
      }
    }
  );

  return await handleResponse(response);
}

export async function getUsers() {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'users', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });
  return await handleResponse(response);
}

export async function deleteUser(_id) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'users/' + _id, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });
  return await handleResponse(response);
}

export async function postUser(userData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'users', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    },
    body: JSON.stringify(userData)
  });
  return await handleResponse(response);
}

export async function putUser(userData) {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'users/' + userData.id, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    },
    body: JSON.stringify(userData)
  });
  return await handleResponse(response);
}
