/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { createOnSave, generateSlug } from '../../../common/util';

import Input from '../../Input';
import InputNumberButton from '../../InputNumberButton';
import InputCheckbox from '../../InputCheckbox';

const SectionMenuManager = ({
  toc,
  parentToc,
  index,
  onSave,
  titleLabel = 'Título da Secção',
  menuLabel = 'Criar Menu',
  indentationLabel = 'Indentação',
  idLabel = 'ID'
}) => {
  const maybeParentToc = parentToc?.title ? parentToc?.title + '-' : '';

  const [id, setId] = React.useState(
    toc?.href
      ? toc.href
      : toc?.title
      ? generateSlug(maybeParentToc + toc.title)
      : ''
  );

  const [hasMenu, setHasMenu] = React.useState(id);

  React.useEffect(() => {
    let id;
    if (toc?.title) {
      id = generateSlug(maybeParentToc + toc.title);
    } else {
      id = '';
    }
    setId(id);
  }, [toc?.title]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSaveMenuId = createOnSave(onSave, `sections.${index}.toc.href`);
  const onSaveTitle = createOnSave(onSave, `sections.${index}.toc.title`);
  const onSaveLevel = createOnSave(onSave, `sections.${index}.toc.level`);

  return (
    <div className="w-full h-auto flex flex-col">
      <div className="w-full flex flex-col items-start h-auto py-4 mb-4 rounded-md">
        <Input
          className="w-full text-3xl font-regular bg-transparent"
          label={titleLabel}
          value={toc?.title ?? ''}
          type="text"
          onChange={onSaveTitle}
        ></Input>
        <div className="menu-selector w-full flex justify-between h-auto pt-4">
          <InputCheckbox
            checked={hasMenu}
            onChange={(event) => {
              const checked = event.target.checked;
              const newId = checked
                ? generateSlug(toc?.title ? maybeParentToc + toc.title : '')
                : '';
              if (checked) {
                onSaveMenuId(id);
              }
              setId(newId);
              setHasMenu(checked);
              onSaveMenuId(newId);
            }}
            label={menuLabel}
          ></InputCheckbox>
          {!!toc?.href && (
            <div className="flex items-center justify-end">
              <span className="mr-2 text-sm text-current">
                {indentationLabel}
              </span>
              <InputNumberButton
                onChange={onSaveLevel}
                initialValue={toc?.level}
                value={toc?.level}
                className="w-28 h-full"
              ></InputNumberButton>
              <span className="ml-4 mr-2 text-sm text-current">{idLabel}</span>
              <input
                disabled
                className="w-1/2 h-full text-xl font-normal text-current py-1 px-2 rounded-md focus:outline-none border border-gray-300 bg-transparent"
                value={id ?? ''}
                readOnly={true}
              ></input>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionMenuManager;
