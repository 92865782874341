import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({
  authenticated,
  component: Component,
  render,
  authenticationPage = '/login',
  path,
  ...rest
}) => {
  // console.log('Protected Route: authenticated, path= ', path);
  return (
    <Route
      {...rest}
      path={path}
      render={(props) => {
        if (authenticated) {
          if (Component) {
            return <Component {...props} />;
          } else {
            return render(props);
          }
        } else {
          // console.log('Protected Route: REDIRECTING');
          return (
            <Redirect
              to={{
                pathname: authenticationPage,
                state: { from: props.location }
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRoute;
