// eslint-disable-next-line no-unused-vars
import { backendAddress } from '../common/variables';
// eslint-disable-next-line no-unused-vars
import { handleResponse, authenticate, errors } from './common';

export const getTags = async () => {
  const userInfo = await authenticate();

  const response = await fetch(backendAddress + 'tags', {
    method: 'GET',
    //body: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};

export const getTag = async (_id) => {
  const userInfo = await authenticate();

  const response = await fetch(backendAddress + 'tags/' + _id, {
    method: 'GET',
    //body: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};

export const putTag = async (tag) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'tags/' + tag._id, {
    method: 'PUT',
    body: JSON.stringify(tag),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};

export const postTag = async (tag) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'tags', {
    method: 'POST',
    body: JSON.stringify(tag),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};

export const deleteTag = async (_id) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'tags/' + _id, {
    method: 'DELETE',
    //body: data,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};
