/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';

import MainMenu from '../../components/Menu';
import Footer from '../../components/Footer';
import routes from '../../routes';
import footerRoutes from '../../routes/footer';

import graph from '../../img/marcus_winkler_graph.jpg';

import './PageAbout.scss';
import { useTranslation } from 'react-i18next';

const PageAbout = () => {
  const { t } = useTranslation();
  const imageBackground = (
    <>
      <div className="absolute z-0 top-0 left-0 w-full h-full">
        <img
          className="w-full h-full object-cover object-center"
          alt={'featured image'}
          src={graph}
        ></img>
      </div>
      <div className="overlay absolute z-10 top-0 left-0 w-full h-full"></div>
    </>
  );

  return (
    <div id="about" className="w-full h-full flex flex-col">
      <MainMenu routes={routes}></MainMenu>
      <div className="relative w-full flex flex-col justify-start items-center ">
        <div className="header relative w-full flex justify-center items-center whitespace-pre-line">
          {imageBackground}
          <div className="w-8/12 z-20 text-gray-700 pt-32 pb-32">
            <h1 className="w-1/2 text-4xl uppercase font-bold mb-8">
              {t('pages.about.title')}
            </h1>
            <div className="w-1/4 pt-0 pb-8">
              <div className="w-full h-0 border-b-2 border-blue-300"></div>
            </div>
            <div className="w-full h-auto mb-32">
              <p className="w-full font-normal text-2xl md:text-3xl leading-snug md:leading-normal mb-8">
                {t('pages.about.paragraph1')}
              </p>
              <p className="w-full font-normal text-2xl md:text-3xl leading-snug md:leading-normal mb-8">
                {t('pages.about.paragraph2')}
              </p>
              <p className="w-full font-normal text-2xl md:text-3xl leading-snug md:leading-normal">
                {t('pages.about.paragraph3')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer routes={footerRoutes}></Footer>
    </div>
  );
};

export default PageAbout;
