const awsmobile = {
  aws_app_analytics: 'disabled',
  aws_cognito_identity_pool_id:
    'us-east-1:aa36fc1a-85d1-4aec-966a-e7480335e517',
  aws_cognito_region: 'us-east-1',
  aws_project_region: 'us-east-1',
  aws_resource_name_prefix: '',
  aws_sign_in_enabled: 'enable',
  aws_user_files: 'enable',
  aws_user_files_s3_bucket: 'dinamia',
  aws_user_files_s3_bucket_region: 'us-east-1',
  aws_user_pools: 'enable',
  aws_user_pools_id: 'us-east-1_y4MjqXYZM',
  aws_user_pools_web_client_id: '5m9llo773b8j4orb44n525m4rj'
};

export default awsmobile;
