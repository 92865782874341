import autobind from 'auto-bind/react';
import React from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';

import AppContext from './AppContextBase';
import awsconfig from './aws-exports';
import { getSelf } from '../network/users';

Amplify.configure(awsconfig);
// Auth.configure(awsconfig);
// Storage.configure(awsconfig);

// console.log('Initializing AWS config');
// Amplify.configure({
//   Auth: {
//     identityPoolId: awsConfig.aws_cognito_identity_pool_id,
//     region: awsConfig.aws_cognito_region,
//     userPoolId: awsConfig.aws_user_pools_id,
//     userPoolWebClientId: awsConfig.aws_user_pools_web_client_id,
//     mandatorySignIn: true,
//   },
//   Storage: {
//     AWSS3: {
//       bucket: awsConfig.aws_user_files_s3_bucket,
//       region: awsConfig.aws_user_files_s3_bucket_region,
//       identityPoolId: awsConfig.aws_cognito_identity_pool_id
//     }
//   }
// });

// // console.log(Amplify.configure(awsConfig));

const defaultValues = { authenticated: false, userData: null, awsData: null };

class AppContextProvider extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);

    this.reset({ inConstructor: true });
    Hub.listen('auth', this.handleHubAuthCapsule, 'signOut');

    this.state = { initLoaded: false };
  }

  async componentDidMount() {
    await this.currentUser();
  }

  async currentUser() {
    try {
      const awsData = await Auth.currentAuthenticatedUser({
        bypassCache: true
      });

      if (this.state.userData) {
        return this.state.userData;
      }

      if (awsData) {
        return await this.loginBackend(awsData);
      }
    } catch (err) {
      this.setState({
        authenticated: false,
        userData: null,
        awsData: null,
        initLoaded: true
      });
      return null;
    }
  }

  async handleHubAuthCapsule(capsule) {
    const { channel, payload } = capsule;
    if (channel === 'auth') {
      if (payload.event === 'signOut') {
        this.setState({ authenticated: false, userData: null, awsData: null });
      } else if (payload.errors) {
        let msg = '';
        for (let outer in payload.errors) {
          const innerErrors = payload.errors[outer].errors;
          if (innerErrors) {
            for (let inner in innerErrors) {
              msg += innerErrors[inner];
            }
            msg += '\n';
          }
        }
        console.warn('Authenticaton Error:', msg);
      }
    } else {
      // console.log('hub: ', payload);
    }
  }

  reset(options = {}) {
    if (options?.inConstructor) {
      //eslint-disable-next-line react/no-direct-mutation-state
      this.state = this.getDefaultValues();
    } else {
      this.setState(this.getDefaultValues());
    }
  }

  isLoggedIn() {
    return !!this.state.userData;
  }

  async loginBackend(awsData) {
    let userData;
    try {
      const userRequest = await getSelf();
      if (!userRequest?.ok) {
        throw new Error('Erro ao requisitar informação de utilizador.');
      }

      userData = userRequest.data;
    } catch (err) {
      this.setState({
        userData: null,
        initLoaded: true
      });
      return null;
    }

    this.setState({
      authenticated: true,
      userData: userData,
      awsData: awsData,
      initLoaded: true
    });

    return userData;
  }
  async logInAws(username, password) {
    try {
      const awsUser = await Auth.signIn(username, password);
      if (awsUser) {
        this.setState({
          authenticated: true,
          userData: null,
          awsData: awsUser
        });
        return awsUser;
      } else {
        throw new Error('Error logging in');
      }
    } catch (err) {
      this.setState({
        authenticated: false,
        userData: null,
        awsData: null
      });
      throw err;
    }
  }

  async logOut() {
    const result = await Auth.signOut();
    this.setState({ authenticated: false, userData: null, awsData: null });
    return result;
  }

  async forgotPassword(username) {
    return await Auth.forgotPassword(username);
  }

  async forgotPasswordSubmit(username, securityCode, password) {
    return await Auth.forgotPasswordSubmit(username, securityCode, password);
  }

  async completeNewPassword(password) {
    const currentUser = this.state.awsData;
    //console.log({ currentUser });
    return await Auth.completeNewPassword(currentUser, password);
  }

  getDefaultValues() {
    return defaultValues;
  }

  helpers() {
    return {
      getUserAttribute: this.getUserAttribute.bind(this),
      logOut: this.logOut.bind(this),
      loginBackend: this.loginBackend.bind(this)
    };
  }

  getUserAttribute(attributeName) {
    switch (attributeName) {
      case 'role':
        return this.state.userData.role;
      case 'scope':
        return this.state.userData.auth.scope;
      case 'department':
        return this.state.userData.auth.department;
      default:
        return null;
    }
  }

  render() {
    // console.log('this.state.userData', this.state.userData);
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          loginBackend: this.loginBackend,
          logInAws: this.logInAws,
          logOut: this.logOut,
          currentUser: this.currentUser,
          forgotPassword: this.forgotPassword,
          forgotPasswordSubmit: this.forgotPasswordSubmit,
          completeNewPassword: this.completeNewPassword,
          isLoggedIn: this.isLoggedIn,
          getUserAttribute: this.getUserAttribute,
          helpers: this.helpers,
          initLoaded: this.state.initLoaded
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

const AppContextConsumer = AppContext.Consumer;

export { AppContextProvider, AppContextConsumer };
