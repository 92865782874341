import React from 'react';
import { Link } from 'react-router-dom';

import PostThumbnail from '../PostThumbnail';
import { getPathPrefix } from '../../common/postTypes';

import './PostGrid.scss';

const PostGrid = ({ postMetas = [], thumbnailClassName = '' }) => {
  const postThumbnails = postMetas.map((meta, index) => {
    const pathPrefix = getPathPrefix(meta);
    return (
      <Link
        className="w-full h-auto block"
        key={'' + meta.title + index}
        to={{ pathname: `${pathPrefix}/${meta?.postId}`, state: meta }}
      >
        <PostThumbnail
          className={thumbnailClassName}
          postMeta={meta}
        ></PostThumbnail>
      </Link>
    );
  });

  return (
    <div className="post-grid relative w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-center">
      {postThumbnails}
    </div>
  );
};

export default PostGrid;
