import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({
  component: Component,
  render,
  path,
  exact,
  ...rest
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        if (Component) {
          return <Component {...props} />;
        } else {
          return render(props);
        }
      }}
      {...rest}
    ></Route>
  );
};

export default PublicRoute;
