/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import {
  UploadIcon,
  TrashIcon,
  DocumentTextIcon,
  RefreshIcon
} from '@heroicons/react/outline';
import { toast } from 'react-toastify';

import { uploadFile, removeFile } from '../../common/s3';
import Input from '../Input';

const s3AttachmentFolderName = 'attachments/';

const S3PdfUpload = ({
  attachments,
  onUpload = (attachments) => {},
  onDelete = (deletedAttachment) => {},
  className = '',
  iconSize = 16
}) => {
  const [isLoading, setIsloading] = React.useState('');
  const [forceInputRender, setForceInputRender] = React.useState(false);

  const inputRef = React.createRef();

  const clearFileInput = () => {
    setForceInputRender(!forceInputRender);
  };

  return (
    <div
      className={`flex items-center ${
        attachments?.length ? 'w-full' : 'w-auto'
      } border border-gray-300 rounded-md px-2 ${className}`}
    >
      <div
        className={`flex flex-col w-full justify-start items-start text-current py-2 `}
      >
        <div className="flex w-full items-start pr-4 border rounded-lg mb-2">
          <label className="w-auto h-full">
            {isLoading ? (
              <RefreshIcon
                className={`w-12 h-12 p-2 mx-2 animate-spin rounded-md opacity-20`}
              ></RefreshIcon>
            ) : (
              <UploadIcon
                className={`w-12 h-12 p-2 mx-2 rounded-md ${
                  isLoading
                    ? 'bg-gray-100 text-gray-400 opacity-20'
                    : 'hover:bg-white hover:bg-opacity-50 cursor-pointer'
                }`}
              ></UploadIcon>
            )}

            <input
              key={forceInputRender}
              ref={inputRef}
              multiple={false}
              type="file"
              accept="application/pdf"
              className="hidden"
              onChange={async (e) => {
                if (isLoading) {
                  return;
                }
                setIsloading(true);
                const files = e.target.files;

                if (!files?.[0]) {
                  return;
                }

                const fileName = files[0]?.name ?? '';

                const response = await uploadFile(
                  files[0],
                  s3AttachmentFolderName
                );

                if (response?.ok) {
                  setIsloading(false);

                  clearFileInput();

                  return onUpload(
                    attachments?.concat([
                      { url: response.data?.publicUrl, name: fileName }
                    ])
                  );
                } else {
                  toast.error('Erro a fazer upload de ficheiro');
                }
                setIsloading(false);
              }}
            />
          </label>
        </div>

        {attachments?.map((attachment, index) => {
          return (
            <Attachment
              key={attachment.url + '' + index}
              attachment={attachment}
              isLoading={isLoading}
              setIsloading={setIsloading}
              onSaveAttachment={(attachment) => {
                const targetAttachment = attachments[index];
                for (const key in attachment) {
                  targetAttachment[key] = attachment[key];
                }
                onUpload(attachments);
              }}
              onDelete={onDelete}
              clearFileInput={clearFileInput}
            ></Attachment>
          );
        })}
      </div>
    </div>
  );
};

const Attachment = ({
  attachment,
  isLoading,
  setIsloading,
  onSaveAttachment = () => {},
  onDelete,
  clearFileInput
}) => {
  const [fileName, setFileName] = React.useState(attachment?.name);
  return (
    <div className="flex w-full justify-start items-start bg-gray-200 mb-2 p-4 rounded-lg">
      <DocumentTextIcon
        className={`w-10 h-10 flex-shrink-0 rounded-md stroke-current  text-gray-500 `}
      ></DocumentTextIcon>

      <div className="flex flex-col justify-start items-start px-2 w-full mr-4">
        <Input
          value={fileName}
          className={`font-sans bg-white w-full h-10 font-mono mb-2 font-light text-base break-all text-current leading-none bg-trasparent`}
          type="text"
          onChange={(val) => {
            setFileName(val);
          }}
          onBlur={async () => {
            setIsloading(true);
            await onSaveAttachment({ ...attachment, name: fileName });
            setIsloading(false);
          }}
          // onSave={() => {}}
          disableAffix={true}
        ></Input>
        <pre className="text-xs break-all whitespace-normal font-sans text-gray-500">{attachment.url}</pre>
      </div>

      <TrashIcon
        className={`flex-shrink-0 w-10 h-10 p-2 rounded-md ${
          isLoading
            ? 'bg-gray-100 text-gray-400 opacity-20'
            : 'bg-white hover:bg-red-700 text-red-700 hover:text-white transition cursor-pointer'
        }`}
        onClick={async () => {
          if (isLoading) {
            return;
          }
          if (attachment?.url?.includes?.('s3')) {
            setIsloading(true);
            const response = await removeFile(
              attachment.url,
              s3AttachmentFolderName
            );

            clearFileInput();

            if (response.ok) {
              setIsloading(false);
              return onDelete(attachment);
            } else {
              toast.error('Erro a remover ficheiro');
            }
          } else {
            clearFileInput();
            setIsloading(false);
            return onDelete(attachment);
          }
          setIsloading(false);
        }}
      ></TrashIcon>
    </div>
  );
};

export default S3PdfUpload;
