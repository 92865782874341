import React from 'react';

import AdminPostGrid from '../../components/AdminPostGrid';
import AdminCategoryList from '../../components/AdminCategoryList';

import './Dashboard.scss';

const AdminDashboard = ({
  postMetas = [],
  categories = [],
  newsMetas = []
}) => {
  return (
    <div
      id="admin-dashboard"
      className="w-full h-full bg-gray-100 flex flex-col p-8"
    >
      <div className="w-1/2 h-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mr-8 flex flex-col items-start mb-8">
        <h1 className="text-4xl font-bold pb-8">Área de Administração</h1>
      </div>
      <div className="dashboard-content w-full flex flex-wrap overflow-y-scroll pb-8">
        <div className="w-3/5 min-h-1/3 bg-white shadow-md rounded px-8 pt-6 pb-8 mr-8 flex flex-col items-start">
          <div className="pb-2 text-3xl border-b border-bluegray-500 mb-8">
            {postMetas.length} publicações
          </div>
          <div className="w-full">
            <AdminPostGrid
              postMetas={postMetas?.slice(0, 3) ?? []}
              pathPrefix={'/admin'}
            ></AdminPostGrid>
          </div>
        </div>
        <div className="w-1/3 min-h-1/3 bg-white shadow-md rounded px-8 pt-6 pb-8 flex flex-col items-start">
          <div className="pb-2 text-3xl border-b border-bluegray-500 mb-8">
            {categories.length} categorias
          </div>
          <div className="w-full overflow-y-scroll">
            <AdminCategoryList categories={categories}></AdminCategoryList>
          </div>
        </div>
        <div className="w-3/5 min-h-1/3 bg-white shadow-md rounded px-8 pt-6 pb-8 mt-8 mr-16 flex flex-col items-start">
          <div className="pb-2 text-3xl border-b border-bluegray-500 mb-8">
            {newsMetas.length} Notícias
          </div>
          <div className="w-full">
            <AdminPostGrid
              postMetas={newsMetas?.slice(0, 3) ?? []}
              pathPrefix={'/admin'}
            ></AdminPostGrid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
