/* eslint-disable lingui/no-unlocalized-strings */
import { isNullish } from '../../common/util';
import React from 'react';
import { withRouter, useLocation } from 'react-router';
import { XIcon } from '@heroicons/react/outline';

import MainMenu from '../../components/Menu';
import Footer from '../../components/Footer';
import routes from '../../routes';
import footerRoutes from '../../routes/footer';
import PostGrid from '../../components/PostGrid';
import Input from '../../components/Input';
import Error from '../../components/Error';
import Ring from '../../components/Ring';

import CodePreview from '../../components/CodePreview';
import { getPostMetas } from '../../network/postMetas';
import { getCategories } from '../../network/categories';

import './NewsList.scss';
import { matchSearch } from '../../common/search';
import { useTranslation, withTranslation } from 'react-i18next';
import postTypes from '../../common/postTypes';
import {
  DEFAULT_LANGUAGE,
  getLocalizedMetaCache,
  getPublicLocalizedContent
} from '../../common/translationUtils';

const NewsList = () => {
  const [postMetas, setPostMetas] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [categories, setCategories] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [tags, setTags] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [postMetaCache, setPostMetaCache] = React.useState([]);

  const { t, i18n } = useTranslation();
  const location = useLocation();

  const isLoadingPosts = isNullish(postMetas);
  const isLocalized = i18n.language !== DEFAULT_LANGUAGE;
  const noPosts = postMetas?.length < 1;

  const init = async () => {
    await loadPosts();
    await loadCategories();

    const search = location?.state?.search;
    if (search) {
      setSearch(search);
    }
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    doSearchAndFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedTags, search, postMetaCache]);

  const localize = (postMetas = postMetaCache) => {
    console.log(
      'Language changed, updating post metas to lang ',
      i18n.language
    );
    const localized = getLocalizedMetaCache(i18n.language, postMetas);
    return localized;
  };

  React.useEffect(() => {
    const localized = localize();
    setPostMetas(localized);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const doSearchAndFilters = () => {
    let newPostMetas = postMetaCache;

    if (!selectedCategory && selectedTags.length < 1 && !search) {
      const localized = localize();
      setPostMetas(localized);
      return;
    }

    if (selectedCategory) {
      newPostMetas = filterByCategory(newPostMetas);
    }
    if (selectedTags.length > 0) {
      console.log('searching tags: ', search);
      newPostMetas = filterByTag(newPostMetas);
    }

    if (search) {
      newPostMetas = newPostMetas.filter((x) => matchSearch(search, x));
    }
    const localized = localize(newPostMetas);
    setPostMetas(localized);
  };

  const loadPosts = async () => {
    const metaRequest = await getPostMetas('news');
    if (metaRequest?.ok) {
      const metas = metaRequest.data ?? [];
      // console.log(metaRequest.data);

      const localized = getLocalizedMetaCache(i18n.language, metas);

      setPostMetas(localized);
      setPostMetaCache(metas);
      loadTagsFromPostMetas(localized);
    } else {
      setPostMetas([]);
      setPostMetaCache([]);
    }
  };

  const loadTagsFromPostMetas = (postMetas) => {
    const newTags = new Set();
    postMetas.forEach((meta) => {
      const metaTags = meta.tags ?? [];
      metaTags.forEach((tag) => newTags.add(tag));
    });
    if (newTags.size < 1) {
      setTags([]);
    } else {
      setTags(Array.from(newTags));
    }
  };

  const loadCategories = async () => {
    const categoryRequest = await getCategories();
    if (categoryRequest?.ok) {
      // console.log(categoryRequest.data);
      let cats = categoryRequest.data ?? [];
      cats = cats.map((cat) => {
        return {
          ...cat,
          ...getPublicLocalizedContent(cat, i18n.language)
        };
      });
      console.log(cats);
      setCategories(
        cats.filter((x) => postTypes.post.categories.includes(x.slug))
      );
    } else {
      setCategories(null);
    }
  };

  const filterByCategory = (postMetas) => {
    const newPostMetas = postMetas.filter(
      (meta) => meta.category?.slug === selectedCategory?.slug
    );
    if (newPostMetas.length > 0) {
      return newPostMetas;
    } else {
      return [];
    }
  };

  const filterByTag = (postMetas) => {
    const newPostMetas = postMetas.filter((meta) =>
      meta.tags.some((item) => selectedTags.includes(item))
    );
    if (newPostMetas.length > 0) {
      return newPostMetas;
    } else {
      return [];
    }
  };

  const searchSection = (
    <>
      <Input
        placeholder=""
        label={
          <div className="text-2xl font-bold uppercase pb-4">
            {t('sections.search.placeholder')}
          </div>
        }
        className="bg-white border mb-4 border-gray-300 w-full h-full placeholder-current text-base uppercase"
        containerClassName="h-full flex items-center"
        onChange={(value) => {
          setSearch(search);
        }}
        value={search}
      ></Input>
      <div
        className={`flex mb-6 justify-center items-center text-gray-300 cursor-pointer hover:text-gray-700 transition`}
        onClick={() => {
          setSelectedCategory(null);
          setSelectedTags([]);
          setSearch('');
        }}
      >
        <XIcon className={'w-8 h-8 mr-2 stroke-current'}></XIcon>

        <span className={'text-current leading-none text-base'}>
          {t('sections.search.clear')}
        </span>
      </div>
    </>
  );

  return (
    <>
      <MainMenu routes={routes}></MainMenu>
      <div
        id="news-list"
        className="relative px-8 lg:px-40 pt-14 bg-gray-100 flex flex-col justify-start items-center w-full h-full min-h-screen"
      >
        {process.env.NODE_ENV === 'development' && (
          <CodePreview
            post={postMetas}
            label="Post Metas"
            className="w-full"
          ></CodePreview>
        )}
        <div className="relative w-full h-auto flex flex-col md:flex-row justify-end md:justify-start items-start pb-8">
          <div className="hidden md:flex flex-col items-start h-full w-full md:w-1/2 pr-0 md:pr-12">
            {searchSection}
          </div>

          {/* <div className="relative flex flex-col md:flex-row h-full w-full md:w-3/4 pt-12 pl-0 md:pl-12">
              {categories}
            </div> */}
        </div>
        <div className="w-full h-auto mb-12 flex flex-col md:flex-row">
          {/* <div className="w-full md:w-1/4 h-auto md:min-h-96 flex flex-col items-start pr-12 pb-12 md:pb-0 border-none md:border-r-2 border-blue-400">
              {tags}
            </div> */}
          <div className="flex md:hidden flex-col items-start h-full w-full md:w-1/4 pr-0 md:pr-12">
            {searchSection}
          </div>
          <div className="w-full pb-12 md:pb-0 flex items-center">
            {noPosts ? (
              <Error
                className="w-full justify-center pt-16"
                message={t('errors.noPosts')}
              ></Error>
            ) : isLoadingPosts ? (
              <Ring size={40}></Ring>
            ) : (
              <PostGrid
                postMetas={
                  isLocalized ? postMetas?.filter((pm) => pm.init) : postMetas
                }
                thumbnailClassName="bg-white"
              ></PostGrid>
            )}
          </div>
        </div>
      </div>
      <Footer routes={footerRoutes}></Footer>
    </>
  );
};

export default withRouter(withTranslation()(NewsList));
