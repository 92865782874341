/* eslint-disable lingui/no-unlocalized-strings */
import { Storage } from 'aws-amplify';
import uuidv4 from 'uuid/v4';

import awsconfig from '../context/aws-exports';
import { anchorDownload } from './util';
Storage.configure(awsconfig);

const defaultFolderName = 'builder/';

const getFile = async (s3Key, fileName, folderName = defaultFolderName) => {
  let realKey = s3Key;
  if (s3Key.includes('https://')) {
    realKey = s3Key.replace(new RegExp('.+' + folderName), '');
    console.log('Delete s3 file: transformed public url into ' + realKey);
  }
  const customPrefix = {
    public: folderName
  };
  let s3Result;
  try {
    s3Result = await Storage.get(realKey, {
      customPrefix: customPrefix,
      level: 'public',
      download: true
    });
  } catch (error) {
    console.log(error);
    return { ok: false, data: s3Result, error };
  }

  return await anchorDownload(s3Result.Body, fileName);
};

const uploadFile = async (
  file,
  folderName = defaultFolderName,
  level = 'public'
) => {
  console.log('Uploading file to s3 with folderName ' + folderName, file);
  const bucketObjectUrlPrefix = `https://${awsconfig.aws_user_files_s3_bucket}.s3.${awsconfig.aws_user_files_s3_bucket_region}.amazonaws.com/${folderName}`;

  const random = uuidv4();
  const fileID = random + file.name;

  const customPrefix = {
    public: folderName
  };

  let s3Result;
  try {
    s3Result = await Storage.put(fileID, file, {
      contentType: file.type,
      customPrefix: customPrefix,
      level: level
    });
  } catch (error) {
    return { ok: false, data: s3Result, error };
  }

  if (s3Result.key) {
    s3Result.publicUrl = bucketObjectUrlPrefix + s3Result.key;
  }

  return { ok: true, data: s3Result };
};

const removeFile = async (s3Key, folderName = defaultFolderName) => {
  console.log('Delete s3 file with key ' + s3Key);
  let realKey = s3Key;
  if (s3Key.includes('https://')) {
    realKey = s3Key.replace(new RegExp('.+' + folderName), '');
    console.log('Delete s3 file: transformed public url into ' + realKey);
  }
  const customPrefix = {
    public: folderName
  };

  let s3Result;
  try {
    s3Result = await Storage.remove(realKey, {
      customPrefix: customPrefix
    });
  } catch (error) {
    return { ok: false, data: s3Result, error };
  }

  return { ok: true, data: s3Result };
};

export { uploadFile, removeFile, getFile };
