/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { Link } from 'react-router-dom';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import moment from 'moment';

import 'swiper/components/pagination/pagination.scss';
import './Hero.scss';

SwiperCore.use([Pagination, Autoplay]);

const Hero = ({ slides = [] }) => {
  const [activeSlide, setActiveSlide] = React.useState(0);
  const [swiper, setSwiper] = React.useState(0);

  React.useEffect(() => {
    setActiveSlide(swiper.realIndex);
  }, [swiper]);

  return (
    <div className="relative w-full h-full">
      <Swiper
        className="hero-slider w-full"
        spaceBetween={0}
        slidesPerView={1}
        initialSlide={0}
        loop={true}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          bulletClass:
            'swiper-pagination-bullet rounded-full h-4 w-4 bg-gray-400 opacity-60 mr-1',
          bulletActiveClass:
            'swiper-pagination-bullet-active bg-white opacity-80'
        }}
        autoplay={{
          delay: 4000
        }}
        speed={450}
        onSlideChange={(swiper) => {
          setActiveSlide(swiper.realIndex);
        }}
        onSwiper={setSwiper}
      >
        {slides.map((slide, index) => {
          const innerSlide = (
            <>
              <div className="absolute z-0 top-0 left-0 w-full h-full">
                <img
                  className="w-full h-full object-cover object-center"
                  alt={slide.title}
                  src={slide.image}
                ></img>
              </div>
              <div className="overlay absolute z-10 top-0 left-0 w-full h-full"></div>
              <div className="z-20 w-10/12 h-full flex justify-start items-start md:items-center">
                <h1 className="w-full pb-8 pt-32 md:pt-0 lg:w-2/3 font-medium text-2xl sm:text-4xl text-white uppercase leading-normal">
                  {slide.title}
                </h1>
              </div>
              <div className="z-20 w-10/12 h-full flex justify-start items-start md:items-center">
                <h2 className="font-light text-2xl text-white leading-normal">
                  {slide.subtitle}
                </h2>
              </div>
            </>
          );
          return (
            <SwiperSlide
              key={slide.title + index}
              className="relative w-full h-full flex justify-center items-center lg:pr-96"
            >
              <Link
                key={slide.title + index}
                // onClick={() => {
                //   setActiveSlide(index);
                //   swiper?.slideTo?.(index);

                // }}

                to={`/${slide.postType === 'news' ? 'n' : 'p'}/${slide.postId}`}
                className={`flex flex-col md:hidden self-start justify-center items-center`}
              >
                {innerSlide}
              </Link>
              <div className={`hidden md:flex flex-col w-2/3`}>
                {innerSlide}
              </div>
            </SwiperSlide>
          );
        })}
        <div className="swiper-pagination absolute bottom-8 h-8 w-full"></div>
      </Swiper>
      <div className="z-10 absolute hidden lg:flex justify-end items-center h-full w-2/5 xl:w-1/3 right-0 top-0 ">
        <div className="flex flex-col xl:min-h-2/3 max-h-4/5 overflow-y-scroll w-full rounded-l-3xl bg-dinamia-blue bg-opacity-70 px-12 py-8 ">
          {slides.map((slide, index) => {
            const borderClasses =
              index + 1 !== slides.length ? 'border-b-2 border-blue-400' : '';
            return (
              <Link
                key={slide.title + index}
                // onClick={() => {
                //   setActiveSlide(index);
                //   swiper?.slideTo?.(index);

                // }}

                to={`/${slide.postType === 'news' ? 'n' : 'p'}/${slide.postId}`}
                className={`cursor-pointer py-4 text-white ${
                  activeSlide === index
                    ? 'opacity-100'
                    : 'opacity-50 hover:opacity-100'
                } h-1/3 w-full flex flex-col ${borderClasses} transition`}
              >
                <h3 className=" font-medium text-2xl lg:text-lg text-white uppercase leading-normal pb-4">
                  {slide.title}
                </h3>
                {process.env.NODE_ENV === 'development' && slide?.language}
                <div className=" font-medium text-lg lg:text-base text-gray-300 uppercase leading-normal pb-4">
                  {moment(slide?.createdAt).format('LL')}
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Hero;
