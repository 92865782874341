import React from 'react';
import { Link, useParams, Prompt } from 'react-router-dom';

import op from 'object-path';
import { toast } from 'react-toastify';

import NewsHeader from '../../components/AdminPostEditor/NewsHeader';
import PostOptions from '../../components/AdminPostEditor/PostOptions';
import PostBody from '../../components/AdminPostEditor/PostBody';

import Post from '../../pagesPublic/Post';

import Lost from '../../components/Lost';
import Ellipsis from '../../components/Ellipsis';
import CodePreview from '../../components/CodePreview';
import {
  createTranslation,
  enableLocalization,
  generateSlug
} from '../../common/util';

import { getPost, putPost } from '../../network/posts';

import './NewsEditor.scss';
import {
  DEFAULT_LANGUAGE
} from '../../common/translationUtils';
import { translatableMetaFields } from '../../common/variables';
import TranslationSwitcher from '../../components/AdminPostEditor/TranslationSwitcher';

const NewsEditor = ({
  post: maybePost,
  meta: maybeMeta,
  availableTags,
  onPostUpdate = () => {}
}) => {
  const [post, setPost] = React.useState(maybePost); // maybe comes in prop
  const [meta, setMeta] = React.useState(maybeMeta); // maybe comes in prop
  const [isLoading, setLoading] = React.useState(true);
  const [isPreviewing, setIsPreviewing] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false); // maybe comes in prop

  const [language, setLanguage] = React.useState(DEFAULT_LANGUAGE);
  const isLocalized = language !== DEFAULT_LANGUAGE;
  const currentPost = isLocalized ? post?.translations?.[language] : post;

  const { id } = useParams();

  React.useEffect(() => {
    const fetchPost = async () => {
      const postResponse = await getPost(id);
      if (postResponse.ok) {
        const post = postResponse.data;
        enableLocalization(post);
        enableLocalization(post?.meta);
        setPost(post);
        setMeta(post?.meta);
      }
    };

    if (!post || !meta) {
      if (!maybePost) {
        fetchPost();
      } else {
        enableLocalization(maybePost);
        enableLocalization(maybePost?.meta);
        setPost(maybePost);
        setMeta(maybeMeta);
      }
    }

    setLoading(false);
  }, [maybePost, maybeMeta, post, meta, id]);

  const refreshContent = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 150);
  };

  React.useEffect(refreshContent, [language]);

  const onSaveMeta = async (path, value) => {
    // Save Meta
    //console.log('settingState meta', { path, value });
    const newMeta = { ...meta };
    if (
      isLocalized &&
      translatableMetaFields.some((fieldName) => path.startsWith(fieldName))
    ) {
      createTranslation(newMeta, language);
      console.log('updating localized', path, value);
      op.set(newMeta?.translations?.[language], path, value);
    } else {
      op.set(newMeta, path, value);
    }
    setMeta(newMeta);

    // Deal with tags
    if (path === 'tags') {
      await onPostUpdate({ tags: value });
    }

    // Save Post

    const newPost = { ...post };
    newPost.meta = newMeta;
    setPost(newPost);
    setDirty(true);
  };

  const onSavePost = (path, value) => {
    //console.log('settingState post', { path, value });
    const newPost = { ...post };
    if (isLocalized) {
      createTranslation(newPost, language);
      op.set(newPost?.translations?.[language], path, value);
    } else {
      op.set(newPost, path, value);
    }
    setPost(newPost);
  };

  const savePostToDb = async () => {
    await new Promise((r) => setTimeout(r, 150));
    setLoading(true);

    console.log('onSaveToDb, ', meta);
    const metaWithSlug = { ...meta, slug: generateSlug(meta?.title, 24) };

    const response = await putPost({ ...post, meta: metaWithSlug });
    if (!response?.ok) {
      toast.error('Erro a criar publicação. Por favor tente de novo.');
      setDirty(true);
    } else {
      setPost(response.data);
      setMeta(response.data.meta);
      setDirty(false);
    }

    setLoading(false);
  };

  return (
    <main
      className={`news-editor py-8 relative flex flex-col justify-start items-center w-full h-auto pb-12`}
    >
      <Prompt
        when={isDirty}
        message={(location) =>
          `Navegar para outra página? Quaisquer alterações não gravadas serão perdidas`
        }
      />
      {isLoading ? (
        <Ellipsis></Ellipsis>
      ) : !post ? (
        <div className="w-full h-full flex justify-center items-center">
          <Lost link="/admin"></Lost>
        </div>
      ) : (
        <>
          <div className="w-full z-50 h-auto flex justify-end align-center sticky top-0 pl-28 pb-4">
            <div className="w-auto h-auto bg-bluegray-300 rounded-l-md flex justify-end align-center top-0 p-4">
              <TranslationSwitcher
                language={language}
                localizableObject={post}
                onChangeLanguage={setLanguage}
                onCreateTranslation={(post) => {
                  savePostToDb(post);
                  setPost(post);
                  setMeta(post?.meta);
                }}
              ></TranslationSwitcher>
              <button
                className="bg-white h-12 hover:bg-gray-50 text-blue-500 font-bold py-2 px-4 rounded focus:outline-none ml-4"
                disabled={isLoading}
                onClick={() => {
                  setIsPreviewing(!isPreviewing);
                }}
              >
                {isPreviewing ? 'Editar' : 'Pré-visualizar'}
              </button>
              <button
                className={`${
                  isLoading || isPreviewing
                    ? 'bg-gray-200 cursor-default'
                    : 'bg-light-blue  hover:bg-blue-500 cursor-pointer'
                } h-12 text-white font-bold py-2 px-4 rounded focus:outline-none ml-4`}
                disabled={isLoading || isPreviewing}
                onClick={savePostToDb}
              >
                Gravar
              </button>
              <Link to={`/admin/posts`} className="h-12">
                <button
                  className={`${
                    isLoading || isPreviewing
                      ? 'bg-gray-200 cursor-default'
                      : 'bg-red-400  hover:bg-red-500 cursor-pointer'
                  } h-12 text-white font-bold py-2 px-4 rounded focus:outline-none ml-4`}
                  disabled={isLoading || isPreviewing}
                >
                  Cancelar
                </button>
              </Link>
            </div>
          </div>
          {process.env.NODE_ENV === 'development' && (
            <div className="bg-bluegray-300 p-4 rounded-md h-auto w-full mb-4 flex flex-col">
              <pre className="text-gray-500">dev</pre>
              <CodePreview post={currentPost ?? post}></CodePreview>
              <CodePreview
                post={currentPost?.meta ?? post?.meta}
                label="Post Meta"
                className=""
              ></CodePreview>
            </div>
          )}
          {isPreviewing ? (
            <Post post={post}></Post>
          ) : (
            <div className="w-full flex flex-col items-center">
              <div className="flex justify-center w-full pt-12 bg-gray-50">
                <div className="w-7/12 print:hidden flex flex-col ">
                  <NewsHeader
                    key="header"
                    language={language}
                    availableTags={availableTags}
                    meta={meta}
                    onSave={onSaveMeta}
                  ></NewsHeader>
                </div>

                <div className="w-4/12 print:hidden flex flex-col ">
                  <PostOptions
                    language={language}
                    meta={meta}
                    onSave={onSaveMeta}
                  ></PostOptions>
                </div>
              </div>
              <div className="w-11/12 pt-16 flex justify-center print:justify-start items-start ">
                <PostBody
                  disableSectionTypes={['collapse', 'tableau']}
                  post={currentPost}
                  onSave={onSavePost}
                ></PostBody>
              </div>
            </div>
          )}
        </>
      )}
    </main>
  );
};

export default NewsEditor; //withRouter(PostEditor);
