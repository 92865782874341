import React from 'react';
import { Collapse } from 'react-collapse';

import PostBody from '../PostBody';
import { ReactComponent as ChevronDown } from '../../../img/chevron-down.svg';
import { ReactComponent as ChevronRight } from '../../../img/chevron-right.svg';
import { isNullish } from '../../../common/util';

import './SectionCollapse.scss';

const collapseSectionWrapper = ({ children, htmlId }) => (
  <>
    <div id={htmlId} className="section-wrapper relative w-full h-auto">
      {children}
    </div>
  </>
);

const SectionCollapse = ({
  toc,
  sections,
  classNames,
  styles,
  options,
  onStateChange = () => {}
}) => {
  //const [isCollapseOpen, setIsCollapseOpen] = React.useState(options?.isOpen);
  const [isCollapseOpen, setIsCollapseOpen] = React.useState(false);

  // React.useEffect(() => {
  //   if (!isNullish(options?.isOpen) && options.isOpen !== isCollapseOpen) {
  //     setIsCollapseOpen(options?.isOpen);
  //   }
  // }, [options?.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!isNullish(isCollapseOpen) && options.isOpen !== isCollapseOpen) {
      onStateChange(toc?.href, isCollapseOpen);
    }
  }, [isCollapseOpen]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div
      className={`page-break section px-0 pb-4 section-collapse w-full flex flex-col justify-start items-center ${classNames}`}
      style={styles}
    >
      <div
        onClick={() => {
          setIsCollapseOpen(!isCollapseOpen);
        }}
        className={`cursor-pointer w-full px-8 min-h-14 mt-2 mb-4 flex justify-between items-center`}
      >
        <h2 className="w-full h-full flex items-center text-2xl font-regular">
          {toc?.title}
        </h2>
        <div className="rounded-md h-16 w-16 print:hidden flex justify-center items-center bg-transparent hover:bg-gray-100 trasition">
          {isCollapseOpen ? (
            <ChevronDown></ChevronDown>
          ) : (
            <ChevronRight></ChevronRight>
          )}
        </div>
      </div>
      <Collapse isOpened={isCollapseOpen} className={'w-full'}>
        <PostBody
          sections={sections}
          WrapperComponent={collapseSectionWrapper}
        ></PostBody>
      </Collapse>
    </div>
  );
};

export default SectionCollapse;
