import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import Modal from 'react-modal';
import 'moment/locale/pt';
import ReactGA from 'react-ga4';

import App from './App';
import { AppContextProvider } from './context/AppContext';
import * as serviceWorker from './serviceWorker';

import 'react-toastify/dist/ReactToastify.css';
import './styles/global.scss';
import moment from 'moment';
import { TagContextProvider } from './context/TagContext';

moment.locale('pt');

if (process.env.NODE_ENV === 'production') {
  console.log('GA ID :', process.env.REACT_APP_GA_MEASUREMENT_ID);
  ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID, {
    testMode: false,
    gaOptions: {
      anonymizeIp: true,
      allowAdFeatures: false,
      allowAdPersonalizationSignals: false
    }
  });
} else {
  console.log('Skipping GA setup in development');
}

ReactDOM.render(
  <HelmetProvider>
    <AppContextProvider>
      <TagContextProvider>
        <App />
      </TagContextProvider>
    </AppContextProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

Modal.setAppElement('#root');

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
