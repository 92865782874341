/* eslint-disable lingui/no-unlocalized-strings */
import { getFile } from '../../../common/s3';
import { useTranslation } from 'react-i18next';
import pdf from '../../../img/pdf.png';
import './NewsHeader.scss';
import TagContext from '../../../context/TagContext';
import React from 'react';

const NewsHeader = ({
  category,
  tags,
  title,
  subtitle,
  image,
  date,
  attachments = []
}) => {
  const { t, i18n } = useTranslation();
  const { tags: allTags } = React.useContext(TagContext);

  const categoriesTags = (
    <div className="w-full flex flex-col md:flex-row">
      <div className="flex gap-4 flex-wrap font-light text-lg leading-loose">
        {!!category?.label && (
          <div className="flex justify-center items-center h-auto w-auto rounded-lg px-4 py-0 md:py-2 text-md md:text-xl text-white font-medium uppercase bg-blue-400">
            {category.label}
          </div>
        )}
        {tags
          ? tags.map((tag) => {
              const targetTag = allTags.find((x) => x?.label === tag);
              let translatedTag =
                targetTag?.translations?.[i18n.language]?.label;
              if (!translatedTag) {
                translatedTag = tag;
              }
              return (
                <div
                  key={tag}
                  className=" px-4 py-0 md:py-2 bg-gray-500 bg-opacity-20 rounded-md flex justify-center items-center"
                >
                  {translatedTag}
                </div>
              );
            })
          : null}
      </div>
    </div>
  );

  return (
    <div className="news-header w-full flex flex-col lg:flex-row justify-end print:justify-start items-end">
      <div className="flex flex-col ">
        {date ? (
          <div className="flex justify-start items-center pb-12">
            <span className="w-full font-light italic text-2xl">{date}</span>
          </div>
        ) : null}
        <div className="flex">
          <div className="w-1/2 hidden lg:block mb-4 lg:mb-0 pr-12">
            <img
              className="w-full mb-8 h-full object-cover object-center"
              alt={'featured image'}
              src={image}
            ></img>
          </div>
          <div className="w-full lg:w-1/2 flex flex-col">
            <h1 className="font-bold text-4xl lg:text-5xl pb-4">{title}</h1>
            {!!subtitle && (
              <h2 className=" font-light text-2xl leading-normal pb-4">
                {subtitle}
              </h2>
            )}
            <div className="hidden lg:flex w-full pt-4">{categoriesTags}</div>
            {attachments?.length > 0 && (
              <div className="flex flex-col justify-start items-start w-full pt-8 pb-2">
                <div className="text-lg ">{t('news.header.attachments')}</div>
                <div className="flex flex-wrap gap-4 justify-start items-start content-start w-full">
                  {attachments.map((attachment, index) => {
                    return (
                      <div
                        key={attachment?.url + '' + index}
                        onClick={() => {
                          getFile(
                            attachment?.url,
                            attachment?.name,
                            'attachments/'
                          );
                        }}
                        className=" flex flex-col justify-start items-start max-w-44 font-light p-2 h-full bg-gray-100 rounded-lg hover:bg-gray-200 cursor-pointer break-all max-h-60 overflow-hidden"
                      >
                        <img
                          className="stroke-1 flex-shrink-0 text-gray-800 stroke-current w-12 h-auto mb-1 object-cover object-center"
                          alt={'Download PDF'}
                          src={pdf}
                        ></img>

                        <div className="text-sm pr-2">{attachment?.name}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="w-full block lg:hidden mb-0 mt-4">
              <img
                className="w-full h-full object-cover object-center"
                alt={'featured image'}
                src={image}
              ></img>
            </div>
          </div>
        </div>
        <div className="flex lg:hidden w-full pt-8">{categoriesTags}</div>
      </div>
    </div>
  );
};

export default NewsHeader;
