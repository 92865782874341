/* eslint-disable lingui/no-unlocalized-strings */
const postTypes = {
  post: {
    label: 'Publicação',
    pathPrefix: '/p',
    type: 'post',
    categories: ['relatoriosanuais', 'relatoriostematicos', 'policy-briefs']
  },
  news: {
    label: 'Notícia',
    pathPrefix: '/n',
    type: 'news',
    categories: ['noticias']
  },
  research: {
    label: 'Item de Investigação',
    pathPrefix: '/r',
    type: 'research',
    categories: ['artigos', 'livros-e-capitulos', 'investigacao-outros']
  }
};

const getPathPrefix = (meta) => {
  let type = null;
  if (meta) {
    type = meta?.postType ?? 'post';
  }

  return postTypes[type]?.pathPrefix;
};

export default postTypes;
export { getPathPrefix };
