/* eslint-disable lingui/no-unlocalized-strings */
const SectionError = ({ ...section }) => {
  return (
    <div className="section bg-red-100 rounded-lg my-8 post-error w-full p-5 flex flex-col justify-start items-start">
      <span className="text-red-700 font-semibold pb-4">
        ERROR
        <br />
        SectionType: {section.type}
      </span>
      <pre className="w-full text-gray-500 whitespace-pre-wrap">
        {JSON.stringify(section, null, 2)}
      </pre>
    </div>
  );
};

export default SectionError;
