/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { UserIcon } from '@heroicons/react/outline';

import MainMenu from '../../components/Menu';
import Footer from '../../components/Footer';
import routes from '../../routes';
import footerRoutes from '../../routes/footer';

import graph from '../../img/marcus_winkler_graph.jpg';

import './PageTeam.scss';
import { useTranslation } from 'react-i18next';

const PageTeam = () => {
  const { t } = useTranslation();
  const imageBackground = (
    <>
      <div className="absolute z-0 top-0 left-0 w-full h-full">
        <img
          className="w-full h-full object-cover object-center"
          alt={'featured image'}
          src={graph}
        ></img>
      </div>
      <div className="overlay absolute z-10 top-0 left-0 w-full h-full"></div>
    </>
  );

  return (
    <div id="team" className="w-full h-full flex flex-col">
      <MainMenu routes={routes}></MainMenu>
      <div className="relative w-full flex flex-col justify-start items-center ">
        <div className="header relative w-full flex justify-center items-center mb-16 md:mb-32">
          {imageBackground}
          <div className="w-10/12 z-20 text-white flex flex-col pt-24 pb-32">
            <span className="font-light italic text-2xl leading-snug md:leading-normal">
              {t('pages.team.title')}
            </span>
          </div>
        </div>
        <div className="w-10/12 flex flex-wrap items-start pb-32">
          <Contact
            imageSrc={'team/FatimaSuleman.png'}
            href="https://ciencia.iscte-iul.pt/authors/fatima-suleman/cv"
            name="Fátima Suleman"
            description={<>{t('pages.team.members.0.description')}</>}
            email="fatima.suleman@iscte-iul.pt"
          ></Contact>
          <Contact
            imageSrc={'team/PauloMarques.png'}
            name="Paulo marques"
            href="https://ciencia.iscte-iul.pt/authors/paulo-marques/cv"
            description={<>{t('pages.team.members.1.description')}</>}
            email="paulo_miguel_marques@iscte-iul.pt"
          ></Contact>
          <Contact
            imageSrc={'team/MariaFigueiredo.png'}
            name="Conceição Figueiredo"
            href="https://ciencia.iscte-iul.pt/authors/maria-da-conceicao-torres-figueiredo/cv"
            description={<>{t('pages.team.members.2.description')}</>}
            email="conceicao.figueiredo@iscte-iul.pt"
          ></Contact>
          <Contact
            imageSrc={'team/PedroVideira.png'}
            name="Pedro Videira"
            href="https://ciencia.iscte-iul.pt/authors/pedro-videira/cv"
            description={<>{t('pages.team.members.4.description')}</>}
            email="pedro.videira@iscte-iul.pt"
          ></Contact>
          <Contact
            imageSrc={'team/RitaGuimaraes.png'}
            name="Rita Guimarães"
            href="https://ciencia.iscte-iul.pt/authors/author-public-page-2659/cv"
            description={<>{t('pages.team.members.5.description')}</>}
            email="rita_henriques_guimaraes@iscte-iul.pt"
          ></Contact>
          <Contact
            imageSrc={'team/MariaDoCarmoBotelho.jpg'}
            name="Maria do Carmo Botelho"
            href="https://ciencia.iscte-iul.pt/authors/maria-do-carmo-botelho/cv"
            description={<>{t('pages.team.members.6.description')}</>}
            email="maria.botelho@iscte-iul.pt"
          ></Contact>
          <Contact
            imageSrc={'team/ClaudiaVajao.jpg'}
            name="Cláudia Vajão"
            href="https://ciencia.iscte-iul.pt/authors/claudia-da-cruz-dias/cv"
            description={<>{t('pages.team.members.7.description')}</>}
            email="Claudia_Isabel_Vajao@iscte-iul.pt"
          ></Contact>
        </div>
      </div>
      <Footer routes={footerRoutes}></Footer>
    </div>
  );
};

const Contact = ({ imageSrc, name, description, email, href }) => {
  return (
    <div className="w-full md:w-1/2 min-h-96 flex justify-center mb-16 md:mb-32">
      <div className="w-3/4 flex flex-col items-center hover:scale-105 transform transition">
        <div className="w-60 h-60 bg-gray-100 rounded-full flex justify-center items-center mb-8">
          {imageSrc ? (
            <img
              src={imageSrc}
              alt={name}
              className="w-full h-full rounded-full object-cover"
            ></img>
          ) : (
            <UserIcon className="w-1/2 h-1/2 text-gray-300 "></UserIcon>
          )}
        </div>
        <div className="w-full text-center mb-8 uppercase text-2xl font-bold">
          {name}
        </div>
        <div className="w-full mb-8 uppercase text-center text-lg font-light whitespace-pre-line">
          {description}
        </div>
        <div className="w-full lowercase italic text-center text-lg font-light">
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`mailto:${email}`}
            className="py-2"
          >
            {email}
          </a>
        </div>
        {!!href && (
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            className="w-full flex justify-center"
          >
            <div className="w-full py-2 lowercase text-center text-lg font-light">
              {href}
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

export default PageTeam;
