/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { Collapse } from 'react-collapse';
import { CogIcon } from '@heroicons/react/outline';

import { createOnSave, isNullish } from '../../../common/util';
import { effects } from '../../effects/effects';
import EffectPreview from '../../EffectPreview';

const SectionOptions = ({ effects: currentEffects, onSave, index }) => {
  const onSaveEffects = createOnSave(onSave, `sections.${index}.effects`);

  const [isCollapseOpen, setIsCollapseOpen] = React.useState(false);

  if (isNullish(currentEffects)) {
    return null;
  }

  return (
    <div
      className={`section-options w-full border border-gray-300 p-4 rounded-md flex flex-col`}
    >
      <div
        onClick={() => {
          setIsCollapseOpen(!isCollapseOpen);
        }}
        className={`cursor-pointer w-full flex justify-between items-center`}
      >
        <span className="text-xl">Opções</span>
        <CogIcon className="h-4 w-4"></CogIcon>
      </div>
      <Collapse isOpened={isCollapseOpen} className={'w-full h-full'}>
        <div className="w-full pt-2 border-t border-current mt-4 flex">
          <h3 className="w-1/5 text-lg text-current">Background</h3>
          <div className="effects-bg w-4/5 flex justify-end flex-wrap">
            {Object.keys(effects.background).map((key, index) => {
              const effect = effects.background[key];
              return (
                <div
                  key={effect?.key + index}
                  className="w-1/4 flex justify-end items-center"
                >
                  <EffectPreview
                    effect={effect}
                    currentEffects={currentEffects}
                    onCheck={(isChecked, effect) => {
                      let newEffects = [...currentEffects];
                      if (isChecked) {
                        newEffects = newEffects.filter(
                          (x) => !x.includes('background.')
                        );
                        newEffects.push(effect.key);
                      } else {
                        newEffects = newEffects.filter(
                          (x) => !x.includes(effect.key)
                        );
                      }
                      onSaveEffects(newEffects);
                    }}
                  ></EffectPreview>
                </div>
              );
            })}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default SectionOptions;
