/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useReactToPrint } from 'react-to-print';

import Ring from '../../Ring';
import pdf from '../../../img/pdf.png';

import './PostDownloads.scss';
import '../../../pagesPublic/Post/Post.scss';
import { MobileUp, Mobile } from '../../../responsive';
import { getFile } from '../../../common/s3';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line lingui/no-unlocalized-strings
const pageStyle = `
  @page { 
    size: A4 portrait !important;
    margin: 10mm 12mm !important;
    text-align: center !important;
  } 
  @page :first { 
    margin: 0mm 12mm 10mm 12mm !important;
  } 
  @media print { 
    html {
      font-size: 12px !important;
      width: auto !important;
      text-align: center !important;
    }
    body { 
      margin: 2em !important;
      text-align: center !important;
      width: auto !important;
      -webkit-print-color-adjust: exact !important; 
      color-adjust: exact !important;
    } 
  }
`;

const PostDownloads = ({ printRef, attachments }) => {
  const [isPrintLoading, setPrintLoading] = React.useState(false);

  const { t } = useTranslation();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle,
    bodyClass: 'w-screen block text-center',
    onBeforePrint: () => setPrintLoading(false)
  });

  return (
    <div className="downloads w-full print:hidden h-full flex flex-col justify-start items-start">
      <div
        className="cursor-pointer flex flex-col w-full pb-4"
        onClick={() => {
          setPrintLoading(true);
          handlePrint();
        }}
      >
        <h3 className="toc-title meta-title w-full pb-4 font-medium text-3xl leading-normal">
          {t('sections.download')}
        </h3>
        {isPrintLoading ? (
          <Ring></Ring>
        ) : (
          <img
            className="w-16 h-auto object-cover object-center"
            alt={'Download PDF'}
            src={pdf}
          ></img>
        )}
      </div>
      <div className="w-full py-4">
        <div className="w-full h-0 border-b-2 border-blue-300"></div>
      </div>
      {attachments?.length > 0 && (
        <div className="flex flex-col w-full pb-4">
          <h3 className="toc-title meta-title w-full pb-4 font-medium text-3xl leading-normal">
            {t`sections.attachments`}
          </h3>
          <div className="flex flex-wrap gap-4 justify-start items-start content-start w-full">
            {attachments.map((attachment, index) => {
              return (
                <div
                  onClick={() => {
                    getFile(attachment?.url, attachment?.name, 'attachments/');
                  }}
                  className=" flex flex-col justify-start items-start max-w-32 font-light p-2 h-full bg-gray-200 rounded-lg hover:bg-gray-300 cursor-pointer break-all max-h-60 overflow-hidden"
                >
                  <img
                    className="stroke-1 flex-shrink-0 text-gray-800 stroke-current w-12 h-auto mb-1 object-cover object-center"
                    alt={'Download PDF ' + attachment?.name}
                    src={pdf}
                  ></img>

                  <div className="text-sm pr-2">{attachment?.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="w-full py-4">
        <div className="w-full h-0 border-b-2 border-blue-300"></div>
      </div>
      <div className="flex flex-col w-full">
        <h3 className="toc-title meta-title w-full pb-4 font-medium text-3xl leading-normal">
          {t`sections.translate`}
        </h3>
        <MobileUp>
          <div id="google_translate_element" style={{ display: 'flex' }}></div>
        </MobileUp>
        <Mobile>
          <div
            id="google_translate_element_mobile"
            style={{ display: 'flex' }}
          ></div>
        </Mobile>
      </div>

      <Mobile>
        <Helmet>
          <script type="text/javascript">
            {`function googleTranslateMobile() {
            try {
            const translateResult = new google.translate.TranslateElement(
              { pageLanguage: 'pt_PT' },
              'google_translate_element_mobile'
            );
            } catch (error) {
              console.error(error);
            }
            
          }`}
          </script>
          <script
            type="text/javascript"
            src="https://translate.google.com/translate_a/element.js?cb=googleTranslateMobile"
          ></script>
        </Helmet>
      </Mobile>
      <MobileUp>
        <Helmet>
          <script type="text/javascript">
            {`function googleTranslate() {
            try {
            const translateResult = new google.translate.TranslateElement(
              { pageLanguage: 'pt_PT' },
              'google_translate_element'
            );
            } catch (error) {
              console.error(error);
            }
            
          }`}
          </script>
          <script
            type="text/javascript"
            src="https://translate.google.com/translate_a/element.js?cb=googleTranslate"
          ></script>
        </Helmet>
      </MobileUp>
    </div>
  );
};

export default PostDownloads;
