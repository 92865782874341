import { getEffects } from '../../effects/effects';

import SectionError from '../SectionError';
import SectionRichText from '../SectionRichText';
import SectionCollapse from '../SectionCollapse';
import SectionImage from '../SectionImage';
import SectionEmbed from '../SectionEmbed';
import SectionTableau from '../SectionTableau';

import './PostBody.scss';

const sectionTypes = {
  richtext: SectionRichText,
  collapse: SectionCollapse,
  image: SectionImage,
  embed: SectionEmbed,
  tableau: SectionTableau
};

const defaultSectionWrapper = ({ children, htmlId }) => (
  <>
    <div id={htmlId} className="section-wrapper relative w-full h-auto">
      {children}
    </div>
  </>
);

const PostBody = ({
  sections = [],
  WrapperComponent = defaultSectionWrapper,
  onCollapseStateChange
}) => {
  return sections.map((section, index) => {
    const sectionType = section.type;
    const SectionComponent = sectionTypes[sectionType] ?? SectionError;

    const { styles, classNames } = getEffects(section.effects);

    const options = section.options ?? {};

    return (
      <WrapperComponent
        key={section.toc?.title + index}
        htmlId={section.toc?.href}
      >
        <SectionComponent
          id={section.toc?.href}
          {...section}
          onStateChange={onCollapseStateChange}
          options={options ?? {}}
          key={'' + section.meta?.title + index}
          classNames={classNames}
          styles={styles}
        ></SectionComponent>
      </WrapperComponent>
    );
  });
};

export default PostBody;
