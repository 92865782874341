import React from 'react';

import Search from '../Search';
import facebook from '../../img/facebook.png';
import twitter from '../../img/twitter.png';

import './Toolbar.scss';

const Toolbar = () => {
  return (
    <div className="w-full h-full flex items-center">
      <Search className="relative hidden md:flex rounded-md w-72 h-12 md:h-full justify-start items-center mr-6"></Search>
      <div className="h-12 flex justify-center items-center">
        <a
          className="h-full w-auto px-2 mr-4"
          href="https://www.facebook.com/OBSEmpregojovem"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="w-auto h-full object-contain"
            alt="FB"
            src={facebook}
          ></img>
        </a>
        <a
          className="h-full w-auto px-2 mr-4"
          href="https://twitter.com/emprego_jovem"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="w-auto h-full object-contain"
            alt="TWT"
            src={twitter}
          ></img>
        </a>
      </div>
    </div>
  );
};

export default Toolbar;
