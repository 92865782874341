/* eslint-disable lingui/no-unlocalized-strings */
import React, { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import AppContext from '../../context/AppContextBase';

import logo from '../../img/logo2@2x.png';

import './Renew.scss';

const PasswordRenew = () => {
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const onSubmit = async (values, { setSubmitting }) => {
    const errorOut = async (e) => {
      await appContext.logOut();

      if (e.message.includes('User is not confirmed.')) {
        toast.error(
          'Por favor, confirme o seu email utilizando o e-mail que lhe foi enviado.'
        );
      } else if (e.message.includes(' Incorrect username or password.')) {
        toast.error('Email ou password incorrectos.');
      } else if (e.message.includes('User does not exist.')) {
        toast.error('Email ou password incorrectos.');
      } else {
        toast.error(
          <div>
            <div>Erro inesperado. Tente de novo dentro de momentos</div>
            {e.message ? <pre>{e.message}</pre> : null}
          </div>
        );
      }
      console.error(e);
      return;
    };

    setSubmitting(true);
    try {
      const awsUser = await appContext.completeNewPassword(values.password);
      console.log('completed new password ');
      const isAuth = await appContext.loginBackend(awsUser);
      console.log('logged in backend');
      if (isAuth) {
        console.log('isAuth');
        setLoading(false);
        history.push('/admin');
        return;
      } else {
        console.log('is NOT Auth');
      }
    } catch (e) {
      errorOut(e);
      setSubmitting(false);
      return;
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.password) {
      errors.password = 'Obrigatório';
    }

    if (!values.passwordConfirm) {
      errors.passwordConfirm = 'Obrigatório';
    }

    if (!values.passwordConfirm === values.password) {
      errors.passwordConfirm = 'Passwords não coincidem';
    }

    return errors;
  };

  return (
    <div
      id="renew"
      className="w-full h-full min-h-screen flex justify-center items-center flex-grow bg-gray-100"
    >
      <Formik
        initialValues={{ password: '', passwordConfirm: '' }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex items-center">
            <Link
              to={'/'}
              className="max-w-72 mr-12 w-auto flex justify-center items-end"
            >
              <img className="max-h-full" src={logo} alt="Dinamia"></img>
            </Link>
            <div className="flex flex-col">
              <h1 className="block text-gray-500 text-2xl font-bold mb-8">
                Alterar Password
              </h1>
              <div className="mb-4 flex flex-col justify-start items-start">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Nova Password
                </label>
                <Field
                  type="password"
                  name="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
                <ErrorMessage
                  className="text-red-700 self-end"
                  name="password"
                  component="div"
                />
              </div>

              <div className="mb-6 flex flex-col justify-start items-start">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="passwordConfirm"
                >
                  Confirmação da Password
                </label>
                <Field
                  type="password"
                  name="passwordConfirm"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
                <ErrorMessage
                  className="text-red-700 self-end"
                  name="passwordConfirm"
                  component="div"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="inline-block align-baseline p-4 rounded-md bg-gray-100 hover:bg-gray-200 font-light text-sm text-gray-800 hover:text-gray-600 mr-12"
                  disabled={isSubmitting || loading}
                  onClick={() => {
                    history.push('/login');
                  }}
                >
                  Voltar ao ecrã de Login
                </button>
                <button
                  type="submit"
                  className="inline-block align-baseline p-4 rounded-md font-bold text-sm bg-blue-400 hover:bg-blue-500 text-white"
                  disabled={isSubmitting || loading}
                >
                  Renovar Password
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PasswordRenew;
