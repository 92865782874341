/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';

import MainMenu from '../../components/Menu';
import Footer from '../../components/Footer';
import routes from '../../routes';
import footerRoutes from '../../routes/footer';

import graph from '../../img/marcus_winkler_graph.jpg';

import './PagePartners.scss';
import { useTranslation } from 'react-i18next';

const PageAbout = () => {
  const { t } = useTranslation();
  const imageBackground = (
    <>
      <div className="absolute z-0 top-0 left-0 w-full h-full">
        <img
          className="w-full h-full object-cover object-center"
          alt={'featured image'}
          src={graph}
        ></img>
      </div>
      <div className="overlay absolute z-10 top-0 left-0 w-full h-full"></div>
    </>
  );

  return (
    <div id="about" className="w-full h-full flex flex-col">
      <MainMenu routes={routes}></MainMenu>
      <div className="relative w-full flex flex-col justify-start items-center ">
        <div className="header relative w-full flex justify-center items-center">
          {imageBackground}
          <div className="w-8/12 z-20 text-gray-700 pt-32 pb-32">
            <h1 className="w-full text-4xl uppercase font-bold mb-8">
              {t('pages.partners.title')}
            </h1>
            <div className="w-1/4 pt-0 pb-8">
              <div className="w-full h-0 border-b-2 border-blue-300"></div>
            </div>
            <div className="w-full h-auto">
              <div className="w-full flex flex-col font-bold text-2xl md:text-3xl leading-snug md:leading-normal mb-16">
                <div className="flex flex-col md:flex-row items-start md:items-start">
                  <span className="uppercase p-2 mr-4 mb-4 min-w-60 text-center md:mb-0 bg-gray-100 rounded-md font-bold text-2xl md:text-3xl leading-snug md:leading-normal">
                    {t('pages.partners.partners.0.title')}
                  </span>
                  <span className="md:pt-2 font-medium">
                    {t('pages.partners.partners.0.body')}
                  </span>
                </div>

                <a
                  className="pl-0 md:pl-4 pt-2"
                  href={t('pages.partners.partners.0.url')}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('sections.partners.moreInfo')}
                </a>
              </div>

              <div className="w-full flex flex-col font-bold text-2xl md:text-3xl leading-snug md:leading-normal mb-16">
                <div className="flex flex-col md:flex-row items-start md:items-start">
                  <span className="uppercase p-2 mr-4 min-w-60 text-center mb-4 md:mb-0 bg-gray-100 rounded-md font-bold text-2xl md:text-3xl leading-snug md:leading-normal">
                    {t('pages.partners.partners.1.title')}
                  </span>
                  <span className="md:pt-2 font-medium">
                    {t('pages.partners.partners.1.body')}
                  </span>
                </div>

                <a
                  className="pl-0 md:pl-4 pt-2"
                  href={t('pages.partners.partners.1.url')}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('sections.partners.moreInfo')}
                </a>
              </div>
              <div className="w-full flex flex-col font-bold text-2xl md:text-3xl leading-snug md:leading-normal mb-16">
                <div className="flex flex-col md:flex-row items-start md:items-start">
                  <span className="uppercase p-2 mr-4 min-w-60 text-center mb-4 md:mb-0 bg-gray-100 rounded-md font-bold text-2xl md:text-3xl leading-snug md:leading-normal">
                    {t('pages.partners.partners.2.title')}
                  </span>
                  <span className="md:pt-2 font-medium">
                    {t('pages.partners.partners.2.body')}
                  </span>
                </div>

                <a
                  className="pl-0 md:pl-4 pt-2"
                  href={t('pages.partners.partners.2.url')}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('sections.partners.moreInfo')}
                </a>
              </div>
              <div className="w-full flex flex-col font-bold text-2xl md:text-3xl leading-snug md:leading-normal mb-16">
                <div className="flex flex-col md:flex-row items-start md:items-start">
                  <span className="uppercase p-2 mr-4 min-w-60 text-center mb-4 md:mb-0 bg-gray-100 rounded-md font-bold text-2xl md:text-3xl leading-snug md:leading-normal">
                    {t('pages.partners.partners.3.title')}
                  </span>
                  <span className="md:pt-2 font-medium">
                    {t('pages.partners.partners.3.body')}
                  </span>
                </div>

                <a
                  className="pl-0 md:pl-4 pt-2"
                  href={t('pages.partners.partners.3.url')}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('sections.partners.moreInfo')}
                </a>
              </div>

              <div className="w-full flex flex-col font-bold text-2xl md:text-3xl leading-snug md:leading-normal mb-16">
                <div className="flex flex-col md:flex-row items-start md:items-start">
                  <span className="uppercase p-2 mr-4 min-w-60 text-center mb-4 md:mb-0 bg-gray-100 rounded-md font-bold text-2xl md:text-3xl leading-snug md:leading-normal">
                    {t('pages.partners.partners.4.title')}
                  </span>
                </div>

                <a
                  className="pl-0 md:pl-4 pt-2"
                  href={t('pages.partners.partners.4.url')}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('sections.partners.moreInfo')}
                </a>
              </div>

              <div className="w-full flex flex-col font-bold text-2xl md:text-3xl leading-snug md:leading-normal mb-16">
                <div className="flex flex-col md:flex-row items-start md:items-start">
                  <span className="uppercase p-2 mr-4 min-w-60 text-center mb-4 md:mb-0 bg-gray-100 rounded-md font-bold text-2xl md:text-3xl leading-snug md:leading-normal">
                    {t('pages.partners.partners.5.title')}
                  </span>
                  <span className="md:pt-2 font-medium"></span>
                </div>

                <a
                  className="pl-0 md:pl-4 pt-2"
                  href={t('pages.partners.partners.5.url')}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('sections.partners.moreInfo')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer routes={footerRoutes}></Footer>
    </div>
  );
};

export default PageAbout;
