/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { ExclamationIcon } from '@heroicons/react/outline';
import {
  TrashIcon,
  ArrowCircleDownIcon,
  ArrowCircleUpIcon
} from '@heroicons/react/outline';

import Popover from '../Popover';
import Ellipsis from '../Ellipsis';

const SectionItem = ({
  children,
  totalItems = Infinity,
  index,
  onMoveUp,
  onMoveDown,
  onDelete,
  width = 16,
  className = '',
  confirmLabel = 'Confirmar remoção',
  cancelLabel = 'Cancelar'
}) => {
  const [isOpen, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const confirmContent = (
    <div className="w-full bg-white rounded-md shadow-md p-8">
      <div className="w-full flex flex-col items-center text-white">
        {isLoading ? (
          <Ellipsis scale={0.3} backgroundColor={'rgb(239, 68, 68)'}></Ellipsis>
        ) : (
          <ExclamationIcon className="text-red-500 w-12 h-12 mr-4 mb-4 stroke-current"></ExclamationIcon>
        )}
        <button
          disabled={isLoading}
          onClick={async () => {
            setLoading(true);
            await onDelete(index);
            setOpen(false);
            setLoading(false);
          }}
          className="h-full w-full flex justify-center items-center mb-4 bg-red-400 hover:bg-red-600 hover:shadow-md py-2 px-4 rounded focus:outline-none"
        >
          <span className="pl-4 whitespace-nowrap">{confirmLabel}</span>
        </button>
        {/* <button
          disabled={isLoading}
          onClick={() => {
            console.log('cancel');
            setOpen(false);
          }}
          className="h-full w-full flex justify-center items-center bg-gray-300 hover:bg-gray-400 hover:shadow-md py-2 px-4 rounded focus:outline-none"
        >
          <span className="pl-4 whitespace-nowrap">{cancelLabel}</span>
        </button> */}
      </div>
    </div>
  );

  return (
    <div
      className={`section-control w-full section-item rounded-md flex pr-0 ${className}`}
    >
      {children}
      <div className="h-full py-8 pl-2">
        <div className=" border-l border-gray-400 hover:border-gray-800 transition pl-2 h-full flex flex-col justify-start items-center">
          {index !== 0 && (
            <div
              onClick={() => onMoveUp(index)}
              className={`w-${width} h-16 cursor-pointer transition bg-white bg-opacity-0 hover:bg-opacity-50 rounded-md border border-transparent hover:border-blue-200 text-gray-400 hover:text-blue-500 px-2 py-4 flex flex-col justify-center items-center`}
            >
              <ArrowCircleUpIcon className="stroke-current"></ArrowCircleUpIcon>
            </div>
          )}

          {index < totalItems - 1 && (
            <div
              onClick={() => onMoveDown(index)}
              className={`w-${width} h-16 cursor-pointer transition bg-white bg-opacity-0 hover:bg-opacity-50 rounded-md border border-transparent hover:border-blue-200 text-gray-400 hover:text-blue-500 px-2 py-4 flex flex-col justify-center items-center`}
            >
              <ArrowCircleDownIcon className="stroke-current"></ArrowCircleDownIcon>
            </div>
          )}

          <Popover
            isOpen={isOpen}
            onSetOpen={setOpen}
            content={confirmContent}
            containerWidth="20rem"
            className=" flex justify-center items-center"
          >
            <div
              onClick={() => {
                setOpen(true);
              }}
              className={`flex w-${width} h-16 mt-16 cursor-pointer transition bg-white bg-opacity-0 hover:bg-opacity-50 rounded-md border border-transparent hover:border-red-200 text-gray-400 hover:text-red-500 px-2 py-4`}
            >
              <TrashIcon className="stroke-current"></TrashIcon>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default SectionItem;
