import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { CheckIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';

import Popover from '../../components/Popover';
import SelectField from '../../components/Formik/FormikSelectField';

const roleOptions = [
  { value: 'admin', label: 'Administrador' },
  { value: 'user', label: 'Editor' }
];

const userTemplate = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  role: ''
};

const FormCreateUser = ({ validate, onSubmit }) => {
  return (
    <Formik
      initialValues={userTemplate}
      validate={validate}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="w-full h-auto pt-8 flex flex-col items-start">
          <div className="w-full flex flex-wrap items-start">
            <div className="w-1/3 mb-4 pr-8 flex flex-col justify-start items-start">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="firstName"
              >
                Primeiro Nome
              </label>
              <Field
                name="firstName"
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
              />
              <ErrorMessage
                className="text-red-700"
                name="firstName"
                component="div"
              />
            </div>

            <div className="w-1/3 mb-4 pr-8 flex flex-col justify-start items-start">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="lastName"
              >
                Último Nome
              </label>
              <Field
                name="lastName"
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
              />
              <ErrorMessage
                className="text-red-700"
                name="lastName"
                component="div"
              />
            </div>
          </div>
          <div className="w-full flex flex-wrap items-start">
            <div className="w-1/3 mb-4 pr-8 flex flex-col justify-start items-start">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                E-mail
              </label>
              <Field
                type="email"
                name="email"
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
              />
              <ErrorMessage
                className="text-red-700"
                name="email"
                component="div"
              />
            </div>

            <div className="w-1/3 mb-4 pr-8 flex flex-col justify-start items-start">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <Field
                type="password"
                name="password"
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
              />
              <ErrorMessage
                className="text-red-700"
                name="password"
                component="div"
              />
            </div>
          </div>

          <div className="w-full flex flex-wrap items-start">
            <div className="w-1/3 mb-4 pr-8 flex flex-col justify-start items-start">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Nível
              </label>
              <div className="w-full flex-col justify-start items-start">
                <div className="flex items-center w-full">
                  <Field
                    name={'role'}
                    component={SelectField}
                    options={roleOptions}
                    className="w-full pr-4"
                    placeholder="Selecionar"
                  />
                  <Popover arrowClassName="bg-gray-50" content={roleExplainer}>
                    <QuestionMarkCircleIcon
                      className={`h-6 w-6 cursor-pointer`}
                    ></QuestionMarkCircleIcon>
                  </Popover>
                </div>
                <ErrorMessage
                  className="text-red-700"
                  name="role"
                  component="div"
                />
              </div>
            </div>
          </div>

          <div className="h-12 mt-4 w-auto">
            <button
              className="h-full flex items-center bg-blue-400 hover:bg-blue-500 text-white py-2 px-4 rounded focus:outline-none"
              type="submit"
              disabled={isSubmitting}
            >
              <CheckIcon className="w-6 h-6"></CheckIcon>
              <span className="pl-4 whitespace-nowrap">Criar Utilizador</span>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormCreateUser;

const roleExplainer = (
  <div className="container w-full flex items-center justify-center">
    <ul className="flex flex-col rounded-md bg-gray-50  p-4">
      <li className=" flex flex-row mb-2">
        <div className="select-none cursor-pointer bg-white rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
          <div className="flex-1 pl-1 mr-16 min-w-1/4">
            <div className="font-medium">Editor</div>
          </div>
          <div className="text-gray-600 text-base">
            Acesso ao backoffice, criação e edição de publicações e opções de
            páginas (tableau e outros). Proibida a remoção de qualquer tipo de
            conteúdo, bem como a criação ou vizualização dos utilizadores do
            backoffice.
          </div>
        </div>
      </li>
      <li className=" flex flex-row mb-2">
        <div className="select-none cursor-pointer bg-white rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
          <div className="flex-1 pl-1 mr-16 min-w-1/4">
            <div className="font-medium">Administrador</div>
          </div>
          <div className="text-gray-600 text-base">
            Todas as permissões de editor, com a possibilidade de criar, editar
            e apagar utilizadores do backoffice. Possibilidade de eliminar
            conteúdo (publicações) e utilizadores.
          </div>
        </div>
      </li>
    </ul>
  </div>
);
