import React from 'react';
import { Link, useParams, Prompt } from 'react-router-dom';

import op from 'object-path';
import { toast } from 'react-toastify';

import PostHeader from '../../components/AdminPostEditor/PostHeader';
import PostMeta from '../../components/AdminPostEditor/PostMeta';
import PostOptions from '../../components/AdminPostEditor/PostOptions';
import PostTOC from '../../components/PostRender/PostTOC';
import PostBody from '../../components/AdminPostEditor/PostBody';
import TranslationSwitcher from '../../components/AdminPostEditor/TranslationSwitcher';

import Post from '../../pagesPublic/Post';

import Lost from '../../components/Lost';
import Ellipsis from '../../components/Ellipsis';
import CodePreview from '../../components/CodePreview';
import {
  createTranslation,
  enableLocalization,
  generateSlug
} from './../../common/util';
import { frontendAddress } from '../../common/variables';

import { getPost, putPost } from '../../network/posts';

import './PostEditor.scss';
import MetaTags from '../../components/MetaTags';
import { DEFAULT_LANGUAGE } from '../../common/translationUtils';

import { translatableMetaFields } from '../../common/variables';

const PostEditor = ({
  post: maybePost,
  meta: maybeMeta,
  availableTags,
  onPostUpdate = () => {}
}) => {
  const [post, setPost] = React.useState(maybePost); // maybe comes in prop
  const [meta, setMeta] = React.useState(maybeMeta); // maybe comes in prop
  const [isLoading, setLoading] = React.useState(true);
  const [isPreviewing, setIsPreviewing] = React.useState(false);
  const [isDirty, setDirty] = React.useState(false); // maybe comes in prop

  const [language, setLanguage] = React.useState(DEFAULT_LANGUAGE);

  const isLocalized = language !== DEFAULT_LANGUAGE;
  const localizedMeta = isLocalized ? meta?.translations?.[language] : null;
  const currentPost = isLocalized ? post?.translations?.[language] : post;

  const { id } = useParams();

  React.useEffect(() => {
    const fetchPost = async () => {
      const postResponse = await getPost(id);
      if (postResponse.ok) {
        const post = postResponse.data;

        enableLocalization(post);
        enableLocalization(post?.meta);

        setPost(post);
        setMeta(post?.meta);
      }
    };

    if (!post || !meta) {
      if (!maybePost) {
        fetchPost();
      } else {
        enableLocalization(maybePost);
        enableLocalization(maybePost?.meta);
        setPost(maybePost);
        setMeta(maybeMeta);
      }
    }

    setLoading(false);
  }, [maybePost, maybeMeta, post, meta, id]);

  const refreshContent = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 150);
  };

  React.useEffect(refreshContent, [language]);

  const onSaveMeta = async (path, value) => {
    const newMeta = { ...meta };

    if (
      isLocalized &&
      translatableMetaFields.some((fieldName) => path.startsWith(fieldName))
    ) {
      createTranslation(newMeta, language);
      console.log('updating localized', path, value);
      op.set(newMeta?.translations?.[language], path, value);
    } else {
      op.set(newMeta, path, value);
    }

    setMeta(newMeta);

    // Deal with tags
    if (path === 'tags') {
      await onPostUpdate({ tags: value });
    }

    // Save Post

    const newPost = { ...post };
    newPost.meta = newMeta;
    setPost(newPost);
    setDirty(true);
  };

  const onSavePost = (path, value) => {
    const newPost = { ...post };
    if (isLocalized) {
      createTranslation(newPost, language);
      op.set(newPost?.translations?.[language], path, value);
    } else {
      op.set(newPost, path, value);
    }

    setPost(newPost);
  };

  const savePostToDb = async () => {
    await new Promise((r) => setTimeout(r, 150));
    setLoading(true);

    console.log('onSaveToDb, ', meta);
    const metaWithSlug = { ...meta, slug: generateSlug(meta?.title, 24) };

    const response = await putPost({ ...post, meta: metaWithSlug });
    if (!response?.ok) {
      toast.error('Erro a criar publicação. Por favor tente de novo.');
      setDirty(true);
    } else {
      setPost(response.data);
      setMeta(response.data.meta);
      setDirty(false);
    }

    setLoading(false);
  };

  return (
    <main
      className={`post-editor py-8 relative flex flex-col justify-start items-center w-full h-auto pb-12`}
    >
      <MetaTags
        url={`${frontendAddress}p/${post?._id}`}
        title={(localizedMeta ?? meta)?.title}
        description={(localizedMeta ?? meta)?.subtitle}
        imageSrc={(localizedMeta ?? meta)?.image}
      ></MetaTags>
      <Prompt
        when={isDirty}
        message={(location) =>
          `Navegar para outra página? Quaisquer alterações não gravadas serão perdidas`
        }
      />
      {isLoading ? (
        <Ellipsis></Ellipsis>
      ) : !post ? (
        <div className="w-full h-full flex justify-center items-center">
          <Lost link="/admin"></Lost>
        </div>
      ) : (
        <>
          <div className="w-full z-50 h-auto flex justify-end align-center sticky top-0 pl-28 pb-4">
            <div className="w-auto h-auto bg-bluegray-300 rounded-l-md flex justify-end align-center top-0 p-4">
              <TranslationSwitcher
                language={language}
                localizableObject={post}
                onChangeLanguage={setLanguage}
                onCreateTranslation={(post) => {
                  savePostToDb(post);
                  setPost(post);
                  setMeta(post?.meta);
                }}
              ></TranslationSwitcher>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${frontendAddress}p/${post?._id}`}
                className="bg-white h-12 hover:bg-gray-50 text-blue-500 flex items-center font-bold py-2 px-4 rounded focus:outline-none ml-4"
              >
                Ver artigo
              </a>
              <button
                className="bg-white h-12 hover:bg-gray-50 text-blue-500 font-bold py-2 px-4 rounded focus:outline-none ml-4"
                disabled={isLoading}
                onClick={() => {
                  setIsPreviewing(!isPreviewing);
                }}
              >
                {isPreviewing ? 'Editar' : 'Pré-visualizar'}
              </button>

              <button
                className={`${
                  isLoading || isPreviewing
                    ? 'bg-gray-200 cursor-default'
                    : 'bg-light-blue  hover:bg-blue-500 cursor-pointer'
                } h-12 text-white font-bold py-2 px-4 rounded focus:outline-none ml-4`}
                disabled={isLoading || isPreviewing}
                onClick={savePostToDb}
              >
                Gravar
              </button>
              <Link to={`/admin/posts`} className="h-12">
                <button
                  className={`${
                    isLoading || isPreviewing
                      ? 'bg-gray-200 cursor-default'
                      : 'bg-red-400  hover:bg-red-500 cursor-pointer'
                  } h-12 text-white font-bold py-2 px-4 rounded focus:outline-none ml-4`}
                  disabled={isLoading || isPreviewing}
                >
                  Cancelar
                </button>
              </Link>
            </div>
          </div>
          {process.env.NODE_ENV === 'development' && (
            <div className="bg-bluegray-300 p-4 rounded-md h-auto w-full mb-4 flex flex-col">
              <pre className="text-gray-500">dev</pre>
              <CodePreview post={currentPost}></CodePreview>
              <CodePreview
                post={currentPost?.meta}
                label="Post Meta"
                className=""
              ></CodePreview>
            </div>
          )}
          {isPreviewing ? (
            <Post post={currentPost}></Post>
          ) : (
            <>
              <PostHeader
                key="header"
                language={language}
                availableTags={availableTags}
                meta={meta}
                onSave={onSaveMeta}
              ></PostHeader>
              <div className="w-full pt-16 flex justify-end print:justify-start items-start ">
                <PostBody post={currentPost} onSave={onSavePost}></PostBody>
                <div className=" w-4/12 print:hidden flex flex-col h-auto pl-12">
                  <div className="print:hidden w-full">
                    <PostOptions
                      meta={meta}
                      language={language}
                      onSave={onSaveMeta}
                    ></PostOptions>
                  </div>
                  <div className="print:hidden w-full">
                    <PostMeta
                      meta={meta}
                      language={language}
                      onSave={onSaveMeta}
                    ></PostMeta>
                  </div>
                  <PostTOC
                    meta={localizedMeta ?? meta}
                    sections={currentPost?.sections}
                    className="top-8"
                  ></PostTOC>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </main>
  );
};

export default PostEditor; //withRouter(PostEditor);
