/* eslint-disable lingui/no-unlocalized-strings */
import { isNullish } from './../../common/util';
import React from 'react';
import { withRouter, useLocation } from 'react-router';
import { XIcon } from '@heroicons/react/outline';

import MainMenu from '../../components/Menu';
import Footer from '../../components/Footer';
import routes from '../../routes';
import footerRoutes from '../../routes/footer';
import PostGrid from '../../components/PostGrid';
import Input from '../../components/Input';
import Error from '../../components/Error';
import Ring from '../../components/Ring';

import { getPostMetas } from '../../network/postMetas';
import { getCategories } from '../../network/categories';

import './PostList.scss';
import { matchSearch } from '../../common/search';
import postTypes from '../../common/postTypes';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  DEFAULT_LANGUAGE,
  getLocalizedMetaCache,
  getPublicLocalizedContent
} from '../../common/translationUtils';
import TagContext from '../../context/TagContext';

const PostList = () => {
  const [postMetas, setPostMetas] = React.useState(null);
  const [categories, setCategories] = React.useState(null);
  const [tags, setTags] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [postMetaCache, setPostMetaCache] = React.useState([]);

  const { tags: allTags } = React.useContext(TagContext);

  const { t, i18n } = useTranslation();
  const isLocalized = i18n.language !== DEFAULT_LANGUAGE;
  const location = useLocation();

  const isLoadingPosts = isNullish(postMetas);
  const isLoadingCategories = isNullish(categories);
  const isLoadingTags = isNullish(tags);
  const noPosts = postMetas?.length < 1;
  const noCategories = categories?.length < 1;
  const noTags = tags?.length < 1;

  const init = async () => {
    await loadPosts();
    await loadCategories();

    const search = location?.state?.search;
    if (search) {
      setSearch(search);
    }
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    doSearchAndFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedTags, search, postMetaCache]);

  const localize = (postMetas = postMetaCache) => {
    if (!postMetaCache) {
      return;
    }
    const localized = getLocalizedMetaCache(i18n.language, postMetas);

    console.log(
      'Language changed, updating post metas to lang ' + i18n.language,
      localized
    );
    return localized;
  };

  React.useEffect(() => {
    const localized = localize();
    setPostMetas(localized);
    loadCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const doSearchAndFilters = () => {
    let newPostMetas = postMetaCache;

    if (!selectedCategory && selectedTags.length < 1 && !search) {
      const localized = localize();
      setPostMetas(localized);
      loadCategories();

      return;
    }

    if (selectedCategory) {
      newPostMetas = filterByCategory(newPostMetas);
    }
    if (selectedTags.length > 0) {
      console.log('searching tags: ', search);
      newPostMetas = filterByTag(newPostMetas);
    }

    if (search) {
      newPostMetas = newPostMetas.filter((x) => matchSearch(search, x));
    }
    const localized = localize(newPostMetas);
    setPostMetas(localized);
  };

  const loadPosts = async () => {
    const metaRequest = await getPostMetas('post');
    if (metaRequest?.ok) {
      const metas = metaRequest.data ?? [];
      // console.log(metaRequest.data);
      const published = metas.filter(
        (x) => !!x?.publishedDate && x?.postType !== 'news'
      );

      setPostMetaCache(published);

      const localized = getLocalizedMetaCache(i18n.language, published);
      setPostMetas(localized);
      loadTagsFromPostMetas(localized);
    } else {
      setPostMetas([]);
      setPostMetaCache([]);
    }
  };

  const loadTagsFromPostMetas = (postMetas) => {
    const newTags = new Set();
    postMetas.forEach((meta) => {
      const metaTags = meta.tags ?? [];
      metaTags.forEach((tag) => newTags.add(tag));
    });
    if (newTags.size < 1) {
      setTags([]);
    } else {
      setTags(Array.from(newTags));
    }
  };

  const loadCategories = async () => {
    const categoryRequest = await getCategories();
    if (categoryRequest?.ok) {
      // console.log(categoryRequest.data);
      let cats = categoryRequest.data ?? [];
      cats = cats.map((cat) => {
        return {
          ...cat,
          ...getPublicLocalizedContent(cat, i18n.language)
        };
      });
      setCategories(
        cats.filter((x) => postTypes.post.categories.includes(x.slug))
      );
    } else {
      setCategories(null);
    }
  };

  const filterByCategory = (postMetas) => {
    const newPostMetas = postMetas.filter(
      (meta) => meta.category?.slug === selectedCategory?.slug
    );
    if (newPostMetas.length > 0) {
      return newPostMetas;
    } else {
      return [];
    }
  };

  const filterByTag = (postMetas) => {
    const newPostMetas = postMetas.filter((meta) =>
      meta.tags.some((item) => selectedTags.includes(item))
    );
    if (newPostMetas.length > 0) {
      return newPostMetas;
    } else {
      return [];
    }
  };

  const searchSection = (
    <>
      <Input
        placeholder=""
        label={
          <div className="text-2xl font-bold uppercase pb-4">
            {t('sections.search.placeholder')}
          </div>
        }
        className="bg-white border mb-4 border-gray-300 w-full h-full placeholder-current text-base uppercase"
        containerClassName="h-full flex items-center"
        onChange={(value) => {
          setSearch(value);
        }}
        value={search}
      ></Input>
      <div
        className={`flex mb-6 justify-center items-center text-gray-300 cursor-pointer hover:text-gray-700 transition`}
        onClick={() => {
          setSelectedCategory(null);
          setSelectedTags([]);
          setSearch('');
        }}
      >
        <XIcon className={'w-8 h-8 mr-2 stroke-current'}></XIcon>

        <span className={'text-current leading-none text-base'}>
          {t('sections.search.clear')}
        </span>
      </div>
    </>
  );

  const categoriesSection = noCategories ? (
    <Error message={t('errors.noCategories')}></Error>
  ) : isLoadingCategories ? (
    <Ring size={40} scale={0.5}></Ring>
  ) : (
    <>
      <div className="flex md:hidden text-2xl font-bold uppercase pb-4">
        {t('sections.filter.filterCategory')}
      </div>
      <CategorySelector
        categories={categories}
        selectedCategory={selectedCategory}
        onSelect={(selectedCategory) => setSelectedCategory(selectedCategory)}
      ></CategorySelector>
    </>
  );

  const tagsSection = (
    <>
      <div className="text-2xl font-bold uppercase pb-4">
        {t('sections.filter.filterTag')}
      </div>
      {noTags ? (
        <Error className="pt-4" message={t('errors.noTags')}></Error>
      ) : isLoadingTags ? (
        <Ring size={40}></Ring>
      ) : (
        <TagSelector
          tagTranslations={allTags}
          language={i18n.language}
          tags={tags}
          selectedTags={selectedTags}
          onSelect={(selectedTag, isSelect) => {
            let newTags = [...selectedTags];
            if (isSelect) {
              // console.log('select tag: ', selectedTag);
              newTags.push(selectedTag);
            } else {
              // console.log('de-select tag: ', selectedTag);
              newTags = newTags.filter((x) => x !== selectedTag);
            }

            setSelectedTags(newTags);
          }}
        ></TagSelector>
      )}
    </>
  );

  return (
    <>
      <MainMenu routes={routes}></MainMenu>
      <div
        id="post-list"
        className="relative px-8 lg:px-40 pt-14 bg-gray-100 flex flex-col justify-start items-center w-full h-full min-h-screen"
      >
        <div className="relative w-full h-auto flex flex-col md:flex-row justify-end items-start pb-8">
          <div className="hidden md:flex flex-col items-start h-full w-full md:w-1/4 pr-0 md:pr-12">
            {searchSection}
          </div>

          <div className="relative flex flex-col md:flex-row h-full w-full md:w-3/4 pt-12 pl-0 md:pl-12">
            {categoriesSection}
          </div>
        </div>
        <div className="w-full h-auto mb-12 flex flex-col md:flex-row">
          <div className="w-full md:w-1/4 h-auto md:min-h-96 flex flex-col items-start pr-12 pb-12 md:pb-0 border-r-0 md:border-r-2 border-blue-400">
            {tagsSection}
          </div>
          <div className="flex md:hidden flex-col items-start h-full w-full md:w-1/4 pr-0 md:pr-12">
            {searchSection}
          </div>
          <div className="w-full md:w-3/4 pl-0 md:pl-12 pb-12 md:pb-0 flex items-center">
            {noPosts ? (
              <Error
                className="w-full justify-center pt-16"
                message={t('errors.noPosts')}
              ></Error>
            ) : isLoadingPosts ? (
              <Ring size={40}></Ring>
            ) : (
              <PostGrid
                postMetas={
                  isLocalized ? postMetas?.filter((pm) => pm.init) : postMetas
                }
                thumbnailClassName="bg-white"
              ></PostGrid>
            )}
          </div>
        </div>
      </div>
      <Footer routes={footerRoutes}></Footer>
    </>
  );
};

export default withRouter(withTranslation()(PostList));

const CategorySelector = ({ selectedCategory, categories, onSelect }) => {
  const cats = categories ?? [];

  const selectedClasses = 'pill-gradient transform transition shadow-md';
  const normalClasses =
    'bg-blue-400 hover:bg-blue-500 hover:shadow-md hover:scale-110 transform transition';

  const isAllSelected = selectedCategory === null;

  const { t } = useTranslation();

  return (
    <div className="flex flex-wrap justify-start items-center w-full h-full">
      <div
        key={'all'}
        className={`rounded-lg px-4 py-2 mb-6 mr-6 cursor-pointer text-xl text-white font-medium uppercase ${
          isAllSelected ? selectedClasses : normalClasses
        }`}
        onClick={async () => {
          return await onSelect(null);
        }}
      >
        {t('sections.filter.allCategories')}
      </div>
      {cats
        ?.filter((cat) => cat?.slug !== 'noticias')
        .map((cat, index) => {
          const isCategorySelected = selectedCategory?._id === cat._id;
          return (
            <div
              key={cat.slug}
              className={`rounded-lg px-4 py-2 mb-6 mr-6 cursor-pointer text-xl text-white font-medium uppercase ${
                isCategorySelected ? selectedClasses : normalClasses
              }`}
              onClick={async () => {
                return await onSelect(cat);
              }}
            >
              {cat.label}
            </div>
          );
        })}
    </div>
  );
};

const TagSelector = ({
  selectedTags,
  tags: maybeTags,
  onSelect,
  tagTranslations = [],
  language = DEFAULT_LANGUAGE
}) => {
  const tags = maybeTags ?? [];

  const selectedClasses =
    'bg-blue-400 border border-transparent text-white shadow-sm transform transition';
  const normalClasses =
    'bg-white border border-blue-400 text-blue-400 hover:shadow-sm hover:scale-105 transform transition';

  return (
    <div className="flex flex-wrap justify-start items-center w-full h-auto">
      {tags.map((tag) => {
        const isTagSelected = selectedTags.includes(tag);
        const targetTag = tagTranslations.find((x) => x?.label === tag);
        const translatedTag = targetTag?.translations?.[language]?.label ?? tag;
        return (
          <div
            key={tag}
            className={`px-2 select-none my-1 cursor-pointer text-xl font-light mr-2 ${
              isTagSelected ? selectedClasses : normalClasses
            }`}
            onClick={async () => {
              return await onSelect(tag, !isTagSelected);
            }}
          >
            {translatedTag}
          </div>
        );
      })}
    </div>
  );
};
