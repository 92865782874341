import NewsList from '../pagesPublic/NewsList';
import PostList from '../pagesPublic/PostList';
import PageIndicators from '../pagesPublic/PageIndicators';
import PageTeam from '../pagesPublic/PageTeam';
import PageAbout from '../pagesPublic/PageAbout';
import PagePartners from '../pagesPublic/PagePartners';
import PageAboutData from '../pagesPublic/PageAboutData';
import PageGlossary from '../pagesPublic/PageGlossary';

const routes = [
  {
    i18nKey: 'menus.footer.team',
    label: 'A Equipa',
    path: '/equipa',
    component: PageTeam
  },
  {
    i18nKey: 'menus.footer.about',
    label: 'Sobre o OEJ',
    path: '/sobre',
    component: PageAbout
  },
  {
    i18nKey: 'menus.footer.partners',
    label: 'Parcerias',
    path: '/parcerias',
    component: PagePartners
  },
  {
    i18nKey: 'menus.footer.aboutData',
    label: 'Sobre os Dados',
    path: '/sobreosdados',
    component: PageAboutData
  },

  {
    i18nKey: 'menus.footer.indicators',
    label: 'Indicadores-chave',
    path: '/indicadores',
    component: PageIndicators
  },
  {
    i18nKey: 'menus.footer.posts',
    label: 'Publicações',
    path: '/publicacoes',
    component: PostList
  },
  {
    i18nKey: 'menus.footer.news',
    label: 'Notícias',
    path: '/noticias',
    component: NewsList
  },
  {
    i18nKey: 'menus.footer.glossary',
    label: 'Glossário',
    path: '/glossario',
    component: PageGlossary
  }
];
export default routes;
