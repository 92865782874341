// eslint-disable-next-line no-unused-vars
import { backendAddress } from '../common/variables';
// eslint-disable-next-line no-unused-vars
import { handleResponse, authenticate, errors } from './common';

export const getPosts = async (type = null) => {
  const userInfo = await authenticate();

  const route = type ? 'posts/type/' + type : 'posts';

  const response = await fetch(backendAddress + route, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};

export const getPost = async (_id) => {
  const userInfo = await authenticate();

  const response = await fetch(backendAddress + 'posts/' + _id, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};

export const putPost = async (post) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'posts/' + post._id, {
    method: 'PUT',
    body: JSON.stringify(post),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};

export const postPost = async (post) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'posts', {
    method: 'POST',
    body: JSON.stringify(post),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};

export const deletePost = async (_id) => {
  const userInfo = await authenticate();
  if (!userInfo) {
    return null;
  }

  const response = await fetch(backendAddress + 'posts/' + _id, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      jwt: userInfo?.signInUserSession.accessToken.jwtToken ?? ''
    }
  });

  return await handleResponse(response);
};
