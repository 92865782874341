import React from 'react';
import { getTags } from '../network/tags';
const TagContext = React.createContext();

const TagContextProvider = ({ children }) => {
  const [tags, setTags] = React.useState([]);

  const initTags = async () => {
    const response = await getTags();

    if (!response?.ok) {
      setTimeout(initTags, 2000);
      return;
    }
    setTags(response.data);
  };

  React.useEffect(() => {
    initTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TagContext.Provider
      value={{
        tags
      }}
    >
      {children}
    </TagContext.Provider>
  );
};

export default TagContext;
const TagContextConsumer = TagContext.Consumer;
export { TagContextProvider, TagContextConsumer };
