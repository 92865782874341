import React from 'react';

import './InputTextArea.scss';

const InputTextArea = ({
  initialValue,
  onChange = () => {},
  className = '',
  label = '',
  value,
  disabled = false
}) => {
  const inputRef = React.createRef();

  return (
    <div className="input-textarea relative flex flex-col w-full h-full">
      {!!label && <span className="text-sm">{label}</span>}
      <div className="relative grid">
        <textarea
          ref={inputRef}
          className={`z-10 overflow-hidden h-full grid-overlap ${className} rounded-md focus:outline-none ${
            !className.includes('bg-') ? 'bg-white' : ''
          } border border-gray-300 bg-transparent ${
            !className.includes('px-') ? 'px-4' : ''
          } ${!className.includes('py-') ? 'py-2' : ''}`}
          value={value ?? initialValue ?? ''}
          onChange={(e) => {
            const value = e.target.value;
            if (value !== initialValue) {
              return onChange(value);
            }
          }}
          defaultValue={initialValue}
          onBlur={(e) => {
            const value = e.target.value;
            if (value !== initialValue) {
              return onChange(value);
            }
          }}
          disabled={disabled}
        ></textarea>
        {/* absolute top-0 left-96 */}
        <div
          className={`z-10  grid-overlap ${className} invisible whitespace-pre-wrap ${
            !className.includes('px-') ? 'px-4' : ''
          } ${!className.includes('py-') ? 'py-2' : ''} `}
        >
          {value ?? initialValue ?? ''}
        </div>
      </div>
    </div>
  );
};

export default InputTextArea;
