/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { pt } from 'date-fns/locale';
import { DatePicker } from 'react-nice-dates';
import './InputDateSelector.scss';
import 'react-nice-dates/build/style.css';
import moment from 'moment';
import { XIcon } from '@heroicons/react/outline';

const InputDateSelector = ({
  disabled = false,
  onChange = () => {},
  value,
  className = '',
  format = 'yyyy/MM/dd',
  placeholder = 'Choose date'
}) => {
  return (
    <DatePicker
      onDateChange={(date) => {
        onChange(date);
      }}
      format={format}
      date={moment(value).isValid() ? moment(value).toDate() : undefined}
      locale={pt}
    >
      {({ inputProps, focused }) => {
        return (
          <div className={`relative w-full pr-8 ${className}`}>
            <input
              disabled={disabled}
              {...inputProps}
              className={`relative pr-4 w-full px-2 py-1`}
              value={value ? moment(value).format('YYYY-MM-DD') : ''}
              placeholder={placeholder}
            />
            <XIcon
              className="absolute right-1 top-1/2 transform -translate-y-1/2 rounded-md hover:bg-gray-200 transition w-6 h-6 cursor-pointer stroke-current"
              onClick={() => {
                onChange(null);
              }}
            ></XIcon>
          </div>
        );
      }}
    </DatePicker>
  );
};

export default InputDateSelector;
