/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import moment from 'moment';

import './PostThumbnail.scss';

const PostThumbnail = ({
  postMeta,
  className = '',
  showEmptyFields = false
}) => {
  if (!postMeta) {
    return (
      <div className="post-thumbnail w-full min-h-80 max-h-96 border border-bluegray-300">
        <span className="font-blue-400">No data</span>
      </div>
    );
  }

  let title = postMeta.title;
  if (!title) {
    title = showEmptyFields ? 'No Title' : '';
  }

  let subtitle = postMeta.subtitle;
  if (subtitle?.length > 150) {
    subtitle = postMeta.subtitle.substring(0, 140) + '...';
  }
  if (!subtitle) {
    subtitle = showEmptyFields ? 'No Subtitle' : '';
  }

  let date = postMeta.publishedDate;
  if (!date) {
    date = showEmptyFields ? 'No Data' : '';
  } else {
    date = moment(date).format('LL');
  }

  return (
    <div
      className={`post-thumbnail relative w-full h-auto shadow-sm hover:shadow-md hover:scale-105 transform transition ${className}`}
    >
      <div className="absolute top-0 left-0 px-6 h-8 min-w-1/2 max-w-full flex items-center text-sm font-medium uppercase whitespace-nowrap text-white bg-light-blue">
        {postMeta?.category?.label ?? (showEmptyFields ? '---' : '')}
      </div>
      <div className="">
        <div
          className="image-thumbnail bg-gray-200 bg-center bg-no-repeat bg-cover"
          style={{ backgroundImage: `url("${postMeta.image?.trim()}")` }}
        ></div>
      </div>
      <div className="w-full h-auto flex flex-col bg-white border-b border-b-bluegray-300">
        <div className="w-full px-6 py-10 text-2xl leading-tight font-bold">
          {title}
        </div>
        <div className="w-full px-6 pb-8 max-h-60 overflow-y-hidden text-2xl italic font-normal">
          {subtitle}
        </div>
        <div className="w-full px-6 pb-8 text-xl text-gray-400 italic font-normal">
          {date}{' '}
        </div>
      </div>
    </div>
  );
};

export default PostThumbnail;
