import MenuItem from './MenuItem';
import MenuItemDropdown from './MenuItemDropdown';

const traverseMenu = (routes, depth = 0, menuItemClassName = '') => {
  return routes?.map((route, index) => {
    if (route.noMenu) {
      return null;
    }

    if (route.routes) {
      return (
        <MenuItemDropdown
          classNameInner={menuItemClassName}
          key={route.path ?? index}
          route={route}
        >
          {traverseMenu(
            route.routes,
            depth + 1,
            'truncate ' + menuItemClassName
          )}
        </MenuItemDropdown>
      );
    } else {
      return (
        <MenuItem
          classNameInner={menuItemClassName}
          key={route.path ?? index}
          route={route}
          noPadding={route?.noPadding}
        ></MenuItem>
      );
    }
  });
};

export default traverseMenu;
