import './SectionImage.scss';

const SectionImage = ({ classNames, styles, options, toc }) => {
  const { src, alt, caption } = options;
  const width = options.width ? options.width + '%' : null;
  const align = options.align ?? 'start';
  return (
    <div
      className={`section section-image w-full print:w-3/4 flex flex-col justify-${align} items-center ${classNames}`}
      style={styles}
    >
      {!!toc?.title && (
        <h2 className="w-full pb-2 text-xl font-regular">{toc.title}</h2>
      )}
      <figure>
        <img
          alt={alt}
          src={src}
          style={{ width, ...styles }}
          className="w-full h-auto object-contain object-center"
        />
        {caption ? <figcaption>{caption}</figcaption> : null}
      </figure>
    </div>
  );
};

export default SectionImage;
