/* eslint-disable lingui/no-unlocalized-strings */
import { PhotographIcon } from '@heroicons/react/outline';

import SectionMenuManager from '../SectionMenuManager';
import Input from '../../Input';
import S3ImageUpload from '../../S3ImageUpload';
import { createOnSave } from '../../../common/util';

import './SectionImage.scss';

const SectionImage = ({
  classNames,
  styles,
  options: { src, alt, caption, width: maybeWidth, align: maybeAlign },
  toc,
  onSave,
  index,
  parent
}) => {
  const width = maybeWidth ? maybeWidth + '%' : null;
  const align = maybeAlign ?? 'start';

  const imageOnSave = createOnSave(onSave, `sections.${index}.options.src`);
  const captionOnSave = createOnSave(
    onSave,
    `sections.${index}.options.caption`
  );
  const altOnSave = createOnSave(onSave, `sections.${index}.options.alt`);
  return (
    <div
      className={`section section-image w-full flex flex-col justify-${align} items-center ${classNames}`}
      style={styles}
    >
      <SectionMenuManager
        toc={toc}
        parentToc={parent?.toc}
        index={index}
        onSave={onSave}
      ></SectionMenuManager>
      <figure className="w-full">
        <span className="text-sm">Upload / Link</span>
        <S3ImageUpload
          className="mb-4"
          url={src}
          onUpload={(url) => {
            imageOnSave(url?.trim() ?? '');
          }}
          onDelete={() => {
            imageOnSave('');
          }}
        ></S3ImageUpload>
        {src ? (
          <img
            alt={alt}
            src={src}
            style={{ width, ...styles }}
            className="w-full h-auto object-contain object-center mb-4"
          />
        ) : (
          <PhotographIcon
            className={`w-full h-auto rounded-xl bg-white opacity-30 mb-4`}
          ></PhotographIcon>
        )}
        <Input
          value={caption}
          className="meta-title w-full font-light italic text-sm leading-none bg-trasparent"
          label={<span className="">Legenda</span>}
          type="text"
          onChange={async (value) => {
            await captionOnSave(value?.trim() ?? '');
            await altOnSave(value?.trim() ?? '');
          }}
        ></Input>
      </figure>
    </div>
  );
};

export default SectionImage;
