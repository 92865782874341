/* eslint-disable lingui/no-unlocalized-strings */
import React, { useEffect, useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import isEmail from 'validator/lib/isEmail';

import AppContext from '../../context/AppContextBase';
import Ring from '../../components/Ring';

import logo from '../../img/logo2@2x.png';

import './Login.scss';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const appContext = useContext(AppContext);
  const history = useHistory();

  const fromRoute = history?.location?.state?.from;

  // Did mount
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const user = await appContext.currentUser();
      if (user) {
        setLoading(false);
        if (fromRoute) {
          console.log('Login: redirecting to from: ', fromRoute);
          history.push(fromRoute);
        } else {
          console.log('Login: redirecting to default admin');
          history.push('/admin');
        }
        return;
      }
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values, { setSubmitting }) => {
    const errorOut = async (e) => {
      await appContext.logOut();

      if (e.message.includes('User is not confirmed.')) {
        toast.error(
          'Por favor, confirme o seu email utilizando o e-mail que lhe foi enviado.'
        );
      } else if (e.message.includes(' Incorrect username or password.')) {
        toast.error('Email ou password incorrectos.');
      } else if (e.message.includes('User does not exist.')) {
        toast.error('Email ou password incorrectos.');
      } else {
        toast.error(
          <div>
            <div>Erro inesperado. Tente de novo dentro de momentos</div>
            {e.message ? <pre>{e.message}</pre> : null}
          </div>
        );
      }
      console.error(e);
      return;
    };

    setSubmitting(true);
    let awsUser;
    try {
      /* login in AWS */
      awsUser = await appContext.logInAws(values.username, values.password);
      if (!awsUser) {
        setSubmitting(false);
        toast.error('Erro a autenticar: utilizador não encontrado.');
        return;
      }
    } catch (e) {
      errorOut(e);
      setSubmitting(false);
      return;
    }
    /* Check first time challenge */
    if (awsUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
      history.push('/renew');
      setSubmitting(false);
    } else {
      /* Otherwise, authenticate in BE */
      try {
        await appContext.loginBackend(awsUser);
        setSubmitting(false);
        if (fromRoute) {
          history.push(fromRoute);
        } else {
          history.push('/admin');
        }
        return;
      } catch (err) {
        errorOut(err);
        return;
      }
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.username || !isEmail(values.username)) {
      errors.username = 'Obrigatório';
    }

    if (!values.password) {
      errors.password = 'Obrigatório';
    }

    return errors;
  };

  return (
    <div
      id="login"
      className="w-full h-full min-h-screen flex justify-center items-center flex-grow bg-gray-100"
    >
      <Formik
        initialValues={{ username: '', password: '' }}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex items-center">
            {loading ? (
              <Ring
                className="max-w-72 mr-12 w-auto flex justify-center items-end"
                size={40}
                scale={0.5}
              ></Ring>
            ) : (
              <Link
                to={'/'}
                className="max-w-72 mr-12 w-auto flex justify-center items-end"
              >
                <img className="max-h-full" src={logo} alt="Dinamia"></img>
              </Link>
            )}
            <div className="flex flex-col">
              <div className="mb-4 flex flex-col justify-start items-start">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="username"
                >
                  E-mail
                </label>
                <Field
                  type="email"
                  name="username"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
                <ErrorMessage
                  className="text-red-700 self-end"
                  name="username"
                  component="div"
                />
              </div>

              <div className="mb-6 flex flex-col justify-start items-start">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <Field
                  type="password"
                  name="password"
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3"
                />
                <ErrorMessage
                  className="text-red-700 self-end"
                  name="password"
                  component="div"
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-light-blue hover:bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-24"
                  type="submit"
                  disabled={isSubmitting || loading}
                >
                  Entrar
                </button>
                <button
                  className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-500"
                  disabled={isSubmitting || loading}
                >
                  <Link to="/recover">Recuperar Password</Link>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
