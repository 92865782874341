/* eslint-disable lingui/no-unlocalized-strings */
import moment from 'moment';
import deburr from 'lodash/deburr';
import cloneDeep from 'lodash/cloneDeep';

export const tryParseDate = (string, options = {}) => {
  const formats = [
    'M-D-YYYY',
    'MM-DD-YYYY',
    'DD-MM-YYYY',
    'D/MM/YYYY',
    'DD/MM/YY',
    'M-D-YY',
    'DD-MM-YY',
    'D/MM/YY',
    'DD/MM/YY'
  ];
  let result;
  for (let format of formats) {
    result = moment(string, format);
    if (result.isValid()) {
      return result;
    }
  }
  if (options.nullable) {
    return null;
  }
  return moment('invalid date');
};

export const handleRequest = (request, erroMessage) => {
  if (!request || request.result !== 'OK' || !request.data) {
    throw request;
  }
  return request;
};

export const anchorDownload = (blob, filename) => {
  const a = document.createElement('a');
  a.download = filename;
  a.href = URL.createObjectURL(blob);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(function () {
    URL.revokeObjectURL(a.href);
  }, 1500);
};

export const traverseObject = (object, fn) => {
  for (const key in object) {
    fn.apply(this, [key, object[key]]);
    if (object[key] !== null && typeof object[key] == 'object') {
      traverseObject(object[key], fn);
    }
  }
};

export const traverseArrayImmutable = (array, fn) => {
  const result = [];
  for (const object of array) {
    const copy = JSON.parse(JSON.stringify(object));
    result.push(copy);
    fn.apply(this, [copy]);

    if (copy !== null && typeof copy === 'object') {
      for (const key in copy) {
        if (Array.isArray(copy[key])) {
          traverseArrayImmutable(copy[key], fn);
        }
      }
    }
  }
  return result;
};

export const createOnSave = (onSave, path) => {
  return async (value) => {
    await onSave(path, value);
  };
};

export const generateSlug = (value, charLimit = 0) => {
  if (!value) {
    return '';
  }
  let result = deburr(value)
    .replace(/^\s*[\d.]+/, '')
    .replace(/\s*-\s*/g, '-')
    .replace(/[ \t]+/g, ' ')
    .replace(/ /g, '-')
    .replace(/[^-_[:alnum:]]/g, '')
    .toLowerCase()
    .trim();

  if (charLimit > 0) {
    result = result.substring(0, charLimit);
  }

  return result;
};

export const cleanValue = (value) => {
  const toClean =
    typeof value === 'string' ? value : JSON.stringify(Object.values(value));
  return deburr(toClean).toLowerCase().trim();
};

export const isEnter = (event) => event.key === 'Enter';

export const selectNodeText = (domElementOrReactRef) => {
  const target = domElementOrReactRef?.current ?? domElementOrReactRef;

  if (!target && !target?.current) {
    return;
  }
  let range, selection;
  if (document.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(target);
    range.select();
  } else if (window.getSelection) {
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(target);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const isNullish = (value) => {
  return value === null || typeof value === 'undefined';
};

export const arrayMoveTo = (array, fromIndex, toIndex) => {
  const clone = cloneDeep(array);
  clone.splice(toIndex, 0, clone.splice(fromIndex, 1)[0]);
  //console.log({ array,fromIndex, toIndex, clone });
  return clone;
};

export const multiLineString = (string) => {
  return string.split('\n').map((i, key) => {
    return <div key={key}>{i}</div>;
  });
};

/** Idempontent, mutates object. Creates translations object in object if it does not exist. */
export const enableLocalization = (object, language) => {
  if (!object) {
    return;
  }
  if (!object.translations) {
    object.translations = {};
  }

  return object;
};

/** Idempontent, mutates object. Creates translation fields in object if they do not exist. */
export const createTranslation = (object, language) => {
  if (!object.translations) {
    object.translations = {};
  }
  if (!object.translations[language]?.init) {
    object.translations[language] = { ...object };
    object.translations[language].init = true;
    delete object.translations[language].translations;
    delete object.translations[language]._id;
    delete object.translations[language].__v;
    delete object.translations[language].meta;
  }

  return object;
};
