import ReactPlayer from 'react-player';
import isURL from 'validator/lib/isURL';

import './SectionEmbed.scss';

const SectionImage = ({ classNames, styles, options, toc }) => {
  const { url, caption } = options;
  // const width = options.width ? options.width + '%' : null;
  // const align = options.align ?? 'start';
  return (
    <div
      className={`section section-embed print:hidden w-full flex flex-col justify-start items-start ${classNames}`}
      style={styles}
    >
      {!!toc?.title && (
        <h2 className="w-full pb-8 text-3xl font-regular">{toc.title}</h2>
      )}
      <div className="w-full">
        <div className="player-wrapper">
          {isURL(url) && (
            <ReactPlayer className="react-player" url={url} controls={true} />
          )}
        </div>
        {caption ? <figcaption>{caption}</figcaption> : null}
      </div>
    </div>
  );
};

export default SectionImage;
