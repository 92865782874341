/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import ls from 'local-storage';

import AppContext from './context/AppContextBase';
import { i18n, i18nInit } from './i18n';
import loadResources from './i18n/loadResources';
import routes from './routes';
import PublicRoute from './components/RoutePublic';
import ProtectedRoute from './components/RouteProtected';
import MetaTags from './components/MetaTags';
import Lost from './components/Lost';
import ScrollToTop from './components/ScrollToTop';

import logo from './img/logo2@2x.png';

import './App.scss';

const App = () => {
  const [i18nLoaded, setI18nLoaded] = React.useState(false);

  const { initLoaded, authenticated } = React.useContext(AppContext);

  const init = async () => {
    await i18nInit;
    await loadResources(i18n);
    setI18nLoaded(true);
    initLanguage();
  };

  const initLanguage = () => {
    const lsLang = ls.get('lang');
    i18n.changeLanguage(lsLang);
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const switchRoutes = [];

  routes?.forEach((route) => {
    if (!route.protected) {
      if (route?.routes?.length) {
        route.routes.forEach?.((subroute) =>
          switchRoutes.push(
            <PublicRoute
              key={subroute.path}
              exact={subroute.exact}
              path={subroute.path}
              component={subroute.component}
            ></PublicRoute>
          )
        );
      }
      switchRoutes.push(
        <PublicRoute
          key={route.path}
          exact={route.exact}
          path={route.path}
          component={route.component}
        ></PublicRoute>
      );
    } else {
      if (route?.routes?.length) {
        route?.routes?.forEach?.((subroute) =>
          switchRoutes.push(
            <ProtectedRoute
              authenticated={authenticated}
              key={subroute.path}
              exact={subroute.exact}
              path={subroute.path}
              component={subroute.component}
            ></ProtectedRoute>
          )
        );
      }
      route.push(
        <ProtectedRoute
          key={route.path}
          authenticated={authenticated}
          exact={route.exact}
          path={route.path}
          component={route.component}
        ></ProtectedRoute>
      );
    }
  });

  return (
    <>
      <MetaTags
        title={'Observatório do Emprego Jovem'}
        description={'Observatório do Emprego Jovem'}
        imageSrc={logo}
        url={window.location.href}
      ></MetaTags>

      <>
        <Router className="w-full">
          <div
            className={
              'flex flex-col justify-start items-center min-h-screen print:block print:text-center w-full'
            }
          >
            <ScrollToTop></ScrollToTop>
            <AppLoading isLoading={!(i18nLoaded && initLoaded)}>
              <GaWrapper>
                <Switch>
                  {switchRoutes}
                  <PublicRoute
                    render={() => <Lost className="h-screen"></Lost>}
                  />
                </Switch>
              </GaWrapper>
            </AppLoading>
          </div>
        </Router>
        <ToastContainer
          className="print:hidden"
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    </>
  );
};

const GaWrapper = ({ children }) => {
  const location = useLocation();
  React.useEffect(() => {
    const isDev = process.env.NODE_ENV !== 'production';
    console.log(`${isDev ? 'DEV (dummy) ' : ''}Ga event`, {
      hitType: 'pageview',
      page: location.pathname + location.search
    });
    if (process.env.NODE_ENV === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search
      });
    }
  }, [location]);

  return children;
};
const AppLoading = ({ isLoading, children }) => {
  return (
    <>
      {isLoading ? <div className={'loader print:hidden'}></div> : null}
      {children}
    </>
  );
};

export default App;
