/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import moment from 'moment';

import InputTextArea from '../../InputTextArea';
import S3ImageUpload from '../../S3ImageUpload';

import { getCategories } from '../../../network/categories';
import { createOnSave, generateSlug } from '../../../common/util';
import './NewsHeader.scss';
import S3PdfUpload from '../../S3PdfUpload';
import { postTag } from '../../../network/tags';
import { DEFAULT_LANGUAGE } from '../../../common/translationUtils';
import TagContext from '../../../context/TagContext';

const categoryStyles = {
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition
    };
  },
  multiValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition
    };
  },
  multiValueLabel: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition
    };
  },
  menu: (provided, state) => ({
    ...provided,
    zIndex: 1000000
  }),
  menuList: (provided, state) => {
    return { ...provided, zIndex: 1000000 };
  }
};

const PostHeader = ({
  meta,
  language,
  onSave = () => {},
  availableTags = []
}) => {
  const [categories, setCategories] = React.useState([]);
  const { tags: allTags } = React.useContext(TagContext);

  const isLocalized = language !== DEFAULT_LANGUAGE;

  const fetchCategories = async () => {
    const response = await getCategories();
    if (!response.ok) {
      toast.error('Erro a obter categorias disponíveis');
      return;
    }
    setCategories(response.data);
  };

  React.useEffect(() => {
    if (!categories || !categories?.length) {
      fetchCategories();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const categoryOptions = categories?.map((cat) => {
    const translatedCategory = isLocalized
      ? { ...cat, ...cat?.translations?.[language] }
      : cat;
    return {
      value: translatedCategory._id,
      label: translatedCategory.label,
      _id: translatedCategory._id
    };
  });

  const imageOnSave = createOnSave(onSave, 'image');
  const attachmentsOnSave = createOnSave(onSave, 'attachments');
  const categoryOnSave = createOnSave(onSave, 'category');
  const tagsOnSave = createOnSave(onSave, 'tags');

  const handleTagChange = async (newValues, actionMeta) => {
    for (const option of newValues) {
      if (!option.__isNew__) {
        continue;
      }
      console.log('posting new tag: ', option.label);
      const slug = generateSlug(option.label);
      const tag = { slug, label: option.label };
      const response = await postTag(tag);

      if (!response?.ok) {
        toast.error('Erro a criar tag. Por favor tente de novo.');
        return;
      }
    }

    tagsOnSave(newValues.map((option) => option.value));
  };

  const getMeta = () => {
    return language !== DEFAULT_LANGUAGE
      ? meta?.translations?.[language]
      : meta;
  };

  const targetCategory = categories.find(
    (x) =>
      (!!x._id && x._id === meta?.category?._id) || x._id === meta?.category
  );
  const translatedCategory = isLocalized
    ? { ...targetCategory, ...targetCategory?.translations?.[language] }
    : targetCategory;

  const category = (
    <div className="flex flex-col w-full">
      <span className="text-sm">Categoria</span>
      <Select
        onChange={(option) => {
          categoryOnSave(option._id);
        }}
        placeholder={'Seleccionar'}
        value={translatedCategory}
        className="w-3/4 font-light italic text-2xl leading-loose bg-transparent"
        options={categoryOptions}
        styles={categoryStyles}
      />
    </div>
  );

  const tags = (
    <div className="flex flex-col w-full">
      <span className="text-sm">Temas</span>
      {isLocalized ? (
        <Select
          closeMenuOnSelect={false}
          onChange={handleTagChange}
          isMulti
          value={meta?.tags?.map?.((tag) => {
            if (isLocalized) {
              const targetTag = allTags.find((x) => x?.label === tag);
              let label = targetTag?.translations?.[language]?.label;
              if (!label) {
                label = `[Missing Translation] ${tag}`;
              }
              return {
                label,
                value: tag
              };
            }
            return { label: tag, value: tag };
          })}
          options={availableTags.map((tag) => {
            if (isLocalized) {
              const targetTag = allTags.find((x) => x?.label === tag);
              let label = targetTag?.translations?.[language]?.label;
              if (!label) {
                label = `[Missing Translation] ${tag}`;
              }
              return {
                label,
                value: tag
              };
            }
            return { label: tag, value: tag };
          })}
          placeholder={'Escolher temas'}
          //value={meta?.tags ?? []}
          className="w-3/4 font-light italic text-2xl leading-loose bg-transparent"
          styles={categoryStyles}
        ></Select>
      ) : (
        <CreatableSelect
          closeMenuOnSelect={false}
          onChange={handleTagChange}
          isMulti
          value={meta?.tags?.map?.((tag) => {
            if (isLocalized) {
              const targetTag = allTags.find((x) => x?.label === tag);
              let label = targetTag?.translations?.[language]?.label;
              if (!label) {
                label = `Missing Translation: ${tag}`;
              }
              return {
                label,
                value: tag
              };
            }
            return { label: tag, value: tag };
          })}
          options={availableTags.map((tag) => {
            if (isLocalized) {
              const targetTag = allTags.find((x) => x?.label === tag);
              return {
                label:
                  targetTag?.translations?.[language]?.label ??
                  `Missing Translation: ${tag}`,
                value: tag
              };
            }
            return { label: tag, value: tag };
          })}
          placeholder={'Escolher ou criar temas'}
          //value={meta?.tags ?? []}
          className="w-3/4 font-light italic text-2xl leading-loose bg-transparent"
          styles={categoryStyles}
        />
      )}
    </div>
  );

  const title = (
    <InputTextArea
      value={getMeta()?.title ?? ''}
      className="z-50 meta-title w-full mb-4 font-medium text-4xl leading-normal bg-trasparent"
      label={<span className="">Título da Notícia</span>}
      type="text"
      onChange={createOnSave(onSave, 'title')}
      affixComponentProps={{ backgroundColor: '#ffffff' }}
    ></InputTextArea>
  );

  const subtitle = (
    <InputTextArea
      value={getMeta()?.subtitle ?? ''}
      className="z-50 meta-title w-full mb-4 font-light text-2xl leading-normal bg-trasparent"
      label={<span className="">Excerto / Subtítulo</span>}
      type="text"
      onChange={createOnSave(onSave, 'subtitle')}
      affixComponentProps={{ backgroundColor: '#ffffff' }}
    ></InputTextArea>
  );

  const image = (
    <>
      <span className="text-sm">Background</span>
      <S3ImageUpload
        className="mb-4 bg-transparent"
        url={getMeta()?.image}
        onUpload={(url) => {
          imageOnSave(url);
        }}
        onDelete={() => {
          imageOnSave('');
        }}
      ></S3ImageUpload>
    </>
  );

  const attachments = (
    <>
      <span className="text-sm">Anexos PDF</span>
      <S3PdfUpload
        className="mb-4 bg-transparent"
        attachments={getMeta()?.attachments ?? []}
        onUpload={(attachments) => {
          attachmentsOnSave(attachments);
        }}
        onDelete={(deletedAttachment) => {
          attachmentsOnSave(
            getMeta()?.attachments?.filter(
              (attachment) => attachment.url !== deletedAttachment.url
            )
          );
        }}
      ></S3PdfUpload>
    </>
  );

  const imageBackground = (
    <div
      className={`z-0 relative w-full ${
        meta?.image ? 'h-96' : 'h-20'
      } pb-4 mb-4 border rounded-md`}
    >
      <img
        className="w-full h-full object-cover object-center"
        alt={'featured image'}
        src={meta?.image}
      ></img>
    </div>
  );

  // const date = (
  //   <div className="w-full z-50 pr-8 h-auto py-3 border-t border-white flex justify-start items-center">
  //     <Input
  //       value={meta?.date ?? ''}
  //       className="meta-title w-full font-light italic text-2xl  leading-loose bg-trasparent"
  //       label={<span className="">Data (texto livre, por extenso)</span>}
  //       type="text"
  //       onChange={createOnSave(onSave, 'date')}
  //       affixComponentProps={{ backgroundColor: '#ffffff' }}
  //     ></Input>
  //   </div>
  // );

  const date = (
    <div className="w-1/2 z-50  h-auto py-3 flex justify-start items-center">
      Data de criação: {moment(meta?.createdAt).format('LL')}
    </div>
  );

  return (
    <div className="admin-news-header relative w-full flex justify-start print:justify-start items-center">
      <div className="relative z-20 w-full h-auto pr-12 pb-12 flex flex-col justify-start items-start ">
        {title}
        {subtitle}
        {date}
        <div className="w-full flex mb-4 items-start">
          <div className="w-1/2 flex flex-col">{category}</div>
          <div className="w-1/2 mb-4">{tags}</div>
        </div>
        <div className="w-full flex mb-4 items-start">
          <div className="w-1/2 pr-8 flex flex-col">
            {image}
            {imageBackground}
          </div>
          <div className="w-1/2 mb-4">{attachments}</div>
        </div>
      </div>
    </div>
  );
};

export default PostHeader;
