import React from 'react';
import { toast } from 'react-toastify';

import Ellipsis from '../../components/Ellipsis';
import { cleanValue, isEnter } from '../../common/util';
import { postPost, getPosts } from '../../network/posts';

import './ResearchList.scss';
import postTypes from '../../common/postTypes';
import ResearchEditor from '../ResearchEditor';

const ResearchList = ({
  type = postTypes.research.type,
  typeLabel = 'Item de Investigação',
  typeRoute = '/admin/r',
  ...rest
}) => {
  const [posts, setPosts] = React.useState([]);
  const [postCache, setPostCache] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [isLoading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await getPosts(type);
      if (response.ok) {
        const metas =
          response?.data?.filter((post) => post?.meta?.postType === type) ?? [];
        setPosts(metas);
        setPostCache(metas);
      } else {
        toast.error('Erro a carregar categorias. Por favor tente de novo.');
      }
    };
    if (!posts || !posts?.length) {
      fetchData();
    }
  }, [type]); // eslint-disable-line

  const listItems = posts?.map?.((post, index) => {
    const meta = post?.meta;
    if (!meta) {
      console.error('Missing meta for post ' + post?._id);
      return null;
    }

    return (
      <div
        key={meta?.postId + index}
        className="post-list-item rounded-md overflow-visible w-full min-h-60 mb-4 bg-white shadow-md border border-gray-300 flex justify-start items-start"
      >
        <ResearchEditor
          onDelete={(post) => {
            setPostCache((prevCache) =>
              prevCache.filter((p) => p._id !== post._id)
            );
            setPosts((prevPosts) => {
              return prevPosts.filter((p) => p._id !== post._id);
            });
          }}
          meta={post?.meta}
          post={post}
          {...rest}
        ></ResearchEditor>
      </div>
    );
  });

  const searchInput = (
    <div className="flex mr-8">
      <input
        className="bg-bluegray-200 active:bg-bluegray-300 outline-none rounded-sm min-w-60 h-auto pl-3 py-3 text-gray-800 placeholder-current text-base focus:shadow-sm"
        placeholder="Procurar"
        value={search}
        onChange={(event) => {
          setSearch(event.target.value);
        }}
        onKeyPress={(e) => {
          if (isEnter(e)) {
            setLoading(true);
            const newMetas = postCache.filter(
              (meta) => cleanValue(meta).indexOf(cleanValue(search)) !== -1
            );
            setPosts(newMetas);
            setLoading(false);
          }
        }}
      ></input>{' '}
      {isLoading && <Ellipsis backgroundColor="#5ea2ef" scale={0.3}></Ellipsis>}
    </div>
  );

  const createPost = (
    <>
      <div className="flex h-full">
        <button
          className="bg-light-blue hover:bg-blue-500 text-white font-bold py-2 px-4 rounded focus:outline-none mr-24"
          disabled={isLoading}
          onClick={async () => {
            setLoading(true);
            const response = await postPost({ postType: type });
            if (!response?.ok) {
              toast.error(
                `Erro a criar ${typeLabel}. Por favor tente de novo.`
              );
            } else {
              console.log('response.data', response.data);
              setPostCache((prevCache) => [response.data, ...prevCache]);
              setPosts((prevPosts) => [response.data, ...prevPosts]);
            }
            setLoading(false);
          }}
        >
          Criar {typeLabel}
        </button>
      </div>
      {isLoading && <Ellipsis backgroundColor="#5ea2ef" scale={0.3}></Ellipsis>}
    </>
  );

  return (
    <div className="post-list relative w-full flex flex-col p-8 bg-white">
      <div className="header pb-4 flex justify-start items-center">
        {searchInput}
        {createPost}
      </div>
      {listItems}
    </div>
  );
};

export default ResearchList;
