/* eslint-disable lingui/no-unlocalized-strings */
import { isNullish } from '../../common/util';
import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { withRouter } from 'react-router';
import { ChevronDownIcon, XIcon } from '@heroicons/react/outline';

import MainMenu from '../../components/Menu';
import Footer from '../../components/Footer';
import routes from '../../routes';
import footerRoutes from '../../routes/footer';
import Input from '../../components/Input';
import Error from '../../components/Error';
import Ring from '../../components/Ring';

import { getCategories } from '../../network/categories';

import './ResearchList.scss';
import { matchSearch } from '../../common/search';
import { DEFAULT_LANGUAGE } from '../../common/translationUtils';
import { getPosts } from '../../network/posts';
import postTypes from '../../common/postTypes';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import TagContext from '../../context/TagContext';
import Select from 'react-select';

const ResearchList = () => {
  const [posts, setPosts] = React.useState(null);
  const [categories, setCategories] = React.useState(null);
  const [tags, setTags] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [authors, setAuthors] = React.useState([]);
  const [authorSearch, setAuthorSearch] = React.useState([]);
  const [postCache, setPostCache] = React.useState([]);

  const { tags: allTags } = React.useContext(TagContext);

  const location = useLocation();
  const { t, i18n } = useTranslation();

  const filterByCategory = (posts) => {
    const newPosts = posts.filter(
      (post) => post.meta.category?.slug === selectedCategory?.slug
    );
    if (newPosts.length > 0) {
      return newPosts;
    } else {
      return [];
    }
  };

  const filterByTag = (posts) => {
    const newPosts = posts.filter((post) =>
      post.meta.tags.some((item) => selectedTags.includes(item))
    );
    if (newPosts.length > 0) {
      return newPosts;
    } else {
      return [];
    }
  };

  const init = async () => {
    await loadPosts();
    await loadCategories();

    const search = location?.search;
    if (search) {
      setSearch(search);
    }
  };

  React.useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setAuthors(getAuthorsFromPosts(posts));
  }, [posts]);

  // React.useEffect(() => {
  //   console.log(
  //     'Language changed, updating post metas to lang ',
  //     i18n.language
  //   );
  //   const localized = getLocalizedMetaCache(i18n.language, postCache);
  //   setPosts(localized);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [i18n.language]);

  React.useEffect(() => {
    doSearchAndFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedTags, search, authorSearch, postCache]);

  const doSearchAndFilters = () => {
    let newPosts = postCache;

    if (
      !selectedCategory &&
      selectedTags.length < 1 &&
      !search &&
      !authorSearch?.length
    ) {
      setPosts(newPosts);
      return;
    }

    if (selectedCategory) {
      newPosts = filterByCategory(newPosts);
    }
    if (selectedTags.length > 0) {
      newPosts = filterByTag(newPosts);
    }

    if (search) {
      newPosts = newPosts.filter((x) => matchSearch(search, x));
    }

    if (authorSearch?.length) {
      console.log('doing author search');
      newPosts = newPosts.filter((post) =>
        authorSearch.every((option) =>
          post?.data.authors?.some(
            (author) => author.name === option.value.name
          )
        )
      );
    } else {
      console.log('skipping author search');
    }

    setPosts(newPosts);
  };

  const loadPosts = async () => {
    const request = await getPosts(postTypes.research.type);
    if (request?.ok) {
      const posts = request.data ?? [];
      // console.log(metaRequest.data);
      const published = posts.filter((x) => !!x?.meta?.publishedDate);

      // const localized = getLocalizedMetaCache(i18n.language, published);
      // setPostCache(published);
      // setPosts(localized);
      // loadTagsFromPosts(localized);

      setPostCache(published);
      setPosts(published);
      loadTagsFromPosts(published);
    } else {
      // console.log('Clearing post cache.');
      setPosts([]);
      setPostCache(postCache);
    }
  };

  const loadTagsFromPosts = (posts) => {
    const tags = new Set();
    posts.forEach((post) => {
      const metaTags = post?.meta?.tags ?? [];
      metaTags.forEach((tag) => tags.add(tag));
    });
    if (tags.size < 1) {
      setTags([]);
    } else {
      setTags(Array.from(tags));
    }
  };

  const loadCategories = async () => {
    const categoryRequest = await getCategories();
    if (categoryRequest?.ok) {
      // console.log(categoryRequest.data);
      let cats = categoryRequest.data ?? [];
      setCategories(
        cats.filter((x) => postTypes.research.categories.includes(x.slug))
      );
    } else {
      setCategories(null);
    }
  };

  const isLoadingPosts = isNullish(posts);
  const isLoadingCategories = isNullish(categories);
  const isLoadingTags = isNullish(tags);
  const noPosts = posts?.length < 1;
  const noCategories = categories?.length < 1;
  const noTags = tags?.length < 1;

  const searchSection = (
    <>
      <Input
        placeholder=""
        label={
          <div className="text-2xl font-bold uppercase pb-4">
            {t('sections.search.placeholder')}
          </div>
        }
        className="bg-white border mb-4 border-gray-300 w-full h-full placeholder-current text-base uppercase"
        containerClassName="h-full flex items-center"
        onChange={(value) => {
          setSearch(value);
        }}
        value={search}
      ></Input>
      <div
        className={`flex mb-6 justify-center items-center text-gray-300 cursor-pointer hover:text-gray-700 transition`}
        onClick={() => {
          setSelectedCategory(null);
          setSelectedTags([]);
          setSearch('');
        }}
      >
        <XIcon className={'w-8 h-8 mr-2 stroke-current'}></XIcon>

        <span className={'text-current text-base leading-none select-none'}>
          {t('sections.search.clear')}
        </span>
      </div>
    </>
  );

  // eslint-disable-next-line no-unused-vars
  const categoriesSection = noCategories ? (
    <Error message={t('errors.noCategories')}></Error>
  ) : isLoadingCategories ? (
    <Ring size={40} scale={0.5}></Ring>
  ) : (
    <>
      <div className="flex md:hidden text-2xl font-bold uppercase pb-4">
        {t('sections.filter.filterCategory')}
      </div>
      <CategorySelector
        categories={categories}
        selectedCategory={selectedCategory}
        onSelect={(selectedCategory) => setSelectedCategory(selectedCategory)}
      ></CategorySelector>
    </>
  );

  const authorSearchSection = (
    <>
      <div className="pb-2 text-xl font-bold uppercase">
        {t('sections.searchAuthor.placeholder')}
      </div>
      <Select
        onChange={(newValues) => {
          console.log(newValues);
          setAuthorSearch(newValues);
        }}
        placeholder={t('sections.searchAuthor.placeholder')}
        isMulti={true}
        value={authorSearch ?? []}
        className="w-full font-light italic text-base leading-loose bg-transparent pb-4"
        options={authors
          ?.filter(
            (author) => !authorSearch.some((search) => search.value.name === author.name)
          )
          .map?.(
            (author) =>
              ({
                label: (
                  <div className="font-medium flex items-center justify-between">
                    {author.name}{' '}
                    <div className="font-base text-sm text-gray-800 ml-2 rounded-full bg-gray-100 w-6 h-6 flex items-center justify-center">
                      {author.count}
                    </div>
                  </div>
                ),
                value: author
              } ?? [])
          )}
        styles={authorStyles}
      />
      <div
        className={`flex mb-6 justify-center items-center text-gray-300 cursor-pointer hover:text-gray-700 transition`}
        onClick={() => {
          setAuthorSearch([]);
        }}
      >
        <XIcon className={'w-8 h-8 mr-2 stroke-current'}></XIcon>

        <span className={'text-current text-base leading-none select-none'}>
          {t('sections.search.clear')}
        </span>
      </div>
    </>
  );

  const tagsSection = (
    <>
      <div className="text-xl font-bold uppercase pb-4">
        {t('sections.filter.filterTag')}
      </div>
      {noTags ? (
        <Error className="pt-4" message={t('errors.noTags')}></Error>
      ) : isLoadingTags ? (
        <Ring size={40}></Ring>
      ) : (
        <TagSelector
          tagTranslations={allTags}
          language={i18n.language}
          tags={tags}
          selectedTags={selectedTags}
          onSelect={(selectedTag, isSelect) => {
            let newTags = [...selectedTags];
            if (isSelect) {
              // console.log('select tag: ', selectedTag);
              newTags.push(selectedTag);
            } else {
              // console.log('de-select tag: ', selectedTag);
              newTags = newTags.filter((x) => x !== selectedTag);
            }

            setSelectedTags(newTags);
          }}
        ></TagSelector>
      )}
    </>
  );

  const articles = posts?.filter(
    (post) => post?.meta?.category?.slug === 'artigos'
  );

  const books = posts?.filter(
    (post) => post?.meta?.category?.slug === 'livros-e-capitulos'
  );

  const others = posts?.filter(
    (post) => post?.meta?.category?.slug === 'investigacao-outros'
  );

  return (
    <>
      <MainMenu routes={routes}></MainMenu>
      <div
        id="post-list"
        className="relative px-8 lg:px-40 pt-14 bg-gray-100 flex flex-col justify-start items-center w-full h-full min-h-screen"
      >
        <div className="relative w-full h-auto flex flex-col md:flex-row justify-end items-start pb-8">
          <div className="hidden md:flex flex-col items-start h-full w-full md:w-1/4 pr-0 md:pr-12">
            {searchSection}
          </div>
          <div className="relative flex flex-col h-full w-full md:w-3/4 pl-0 md:pl-12">
            <h1 className="text-4xl md:text-6xl font-bold pb-2 uppercase">
              {t`pages.research.title`}
            </h1>
            <div className="w-full h-auto flex flex-col justify-start items-start text-lg md:text-xl">
              {t`pages.research.subtitle`}
            </div>
            {/* {categoriesSection} */}
          </div>
        </div>
        <div className="w-full h-auto mb-12 flex flex-col md:flex-row">
          <div className="w-full md:w-1/4 h-auto md:min-h-96 flex flex-col items-start pr-12 pb-12 md:pb-0 border-r-0 md:border-r-2 border-blue-400">
            {authorSearchSection}
            {tagsSection}
          </div>
          <div className="flex md:hidden flex-col items-start h-full w-full md:w-1/4 pr-0 md:pr-12">
            {searchSection}
          </div>
          <div className="w-full md:w-3/4 pl-0 md:pl-12 pb-12 md:pb-0 flex flex-col justify-center items-center">
            {noPosts ? (
              <Error
                className="w-full justify-center pt-16"
                message={t`errors.noPosts`}
              ></Error>
            ) : isLoadingPosts ? (
              <Ring size={40}></Ring>
            ) : (
              <>
                <ResearchGrid
                  title={t`pages.research.articles.title`}
                  posts={articles}
                ></ResearchGrid>
                <ResearchGrid
                  title={t`pages.research.books.title`}
                  posts={books}
                ></ResearchGrid>
                <ResearchGrid
                  title={t`pages.research.others.title`}
                  posts={others}
                ></ResearchGrid>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer routes={footerRoutes}></Footer>
    </>
  );
};

export default withRouter(ResearchList);

const ResearchGrid = ({ posts, title }) => {
  const scrollableDivRef = React.useRef(null);
  const [hasOverflow, setHasOverflow] = React.useState(false);

  const { t } = useTranslation();

  React.useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      if (scrollableDiv.clientHeight < scrollableDiv.scrollHeight) {
        setHasOverflow(true);
      } else {
        setHasOverflow(false);
      }
    }
  }, [posts]);

  if (!posts.length) {
    return null;
  }

  return (
    <div className="w-full h-auto flex flex-col justify-start items-start p-2 pt-0 mb-8">
      <div className="w-full pb-2 font-bold text-2xl">{title}</div>
      <div
        ref={scrollableDivRef}
        className={`relative w-full max-h-192 md:max-h-160 h-auto flex flex-col justify-start border ${
          hasOverflow ? 'border-b-0 rounded-t-md' : 'rounded-md'
        } border-bluegray-300 items-start overflow-scroll`}
      >
        <div
          className={`sticky top-0 h-10 flex-shrink-0 w-full flex justify-start items-center border-b border-bluegray-300 bg-bluegray-300`}
        >
          <div className="w-1/6 p-2 h-full hidden md:flex justify-center items-center border-r border-bluegray-300">
            {t('pages.research.grids.tableHeader.date')}
          </div>
          <div className="w-1/4 p-2 h-full hidden md:flex justify-start items-center border-r border-bluegray-300">
            {t('pages.research.grids.tableHeader.authors')}
          </div>

          <div className="w-2/6 p-2 h-full hidden md:flex justify-start items-center border-r border-bluegray-300">
            {t('pages.research.grids.tableHeader.title')}
          </div>
          <div className="w-1/4 p-2 h-full hidden md:flex justify-start items-center">
            {t('pages.research.grids.tableHeader.publishedIn')}
          </div>
        </div>
        {posts.map((post, index) => {
          const isEven = index % 2 === 0;
          const isLast = index === posts.length - 1;

          const articleDate =
            post?.data?.date?.value ??
            (post?.data?.date && moment(post?.data?.date, true).isValid() ? (
              <div
                className=""
                // title={moment(post?.data?.date).format('YYYY/MM/DD')}
              >
                {moment(post?.data?.date).format('YYYY')}
                {/* <span className="text-gray-400">
                {moment(post?.data?.date).format(' / MM / DD')}
              </span> */}
              </div>
            ) : (
              post?.data?.date?.value ?? post?.data?.date ?? ''
            ));

          const authors = (
            <div className=" flex flex-wrap justify-start items-start content-start gap-1">
              {post?.data?.authors?.map((author, index) => {
                return (
                  <div
                    key={author?.name ?? index}
                    className="px-1 bg-bluegray-200 rounded-md text-sm"
                  >
                    {author?.url ? (
                      <a
                        className="break-words underline text-gray-800 hover:text-gray-500 transition duration-75"
                        href={author?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {author?.name}
                      </a>
                    ) : (
                      author?.name
                    )}
                  </div>
                );
              })}
            </div>
          );

          let postTitle;
          if (post.data?.url) {
            postTitle = (
              <a
                className="break-words underline text-gray-800 hover:text-gray-500 transition duration-75"
                href={post.data.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {post.meta.title}
              </a>
            );
          } else {
            postTitle = <div className="text-lg">{post.meta.title}</div>;
          }

          return (
            <Fragment key={post?._id ?? index}>
              <div
                className={`min-h-20 flex-shrink-0 w-full hidden md:flex justify-start items-center border-b border-bluegray-300 ${
                  isEven ? 'bg-white' : 'bg-bluegray-100'
                } ${isLast ? 'border-none' : ''}`}
              >
                <div className="w-1/6 p-2 h-full flex justify-center items-center border-r border-bluegray-300">
                  {articleDate}
                </div>
                <div className="w-1/4 p-2 h-full flex justify-start items-center border-r border-bluegray-300">
                  {authors}
                </div>
                <div className="w-2/6 p-2 h-full flex justify-start items-center border-r border-bluegray-300">
                  {postTitle}
                </div>
                <div className="w-1/4 p-2 h-full flex justify-start items-center">
                  {post?.data?.publishedIn}
                </div>
              </div>
              <div
                className={`min-h-20 flex-shrink-0 px-2 py-4 w-full flex flex-col md:hidden justify-start items-center border-b border-bluegray-300 ${
                  isEven ? 'bg-white' : 'bg-bluegray-100'
                } ${isLast ? 'border-none' : ''}`}
              >
                <div className="w-full p-2 pb-0 h-full flex justify-start items-center">
                  <div className="w-2/5 flex-shrink-0">
                    {t('pages.research.grids.tableCells.date')}
                  </div>{' '}
                  {articleDate}
                </div>
                <div className="w-full p-2 pb-0 h-full flex justify-start items-center">
                  <div className="w-2/5 flex-shrink-0">
                    {t('pages.research.grids.tableCells.publishedIn')}
                  </div>
                  {post?.data?.publishedIn}
                </div>
                <div className="w-full p-2 h-full flex justify-start items-center pb-4 mb-2 border-b border-bluegray-200">
                  <div className="w-2/5 flex-shrink-0">
                    {t('pages.research.grids.tableCells.authors')}
                  </div>{' '}
                  {authors}
                </div>
                <div className="w-full p-2 pb-0 h-full flex justify-start items-center">
                  {postTitle}
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
      {hasOverflow && (
        <div
          className={`flex-shrink-0 w-full flex justify-center items-center bg-white text-bluegray-700 rounded-b-md border-l border-r border-b border-bluegray-300`}
        >
          {[0, 1, 2].map((i) => {
            return (
              <ChevronDownIcon
                key={i}
                className={'w-5 h-3 px-1 stroke-current border-t'}
              ></ChevronDownIcon>
            );
          })}
        </div>
      )}
    </div>
  );
};

const CategorySelector = ({ selectedCategory, categories, onSelect }) => {
  const { t } = useTranslation();

  const cats = categories ?? [];

  const selectedClasses = 'pill-gradient transform transition shadow-md';
  const normalClasses =
    'bg-blue-400 hover:bg-blue-500 hover:shadow-md hover:scale-110 transform transition';

  const isAllSelected = selectedCategory === null;

  return (
    <div className="flex flex-wrap justify-start items-center w-full h-full">
      <div
        key={'all'}
        className={`rounded-lg px-4 py-2 mb-6 mr-6 cursor-pointer text-xl text-white font-medium uppercase ${
          isAllSelected ? selectedClasses : normalClasses
        }`}
        onClick={async () => {
          return await onSelect(null);
        }}
      >
        {t('sections.filter.allCategories')}
      </div>
      {cats
        ?.filter((cat) => cat?.slug !== 'noticias')
        .map((cat, index) => {
          const isCategorySelected = selectedCategory?._id === cat._id;
          return (
            <div
              key={cat.slug}
              className={`rounded-lg px-4 py-2 mb-6 mr-6 cursor-pointer text-xl text-white font-medium uppercase ${
                isCategorySelected ? selectedClasses : normalClasses
              }`}
              onClick={async () => {
                return await onSelect(cat);
              }}
            >
              {cat.label}
            </div>
          );
        })}
    </div>
  );
};

const getAuthorsFromPosts = (posts) => {
  const authors = new Map();
  if (!posts?.length) {
    return [];
  }
  for (const post of posts) {
    if (post?.data?.authors) {
      post?.data?.authors?.forEach((author) => {
        if (author?.name) {
          if (!authors.has(author.name)) {
            authors.set(author.name, 1);
          } else {
            authors.set(author.name, authors.get(author.name) + 1);
          }
        }
      });
    }
  }

  return Array.from(authors)
    .map((element) => ({ name: element[0], count: element[1] }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

const TagSelector = ({
  selectedTags,
  tags: maybeTags,
  onSelect,
  tagTranslations = [],
  language = DEFAULT_LANGUAGE
}) => {
  const tags = maybeTags ?? [];

  const selectedClasses =
    'bg-blue-400 border border-transparent text-white shadow-sm transform transition';
  const normalClasses =
    'bg-white border border-blue-400 text-blue-400 hover:shadow-sm hover:scale-105 transform transition';

  return (
    <div className="flex flex-wrap justify-start items-center w-full h-auto">
      {tags.map((tag, index) => {
        const isTagSelected = selectedTags.includes(tag);
        const targetTag = tagTranslations.find((x) => x?.label === tag);
        const translatedTag = targetTag?.translations?.[language]?.label ?? tag;
        return (
          <div
            key={tag ?? index}
            className={`px-2 select-none my-1 cursor-pointer text-xl font-light mr-2 ${
              isTagSelected ? selectedClasses : normalClasses
            }`}
            onClick={async () => {
              return await onSelect(tag, !isTagSelected);
            }}
          >
            {translatedTag}
          </div>
        );
      })}
    </div>
  );
};

const authorStyles = {
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition
    };
  },
  multiValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition
    };
  },
  multiValueLabel: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {
      ...provided,
      opacity,
      transition
    };
  },
  menu: (provided, state) => ({
    ...provided,
    zIndex: 1000000
  }),
  menuList: (provided, state) => {
    return { ...provided, zIndex: 1000000 };
  }
};
