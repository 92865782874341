import React from 'react';
import isURL from 'validator/lib/isURL';
import { toast } from 'react-toastify';

import TableauReport from '../../components/TableauReact';
import Input from '../../components/Input';
import InputTextArea from '../../components/InputTextArea';

import { getPageByName, putPageByName } from '../../network/pages';
import {
  AVAILABLE_LANGUAGES,
  DEFAULT_LANGUAGE
} from '../../common/translationUtils';

import './PageFeaturedIndicator.scss';
import { get, set } from 'lodash';

const PageFeaturedIndicator = () => {
  const [page, setPage] = React.useState(null);
  const [language, setLanguage] = React.useState(DEFAULT_LANGUAGE);
  const [urlError, setUrlError] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const save = async () => {
    setLoading(true);
    const response = await putPageByName('indicators', page);

    if (response?.ok) {
      console.log({ page, data: response.data });
      setPage(response.data);
      toast.success('Dados gravados com sucesso');
    } else {
      toast.error('Erro a gravar informação.');
    }
    setLoading(false);
  };

  const fetchData = async () => {
    setLoading(true);
    const response = await getPageByName('indicators');
    console.log(response?.data);
    if (response.ok) {
      setPage(response.data);
    } else {
      toast.error('Erro a carregar indicador. Por favor tente de novo.');
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (!page) {
      fetchData();
    }
  }, []); // eslint-disable-line

  const setValueLocalized = async (path, value) => {
    const newPage = { ...page };
    if (language === DEFAULT_LANGUAGE) {
      set(newPage, 'data.featuredIndicator.' + path, value);
    } else {
      const localizedPath =
        'translations.' + language + '.data.featuredIndicator.' + path;
      set(newPage, localizedPath, value);
    }
    setPage(newPage);
  };

  const getValueLocalized = (path) => {
    if (language === DEFAULT_LANGUAGE) {
      return get(page, 'data.featuredIndicator.' + path);
    }
    const localizedPath =
      'translations.' + language + '.data.featuredIndicator.' + path;
    return get(page, localizedPath);
  };

  return (
    <div className="category-list relative w-full flex flex-col justify-start items-start p-8 bg-gray-100">
      <div className="sticky z-30 top-0 shadow-xl w-full h-auto flex-shrink-0 rounded-md overflow-hidden bg-gray-300 mb-2">
        {AVAILABLE_LANGUAGES.map((lang, index) => {
          return (
            <button
              key={lang}
              onClick={() => setLanguage(lang)}
              className={`px-8 py-1 ${
                lang === language
                  ? 'bg-blue-700 text-white animate-pulse'
                  : 'bg-gray-300 text-gray-700'
              } border-r-2 border-gray-100 uppercase focus:outline-none transition`}
            >
              {lang}
            </button>
          );
        })}
      </div>
      <div className="w-full py-4 h-auto flex justify-between items-center">
        <h1 className="w-1/2 text-4xl font-bold">Indicador em Destaque</h1>
        <div className="w-1/22 h-auto rounded-md flex justify-end align-center top-0 p-4 pr-0">
          <button
            className={`${
              isLoading
                ? 'bg-gray-200 cursor-default'
                : 'bg-light-blue  hover:bg-blue-500 cursor-pointer'
            } h-12 text-white font-bold py-2 px-4 rounded focus:outline-none ml-4`}
            disabled={isLoading}
            onClick={save}
          >
            Gravar
          </button>
        </div>
      </div>

      <div
        className={`section w-full h-full p-12 flex flex-col justify-start items-start`}
      >
        <Input
          value={getValueLocalized('title')}
          className="meta-title w-full font-medium italic text-3xl leading-loose bg-trasparent mb-4"
          label={<span className="">Título</span>}
          type="text"
          onChange={(value) => setValueLocalized('title', value)}
        ></Input>
        <Input
          value={getValueLocalized('url')}
          className="meta-title w-full font-light italic text-xl leading-loose bg-trasparent mb-4"
          label={<span className="">URL</span>}
          type="text"
          onChange={async (value) => {
            if (!isURL(value)) {
              setValueLocalized('url', value?.trim() ?? value);
              if (!urlError) {
                setUrlError(true);
              }
            } else {
              setValueLocalized('url', value?.trim() ?? value);
              setUrlError(false);
            }
          }}
        ></Input>
        {getValueLocalized('url') && !urlError && (
          <div className="w-full h-auto mb-4">
            <TableauReport
              url={getValueLocalized('url')}
              options={{ hideTabs: true, hideToolbar: true }}
              query="?:embed=yes&amp;:comments=no&amp;:toolbar=no&amp;:refresh=yes"
            />
          </div>
        )}
        <InputTextArea
          containerClassName="w-3/4 bg-transparent"
          className="w-full text-sml font-light mb-4 py-1 px-2 bg-transparent"
          label="Legenda"
          type="text"
          value={getValueLocalized('label')}
          onChange={(value) => {
            setValueLocalized('label', value);
          }}
        ></InputTextArea>
        <InputTextArea
          containerClassName="w-3/4 bg-transparent"
          className="w-full text-xl font-light mb-4 py-1 px-2 bg-transparent"
          label="Descrição"
          type="text"
          value={getValueLocalized('description')}
          onChange={(value) => {
            setValueLocalized('description', value);
          }}
        ></InputTextArea>
        <div className={`${urlError ? 'flex' : 'hidden'} text-red-500 text-sm`}>
          URL Inválido. Deverá ser um URL com formato standard (https://....)
        </div>
      </div>
    </div>
  );
};

export default PageFeaturedIndicator;
