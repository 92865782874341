import i18next from 'i18next';
import en from './dictionaries/app/en.json';
import pt from './dictionaries/app/pt.json';
import { DEFAULT_LANGUAGE } from '../common/translationUtils';

const loadResources = async () => {
  await i18next.addResourceBundle(DEFAULT_LANGUAGE, 'app', pt, true, true);
  await i18next.addResourceBundle('en', 'app', en, true, true);
};

export default loadResources;
